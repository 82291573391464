import ReactDOM from 'react-dom/client'
import AppRoutes from './pages/route/router'

import { ServerEnvironment } from './components/serverEnvironment/server-environment-component'
import { AuthProvider } from 'data/context/auth-context'
import { Global } from 'pages/style/global/global-styled'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { init as initApm } from '@elastic/apm-rum'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const apm = initApm({

  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'is-compras-web',

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: 'https://apm.us.lumeprodutos.com.br/',

  // Set service version (required for sourcemap feature)
  serviceVersion: '1.0.0',

  environment: 'dev',

  distributedTracingOrigins: [
    'https://iscompras.dev.lumeprodutos.com.br',
    'https://is-services.api.intelligentsupply.com.br'
  ],

  propagateTracestate: true
})

root.render(
  <AuthProvider>
    <Global>
      <ReactNotifications />
      <AppRoutes />
    </Global>
    <ServerEnvironment />
  </AuthProvider>
)
