import {
  FiltersDefault,
  FilterItemInputDate,
  FilterItemInputText,
  FilterItemInputNumber,
  FilterItemInputSelect,
  FilterItemInputCheckbox,
  FilterItemInputMultiSelect,
  FilterItemInputTextList,
} from '../../../@types/interfaces/filter-interface';

let listMesesUltimaCompra = [];
let listMesesUltimaVenda = [];
let listPontuacaoItem = [];
for (let index = 0; index <= 21; index++) {
  listPontuacaoItem.push({ label: `${index}`, value: index });
  listMesesUltimaCompra.push({ label: `${index}`, value: index });
  listMesesUltimaVenda.push({ label: `${index}`, value: index });
}

export const ActiveCampaignsPlanningAdapter: FiltersDefault[] = [
  {
    label: 'Descrição do item',
    typeFilter: 'DESCRICAO_ITEM',
    field: [
      {
        name: 'descricao',
        typeInput: 'text',
      } as FilterItemInputText,
    ],
  },
  {
    label: 'Obsoleto',
    typeFilter: 'OBSOLETO',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Comprar', value: 'COMPRAR' },
          { label: 'Vender', value: 'VENDER' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'empresa',
        typeInput: 'select',
        optionsInput: [],
      } as FilterItemInputSelect,
      {
        name: 'filiais',
        typeInput: 'multi-select',
        optionsInput: [],
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Base',
    typeFilter: 'BASE_CODE',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'codigos',
        typeInput: 'multi-select',
        optionsInput: [],//lista de códigos do endpoint
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Base Descrição',
    typeFilter: 'BASE_DESC',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'descricoes',
        typeInput: 'multi-select',
        optionsInput: [],//lista de descricoes do endpoint
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Código do Item',
    typeFilter: 'CODIGO_ITEM',
    field: [
      {
        name: 'codigos',
        typeInput: 'text-list',
        separator: ';',
      } as FilterItemInputTextList,
    ],
  },
  {
    label: 'Status',
    typeFilter: 'STATUS',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'status',
        typeInput: 'multi-select',
        optionsInput: [
          { label: 'Blq.Def.Asc.', value: 'Blq.Def.Asc.' },
          { label: 'Blq.Def.Desc.', value: 'Blq.Def.Desc.' },
          { label: 'Blq.Definitivo', value: 'Blq.Definitivo' },
          { label: 'Blq.Téc.Asc.', value: 'Blq.Téc.Asc.' },
          { label: 'Blq.Téc.Desc.', value: 'Blq.Téc.Desc.' },
          { label: 'Blq.Técnico', value: 'Blq.Técnico' },
          { label: 'Crítico', value: 'Crítico' },
          { label: 'Crítico Asc.', value: 'Crítico Asc.' },
          { label: 'Crítico Des.', value: 'Crítico Des.' },
          { label: 'Dem. Zero', value: 'Dem. Zero' },
          { label: 'Dem.Zero Asc.', value: 'Dem.Zero Asc.' },
          { label: 'Dm.Zero S/Vda', value: 'Dm.Zero S/Vda' },
          { label: 'Estável', value: 'Estável' },
          { label: 'Estável Asc.', value: 'Estável Asc.' },
          { label: 'Estável Des.', value: 'Estável Des.' },
          { label: 'Excesso', value: 'Excesso' },
          { label: 'Excesso Asc.', value: 'Excesso Asc.' },
          { label: 'Excesso Des.', value: 'Excesso Des.' },
          { label: 'Novo Asc.', value: 'Novo Asc.' },
          { label: 'Novo Crítico', value: 'Novo Crítico' },
          { label: 'Novo Des.', value: 'Novo Des.' },
          { label: 'Novo Premiun', value: 'Novo Premiun' },
          { label: 'Novo S/Vda', value: 'Novo S/Vda' },
          { label: 'Pleno', value: 'Pleno' },
          { label: 'Pleno Asc.', value: 'Pleno Asc.' },
          { label: 'Pleno Des.', value: 'Pleno Des.' },
          //todo: pegar todos os item de status com o Gustavo
        ],
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Itens Bloqueados para Compra',
    typeFilter: 'ITEM_BLOQUEADO',
    field: [
      {
        name: 'bloqueado',
        typeInput: 'checkbox',
        optionsInput: [
          { label: 'Bloqueado', value: true },
          { label: 'Desbloqueado', value: false },
        ],
      } as FilterItemInputCheckbox,
    ],
  },
  {
    label: 'Data da ultima compra',
    typeFilter: 'DATA_ULTIMA_COMPRA',
    field: [
      {
        name: 'inicio_periodo_compra',
        typeInput: 'date',
      } as FilterItemInputDate,
      {
        name: 'fim_periodo_compra',
        typeInput: 'date',
      } as FilterItemInputDate,
    ],
  },
  {
    label: 'Data da última venda',
    typeFilter: 'DATA_ULTIMA_VENDA',
    field: [
      {
        name: 'inicio_periodo_venda',
        typeInput: 'date',
      } as FilterItemInputDate,
      {
        name: 'fim_periodo_venda',
        typeInput: 'date',
      } as FilterItemInputDate,
    ],
  },
  {
    label: 'Pontuação do item',
    typeFilter: 'PONTUACAO_ITEM',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'pontuacoes',
        typeInput: 'multi-select',
        optionsInput: listPontuacaoItem,
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Itens Bloqueados em Estoque',
    typeFilter: 'ESTOQUE_BLOQUEADO',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'estoque_bloqueado',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Quantidade de excesso', //TODO verificar para coloca numeros negativos
    typeFilter: 'QUANTIDADE_EXCESSO',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'quantidade_excesso',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
];
