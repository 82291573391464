import { useEffect, useState } from "react"
import { UseAuth } from "hook/auth-hook"
import { RestUseCase } from "service/api/rest/rest-usecase"
import FileLoadPage from "pages/layout/file-load-page"
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page"
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter"
import { handleRequest } from "service/api/request-common"
import { getEnv } from "helper/window-helper"

export const FileLoadFactory: React.FC = () => {

    const ENDPOINT_CHARGE_ANALYSIS = getEnv("REACT_APP_END_POINT_ITEM_CHARGE_ANALYSIS")!
    const ENDPOINT_CHARGE_INICIAL = getEnv("REACT_APP_END_POINT_ITEM_CHARGE_INICIAL")!
    const ENDPOINT_CHARGE_ANALYSIS_USERS = getEnv("REACT_APP_END_POINT_ITEM_CHARGE_ANALYSIS_USERS")!
    const ENDPOINT_EXPORT = getEnv("REACT_APP_END_POINT_FILE_ERROR")!
    const headers = { "Content-Type": "multipart/form-data" }

    async function handleRequestFile(file: File, endPoint: string): Promise<any> {

        const formData = new FormData()
        formData.append("file", file)

        const response = await new RestUseCase(endPoint).Post({ data: formData, headers: headers })
        return response.body
    }

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Análise")[0].navItem.filter(item => item.title === "Carga")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }

    }, [loggedUserData])

    return (
        <>
            {showScreen ?
                <FileLoadPage
                    uploadFileAnalysis={async function (params: { file: File }): Promise<any> {
                        return await handleRequestFile(params?.file!, ENDPOINT_CHARGE_ANALYSIS)
                    }}

                    uploadFileRegistrationInitial={async function (params: { file: File }): Promise<any> {
                        return await handleRequestFile(params?.file!, ENDPOINT_CHARGE_INICIAL)
                    }}

                    uploadFileRegistrationInitialUsers={async function (params: { file: File; users: string }): Promise<any> {
                        return await handleRequestFile(params?.file!, ENDPOINT_CHARGE_ANALYSIS_USERS + params.users)
                    }}

                    exportFile={async function (): Promise<any> {
                        const response = await new RestUseCase(ENDPOINT_EXPORT!).Get()
                        return handleRequest(response)
                    }}
                    companyId={loggedUserData?.companyId ?? 0}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )
}
