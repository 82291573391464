/* eslint-disable no-restricted-globals */

import { Footer } from "components/footer/footer-component";
import { setLocalStorege, updateLocalStorege } from "data/cache/localstorage-cache";
import { getEnv } from "helper/window-helper";
import { UseAlert } from "hook/alert-hook";
import { SpinnerSC } from "pages/style/spinner/spinner-styled";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSearchParams, useNavigate } from "react-router-dom";
import { AuthUseCase } from "service/api/rest/auth-usecase";
import { RestUseCase } from "service/api/rest/rest-usecase";


export const OnlineConsultantAnalyticalFactory: React.FC = () => {

  const ENDPOINT_LOGIN = getEnv("REACT_APP_END_POINT_LOGIN")!
  const LOCALSTORAGE_TOKEN_USER_LOGIN = getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN")!
  const ENDPOINT_ONLINE_CONSULTANT = getEnv('REACT_APP_END_POINT_ONLINE_CONSULTANT')!;
  const LOCALSTORAGE_PLANNING_ANALITYCAL = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_ANALYTICAL')!;

  const { showAlert } = UseAlert();
  const [queryParameters] = useSearchParams()
  const [isLogged, updateIsLogged] = useState<boolean>(false);

  let anoIntCod = queryParameters.get("anoIntCod")
  let p = queryParameters.get("p")
  let parameters = p?.split(";")
  let navigate = useNavigate()

  async function authentication(params: { login: string, password: string }): Promise<any> {

    const loginFormData = new FormData();
    loginFormData.append('username', params.login);
    loginFormData.append('password', params.password);

    await new AuthUseCase(`${ENDPOINT_LOGIN}/login-consultor-online`)
      .Post({
        data: {
          username: params.login,
          password: params.password
        }
      })
      .then(response => {
        if (response.statusCode === 200) {
          setLocalStorege(LOCALSTORAGE_TOKEN_USER_LOGIN, response.body)
          findPlanning()
        } else {
          showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
        }
      }).catch((error) => {
        showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
      })
  }

  async function findPlanning(): Promise<any> {
    if (anoIntCod !== undefined) {
      await new RestUseCase(`${ENDPOINT_ONLINE_CONSULTANT!}/filtros/${anoIntCod}`).Get()
        .then(response => {
          if (response.statusCode === 200) {
            let filtros = response.body.filtros
            updateLocalStorege(LOCALSTORAGE_PLANNING_ANALITYCAL, filtros);
            updateIsLogged(true)

          } else {
            showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
          }
        }).catch((error) => {
          showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
        });
    }
  }

  useEffect(() => {
    if (parameters !== null && parameters !== undefined) {
      try {
        // let decoded = window.atob(parameters[1])
        // let params = { login: parameters[0], password: decoded }
        let params = { login: parameters[0], password: parameters[1] }
        authentication(params)
      } catch (error) { }
    }
  }, [])

  useEffect(() => {
    if (isLogged) {
      navigate('/analiticoconsultoronline')
      location.reload()
    }
  }, [isLogged])

  return (
    <>
      <Container style={{ height: "90vh", textAlign: "center", alignContent: "center" }}>
        <h6 className="mt-3">Redirecionando para Analítico, caso não seja redirecionado verifique se o link da página está correto.</h6>
        <SpinnerSC style={{height: "10vh"}}>
          <div className="spinner-border text-secondary" role="status"></div>
        </SpinnerSC>
      </Container>
      <Footer />
    </>
  );
}