/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { SpinnerSC } from 'pages/style/spinner/spinner-styled';
import { Footer } from 'components/footer/footer-component';
import { Headers } from 'components/header/headers-component';
import { FiltersDefault } from '../../../@types/interfaces/filter-interface';
import { BsTrash } from 'react-icons/bs';
import { ParamsFilterScrapEntity } from '../../../@types/entity/params-filter-scrap-entity';
import { SelectOptionInterface } from '../../../@types/entity/interface/select-option-interface';
import { SelectProvider } from 'components/select/provider-select-component';
import { TitleGroupComponent } from '../../../components/title/title-group-component';
import { FilterScrapEntity } from '../../../@types/entity/filter-scrap-entity';
import ReactTooltip from 'react-tooltip';

interface CreateField {
  field: any,
  indexSelected: number
}

type Props = {
  listAllPlanning: (params?: any) => Promise<any>,
  createPlanning: (params?: any) => Promise<any>,
  deletePlanning: (params?: any) => Promise<any>,
  optionsFiltersDefault: FiltersDefault[]
}

const titlePage = 'Filtro Scrap';

const FilterScrapPage: React.FC<Props> = ({ optionsFiltersDefault, listAllPlanning, createPlanning, deletePlanning }: Props) => {

  /**
   *
   * CRUD MODEL / MODELS
   *
   */
  const [filterScrapList, updateFilterScrapList] = useState<FilterScrapEntity[]>([]);
  const [currentFilterScrap, updateCurrentFilterScrap] = useState<FilterScrapEntity>();
  const [editingFilterScrap, updateEditingFilterScrap] = useState<FilterScrapEntity>();
  const [modelItemFilter, updateItemFilter] = useState<ParamsFilterScrapEntity[]>([]);
  const [namePlanning, updateNamePlanning] = useState<string>('');

  async function handleModel(idProvider: number): Promise<void> {

    updateLoading(true);
    await listAllPlanning({ idProvider: idProvider })
      .then(response => {
        if (response.statusCode === 200) {
          updateFilterScrapList(response.body);
        } else {
          clearFilter();
        }
      })
      .catch(error => {
        console.log('error', error);
      });

    updateLoading(false);
  }

  async function handleSave(modelFields: ParamsFilterScrapEntity[]): Promise<void> {
    let models: ParamsFilterScrapEntity[] = modelFields.filter(value => value.type !== '');

    let modifiedList = models.map((item: ParamsFilterScrapEntity) => {
      let fieldName = Object.keys(item.params)[0] === "type" ? Object.keys(item.params)[1] : Object.keys(item.params)[0];
      if (item.params.type === 'GREATER' || item.params.type === 'LESSER') {
        if (item.type === 'MESES_ULTIMA_COMPRA_FORNECEDOR') {
          item.params[fieldName] = item.params[fieldName][0];
        }

        if (item.type === 'MESES_ULTIMA_COMPRA') {
          item.params.meses_ultima_compra = Number(item.params.meses_ultima_compra);
        }

        if (item.type === 'MESES_ULTIMA_VENDA') {
          item.params.meses_ultima_venda = Number(item.params.meses_ultima_venda);
        }
      }
      if (item.params.type === 'IN' || item.params.type === 'NOT_IN') {
        if (item.type === 'MESES_ULTIMA_COMPRA') {
          item.params.meses_ultima_compra = item.params.meses_ultima_compra.map((value: any) => {
            value = Number(value);
            return value;
          });
        }
        if (item.type === 'MESES_ULTIMA_VENDA') {
          item.params.meses_ultima_venda = item.params.meses_ultima_venda.map((value: any) => {
            value = Number(value);
            return value;
          });
        }
      }
      return item;
    });

    let filterScrapEntitySave: FilterScrapEntity = { filtroDTOList: [], idAnaliseFornecedor: null, isVigente: false, nomeFiltro: "" }
    if (editingFilterScrap !== undefined && editingFilterScrap.idAnaliseFornecedor !== undefined
      && editingFilterScrap.idAnaliseFornecedor !== null) {
      filterScrapEntitySave.idAnaliseFornecedor = editingFilterScrap.idAnaliseFornecedor
    }
    filterScrapEntitySave.filtroDTOList = modifiedList
    filterScrapEntitySave.nomeFiltro = namePlanning
    //Ao salvar/editar o filtro o campo isVigente se torna true no back-end

    await createPlanning({ idProvider: idProvider, filterScrapEntity: filterScrapEntitySave });
    await handleModel(idProvider!);
  }

  async function applySavedFilter(value: FilterScrapEntity): Promise<void> {
    updateLoading(true);
    await createPlanning({ idProvider: idProvider, filterScrapEntity: value });
    await handleModel(idProvider!);
    updateLoading(false);
  }

  async function deleteSavedFilter(value: FilterScrapEntity): Promise<void> {
    updateLoading(true);
    await deletePlanning({ idPlanning: value.idAnaliseFornecedor })
    await handleModel(idProvider!);
    updateLoading(false);
  }

  /**
   *
   * FIELD
   *
   */
  const add = (item: FiltersDefault, indexSelected: number) => {

    if (modelItemFilter.length === 1)
      updateItemFilter([
        ...modelItemFilter.map((value, index) => {
          if (index === indexSelected)
            value.type = item.typeFilter;
          return value;
        }),
        { type: '', params: {} },
      ]);
    else
      updateItemFilter(
        modelItemFilter.map((value, index) => {
          if (index === indexSelected)
            value.type = item.typeFilter;
          return value;
        }),
      );

    let emptyQuantity: number = modelItemFilter.filter(value => value.type === '').length;

    if (emptyQuantity === 0)
      addBlank();
  };

  const addBlank = () => {
    updateItemFilter([...modelItemFilter, { type: '', params: {} }]);
  };

  const removeItem = (params: any) => {
    updateItemFilter(modelItemFilter.filter((value, index) => {
      if (index !== params) {
        return value;
      }
    }));
  };

  const clearFilter = () => {
    updateItemFilter([...[], { type: '', params: {} }]);
  };


  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
      <SpinnerSC>
        <div className='spinner-border text-secondary' role='status'></div>
      </SpinnerSC>
    );
  }

  function getFilterByType(type: string) {
    let filtered = optionsFiltersDefault.filter(value => value.typeFilter === type);
    return filtered.length > 0 ? filtered : [];
  }

  const updateParameterValue = (index: number, param: string, value: any) => {
    updateItemFilter(
      modelItemFilter.map((item, itemIndex) => {
        if (itemIndex === index) {
          item.params[param!] = value;
        }
        return item;
      }),
    );
  };

  /**
   *
   * PROVIDER
   *
   */
  const [idProvider, updateIdProvider] = useState<number>();
  const [modelProvider, updateModelProvider] = useState<SelectOptionInterface>();

  function handleSelectProvider(filial: {
    value: number,
    label: string
  }) {
    newFilter()
    updateModelProvider(filial);
    updateIdProvider(filial.value);
    handleModel(filial.value);
  }

  function buildEditSavedFilter(value: FilterScrapEntity): void {
    updateEditingFilterScrap(value)
    updateItemFilter([...value.filtroDTOList, { type: '', params: {} }])
    updateNamePlanning(value.nomeFiltro)
  }

  function newFilter(): void {
    updateItemFilter([{ type: '', params: {} }])
    updateNamePlanning("")
    updateEditingFilterScrap(undefined)
  }

  useEffect(() => {
    if (filterScrapList !== null && filterScrapList !== undefined && filterScrapList.length > 0) {
      let filterScrapEntity: FilterScrapEntity | undefined = filterScrapList.find(filter => filter.isVigente === true)
      if (filterScrapEntity !== undefined) {
        updateCurrentFilterScrap(filterScrapEntity)
        updateEditingFilterScrap(filterScrapEntity)
        updateItemFilter([...filterScrapEntity.filtroDTOList, { type: '', params: {} }])
        updateNamePlanning(filterScrapEntity.nomeFiltro)
      } else {
        updateCurrentFilterScrap(undefined)
        updateEditingFilterScrap(undefined)
        updateItemFilter([{ type: '', params: {} }])
        updateNamePlanning("")
      }
    } else {
      updateCurrentFilterScrap(undefined)
      updateEditingFilterScrap(undefined)
      updateItemFilter([{ type: '', params: {} }])
      updateNamePlanning("")
    }
  }, [filterScrapList, updateFilterScrapList])

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
  }, []);

  /**
   *
   * RENDER FUNCTIONS
   *
   */
  function FieldCreated(params: CreateField) {

    let filter: any[] = getFilterByType(params.field.type);
    const elements: React.ReactElement[] = [];

    if (filter.length > 0) {
      filter[0].field.forEach((value: any, index: number) => {
        if (value.typeInput === 'select' || value.typeInput === 'checkbox') {
          elements.push(
            <Col md={5} key={index}>
              <Select
                options={value.optionsInput}
                onChange={(e: any) => {

                  if (filter[0].label.toLowerCase() === 'pontuação do item') {
                    if (e.value === 'GREATER' || e.value === 'LESSER') {
                      if (params.field.params.pontuacoes !== undefined) {
                        params.field.params.pontuacoes = [params.field.params.pontuacoes[0]];
                      }
                    }
                  }

                  updateItemFilter(
                    modelItemFilter.map((vl: any, idx) => {
                      if (params.indexSelected === idx)
                        vl.params[value.name!] = e.value;
                      return vl;
                    }),
                  );
                }}
                defaultValue={
                  {
                    label: value.optionsInput.filter((vlFilter: any) => modelItemFilter.filter((vl: any, idx) => {
                      if (params.indexSelected === idx) return vl;
                    })[0].params[value.name!] === vlFilter.value)[0] ?
                      value.optionsInput.filter((vlFilter: any) => modelItemFilter.filter((vl: any, idx) => {
                        if (params.indexSelected === idx) return vl;
                      })[0].params[value.name!] === vlFilter.value)[0].label :
                      '',
                    value: modelItemFilter.filter((vl: any, idx) => {
                      if (params.indexSelected === idx)
                        return vl;
                    })[0].params[value.name!],
                  }
                }
              />
            </Col>,
          );
        }

        if (value.typeInput === 'multi-select') {
          elements.push(
            <Col md={5} key={index}>
              <Select
                options={value.optionsInput}
                isMulti={true}
                onChange={(e: any) => {
                  if (e.length > 1 && (params.field.params.type === 'GREATER' || params.field.params.type === 'LESSER' || params.field.params.type === 'NOT_EQUALS')) {
                    e = [e[e.length - 1]];
                  }
                  updateParameterValue(params.indexSelected, value.name, e.map((elements: any) => elements.value));
                }}
                defaultValue={() => {
                  const currentValue = modelItemFilter[params.indexSelected]?.params[value.name] ?? [];
                  if (currentValue.length === 0) {
                    return '';
                  } else {
                    if (value.optionsInput !== undefined) {
                      return Array.isArray(currentValue)
                        ? value.optionsInput.filter((opt: { label: string; value: any }) => currentValue.includes(opt?.value))
                        : value.optionsInput.filter((opt: { label: string; value: any }) => currentValue === opt?.value);
                    } else {
                      return currentValue;
                    }
                  }
                }}
              />
            </Col>,
          );
        }

        if (value.typeInput === 'text' || value.typeInput === 'date') {
          elements.push(
            <Col md={5} key={index}>
              <Form.Control
                type={value.typeInput}
                onBlur={(e) => {
                  e.preventDefault();
                  updateItemFilter(
                    modelItemFilter.map((vl: any, idx) => {
                      if (params.indexSelected === idx)
                        vl.params[value.name!] = e.target.value;
                      return vl;
                    }),
                  );
                }}
                defaultValue={
                  modelItemFilter.filter((vl: any, idx) => {
                    if (params.indexSelected === idx)
                      return vl;
                  })[0].params[value.name!]
                }
              />
            </Col>,
          );
        }
        if (value.typeInput === 'text-list') {
          let valor: any = 0;

          if (typeof modelItemFilter[params.indexSelected].params[value.name!] === 'number') {
            valor = modelItemFilter[params.indexSelected].params[value.name!];
          }

          if (typeof modelItemFilter[params.indexSelected].params[value.name!] === 'object') {
            valor = modelItemFilter[params.indexSelected].params[value.name!]?.join(value.separator);
          }

          let isGreaterOrLesser = params.field.params.type === 'GREATER' || params.field.params.type === 'LESSER'

          elements.push(
            <Col md={5} key={index}>
              <Form.Control
                type={value.typeInput}
                onBlur={(e: any) => {
                  let list = e.target.value.split(value.separator).map((item: string) => item.split(/\s+/).join(''));
                  updateParameterValue(params.indexSelected, value.name, list);
                }}
                defaultValue={valor}
                style={{ display: "inline-block", width: !isGreaterOrLesser ? "40%" : "100%" }}
              />
              {!isGreaterOrLesser ? <Form.Label style={{ marginLeft: "5px" }}>
                * Valor separado por {value.separator}
              </Form.Label> : <></>}
            </Col>
          );
        }

        if (value.typeInput === 'number') {
          var defaultVlaue = 0;
          if (modelItemFilter[params.indexSelected]?.params[value.name] === null ||
            modelItemFilter[params.indexSelected]?.params[value.name] === undefined) {
            updateParameterValue(params.indexSelected, value.name, 0)
            defaultVlaue = 0
          } else {
            defaultVlaue = modelItemFilter[params.indexSelected].params[value.name]
          }
          elements.push(
            <Col md={5} key={index}>
              <Form.Control
                type={value.typeInput}
                onBlur={(e) => updateParameterValue(params.indexSelected, value.name, parseFloat(e.target.value))}
                defaultValue={defaultVlaue}
              />
            </Col>,
          );
        }
      });
    }

    return (
      <Row className='d-flex justify-content-start'>
        {elements}
      </Row>
    );
  }

  function CreateSelectProvider() {
    return (
      <>
        <Container>
          <Row className='mt-3 d-flex align-items-center justify-content-start'>
            <Col md={1}>
              <Form.Label>Fornecedor:</Form.Label>
            </Col>
            <Col md={3}>
              <SelectProvider passOnTheValue={handleSelectProvider} defaultValue={modelProvider} />
            </Col>
            {idProvider !== undefined ?
              <Col md={8} style={{ textAlign: "end" }}>
                {currentFilterScrap?.nomeFiltro !== undefined ?
                  <Badge style={{ fontSize: "medium" }}>Filtro Vigente: {currentFilterScrap?.nomeFiltro}</Badge>
                  :
                  <Badge style={{ fontSize: "medium" }}>Não há Filtro Vigente</Badge>}
              </Col>
              :
              <></>
            }
          </Row>
        </Container>
      </>
    );
  }

  /**
   *
   * RENDER 
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}

      <div className='mx-3'>
        <div className='mx-5'>
          <TitleGroupComponent titlePage={titlePage}>
            <Row>
              <Col className='d-flex align-items-center justify-content-end'>
                <Button variant="outline-primary" onClick={() => newFilter()} size='sm'>Novo</Button>
                <div data-tip data-for="tooltipSalvar" >
                  <Button variant="primary" className="ms-3" disabled={namePlanning === undefined || namePlanning === ""}
                    onClick={() => handleSave(modelItemFilter)} size="sm" >
                    {editingFilterScrap?.idAnaliseFornecedor === undefined ? 'Salvar Filtro' : 'Editar Filtro'}
                  </Button>
                </div>
                {namePlanning === undefined || namePlanning === "" ?
                  <ReactTooltip id="tooltipSalvar" backgroundColor={'#DC3444'} place="bottom" border>
                    <span>É necessário preencher o nome do filtro!</span>
                  </ReactTooltip>
                  : <></>}
              </Col>
            </Row>
          </TitleGroupComponent>
        </div>
        <Card>
          <Card.Body>

            <CreateSelectProvider />

            {idProvider !== undefined ?

              <Container className='mt-4'>
                <Container>
                  <Row className='mt-3 d-flex align-items-center justify-content-start'>
                    <Col md={6}>
                      <Form.Label>Nome do filtro:</Form.Label>
                      <Form.Control className="my-2" style={{ display: "inline-block", width: "60%", marginLeft: "5px" }}
                        onBlur={(e) => updateNamePlanning(e.target.value)} onChange={e => updateNamePlanning(e.target.value)}
                        value={namePlanning} placeholder="Nome do planejamento" defaultValue={namePlanning} />
                    </Col>
                  </Row>
                </Container>

                {modelItemFilter.map((value, index) => {

                  var optionsFiltered = optionsFiltersDefault.map(vl => {
                    return { value: vl.label, label: vl.label };
                  });
                  var optionsSelected = optionsFiltersDefault.filter(vl => vl.typeFilter === value.type);

                  var valueSelect = { value: '', label: 'Selecione um novo filtro' };

                  if (value.type !== '') {
                    valueSelect = { value: optionsSelected[0].label, label: optionsSelected[0].label };
                  }

                  return (
                    <Container className='mt-1' key={index}>
                      <Row>
                        <Col md={3}>
                          <Col>
                            <Select
                              key={index}
                              options={optionsFiltered}
                              onChange={val => {
                                add(optionsFiltersDefault.filter(value => value.label === val?.label)[0], index);
                              }}
                              value={valueSelect}
                            />
                          </Col>
                        </Col>
                        <Col>
                          <FieldCreated field={value} indexSelected={index} />
                        </Col>
                        {value.type !== '' &&
                          <Col md={1}>
                            <Button variant='danger' className='ms-1'
                              onClick={() => removeItem(index)}><BsTrash /></Button>
                          </Col>
                        }
                      </Row>
                    </Container>
                  );
                })}
              </Container>
              :
              <></>
            }
            <div className="mt-2">
              <Card>
                <Card.Body>
                  <div className="mt-2">
                    <h5>Filtros salvos:</h5>
                    {filterScrapList.length > 0 ? (
                      <Table size="sm" className="mt-2" style={{ cursor: 'pointer' }}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nome do planejamento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterScrapList.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td>{value.idAnaliseFornecedor}</td>
                                <td className="d-flex align-items-center justify-content-between">
                                  <div>{value.nomeFiltro}</div>
                                  <div>
                                    <Button variant="outline-primary" size="sm" onClick={() => applySavedFilter(value)}>
                                      Aplicar este Filtro
                                    </Button>
                                    <Button variant="outline-primary" size="sm" className="mx-1" onClick={() => buildEditSavedFilter(value)}>
                                      Editar
                                    </Button>
                                    <Button variant="danger" size="sm" onClick={() => deleteSavedFilter(value)}>
                                      <BsTrash />
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <p className="m-5">Nenhum Filtro Salvo</p>
                        </div>
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>

          </Card.Body>
        </Card>
      </div>
      <Footer />
    </>
  );
};

export default FilterScrapPage;
