import { useEffect, useState } from "react";
import CrudPage from "pages/layout/crud-page";
import { UseAuth } from "hook/auth-hook";
import { RestUseCase } from "service/api/rest/rest-usecase";
import { ParseDateToEn } from "helper/format-date-helper";
import { EntityField } from "../../../@types/entity/field/crud/field-crud-entity";
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page";
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter";
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface";
import { getEnv } from "helper/window-helper"
export const CommuniqueFactory: React.FC = () => {
    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_COMMUNIQUE")!

    /** TITULO DA PAGINA */
    const titlePage = "Comunicado";

    /** USUARIO LOGADO */
    const { loggedUserData } = UseAuth();

    /** CAMPOS PARA MODAL E TABELA */
    const entityFields: EntityField[] = [
        {
            name: "id",
            labelField: "",
            labelTable: "ID",
            typeField: "hidden",
            valueField: "",
            sizeField: 0,
            required: false,
            align: "center",
            tableField: { column: "id", columnVisible: true },
        },
        {
            name: "status",
            labelField: "Status do Comunicado:",
            labelTable: "Status",
            typeField: "radio",
            valueField: null,
            listValueField: [
                { label: "Ativo", value: "A" },
                { label: "Inativo", value: "I" },
            ],
            sizeField: 4,
            required: false,
            align: "center",
            tableField: { column: "Status", columnVisible: true },
        },
        {
            name: "dataInicio",
            labelField: "Data Inicial do Comunicado:",
            labelTable: "Data Inicial",
            typeField: "date",
            valueField: "",
            sizeField: 4,
            required: false,
            align: "left",
            tableField: { column: "Data Inicial", columnVisible: true },
        },
        {
            name: "dataFim",
            labelField: "Data Final do Comunicado:",
            labelTable: "Data Final",
            typeField: "date",
            valueField: "",
            sizeField: 4,
            required: false,
            align: "left",
            tableField: { column: "Data Final", columnVisible: true },
        },
        {
            name: "titulo",
            labelField: "Título do Comunicado:",
            labelTable: "Título do Comunicado",
            typeField: "text",
            valueField: "",
            sizeField: 12,
            required: false,
            align: "left",
            tableField: { column: "Texto", columnVisible: true },
        },
        {
            name: "texto",
            labelField: "Descrição do Comunicado:",
            labelTable: "Descrição do Comunicado",
            typeField: "html",
            valueField: "",
            sizeField: 12,
            required: false,
            align: "center",
            tableField: { column: "Descrição", columnVisible: true },
        },
    ];
    const [entity, updateEntity] = useState(entityFields);

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([]);
    const [tableBody, updateTableBody] = useState([]);

    /** VERIFICA PERMISSAO DE ACESSO */
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Configurações"
        )[0].navItem.filter((item) => item.title === "Cadastrar Comunicado")[0]
            .role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
    }, [loggedUserData]);

    const [idCompany, updateIdCompany] = useState<number>();
    const [modelCompany, updateModelCompany] =
        useState<SelectOptionInterface>();
    function handleSelectProvider(filial: { value: number; label: string }) {
        updateModelCompany(filial);
        updateIdCompany(filial.value);
        if (filial.value) {
            getAllV2(filial.value);
        }
    }

    async function getAllV2(company: number) {
        const response = await new RestUseCase(`${ENDPOINT_CRUD}/all`)
            .Get()
            .finally();

        updateTableHeader(
            entityFields.map((value) => {
                return {
                    name: value.name,
                    labelTable: value.labelTable,
                    align: value.align,
                    columnVisible: value.tableField.columnVisible,
                };
            })
        );
        updateTableBody(response.body);
    }

    return (
        <>
            {showScreen ? (
                <CrudPage
                    handleSelectProvider={handleSelectProvider}
                    filterByCompany={false}
                    modelCompany={modelCompany}
                    entityFields={entity}
                    updateEntity={updateEntity}
                    titlePage={titlePage}
                    getAll={async function () {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/all`
                        )
                            .Get()
                            .finally();

                        updateTableHeader(
                            entityFields.map((value) => {
                                return {
                                    name: value.name,
                                    labelTable: value.labelTable,
                                    align: value.align,
                                    columnVisible:
                                        value.tableField.columnVisible,
                                };
                            })
                        );
                        updateTableBody(response.body);
                    }}
                    getById={async function (id: number) {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${id}`
                        )
                            .GetById()
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                if (value.typeField === "date")
                                    value.valueField = ParseDateToEn(
                                        response.body[value.name]
                                    );
                                else
                                    value.valueField =
                                        response.body[value.name];

                                return value;
                            })
                        );
                    }}
                    post={async function (model: any) {
                        delete model["id"];
                        if (model["idEmpresa"] !== "") {
                            model["idEmpresa"] = loggedUserData?.companyId;
                        }
                        if (model["dataInicio"] !== "") {
                            model["dataInicio"] = new Date(
                                model["dataInicio"]
                            ).toISOString();
                        }
                        if (model["dataFim"] !== "") {
                            model["dataFim"] = new Date(
                                model["dataFim"]
                            ).toISOString();
                        }
                        await new RestUseCase(ENDPOINT_CRUD)
                            .Post({ data: model })
                            .finally();
                    }}
                    patch={async function (id: number, model: any) {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${id}`
                        )
                            .Patch({ data: model })
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                value.valueField = response.body[value.name];
                                return value;
                            })
                        );
                    }}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
