/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import { PanelEntity } from '../../@types/entity/panel/panel-entity';
import { CrudInterface } from '../../@types/entity/interface/crud-interface';
import { UseAlert } from 'hook/alert-hook';
import { Painel } from 'components/painel/peinel-component';
import { SpinnerSC } from 'pages/style/spinner/spinner-styled';
import { UseAlertModal } from 'hook/alert-modal-hook';
import { DefaultEntity, DefaultInputs } from '../../@types/entity/default-entity';
import { Headers } from 'components/header/headers-component';
import { Footer } from 'components/footer/footer-component';
import { TitlePageComponent } from '../../components/title/title-page-component';

type Props = {
  getPanel: () => Promise<PanelEntity[]>,
  modelMethods: CrudInterface<DefaultEntity>
}

const tableDescriptions: string[] = ['#', 'COLUNA 1', 'COLUNA 2', 'COLUNA 3', 'COLUNA 4'];
const titlePage = 'TESTE';

const PageDefault: React.FC<Props> = ({ modelMethods, getPanel }: Props) => {

  /**
   *
   * ALERT / ALERT MODAL
   *
   */
  const { showAlert } = UseAlert();
  const { messageAlertModal, showAlertModal } = UseAlertModal();
  const cleanAlertModal = () => showAlertModal({ show: false, content: '', color: '' });

  function AlertModal() {
    if (messageAlertModal?.show) {
      return (
        <div
          className={'alert alert-' + messageAlertModal.color + ' fade show mx-5 mt-2 alert-dismissible'}
          role='alert'
        >
          {messageAlertModal.content}
          {!messageAlertModal.time &&
            <button
              type='button'
              className='ms-1 btn-close btn-sm'
              data-bs-dismiss='alert'
              aria-label='Close'
              onClick={() => cleanAlertModal()}>
            </button>
          }
        </div>
      );
    } else {
      return (
        <></>
      );
    }
  }

  /**
   *
   * FORM HOOK
   *
   */
  const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm<DefaultInputs>();

  function clearField(): void {
    // LIMPA OS CAMPOS
    reset({});
  }

  /**
   *
   * ERROR
   *
   */
  const [listError, updateListError] = useState<string[]>([]);


  /**
   *
   * FILTER
   *
   */
  const [isFiltered, updateIsFiltered] = useState<boolean>(false);

  async function handleFilter(): Promise<void> {
    await handleListModel();
    // FILTRA
    updateIsFiltered(true);
  }

  /**
   *
   * PAINEL
   *
   */
  const [panelInfo, updatePanelInfo] = useState<PanelEntity[]>([]);

  async function handlePanelInfo(): Promise<void> {
    // BUSCA INFORMAÇÃO DO PAINEL
    updatePanelInfo(await getPanel());
  }

  /**
   *
   * CRUD MODEL / MODELS
   *
   */
  const [listModel, updateListModel] = useState<DefaultEntity[]>([]);

  async function handleListModel(): Promise<void> {
    updateLoading(true);
    // BUSCA LISTAGEM DE MODELS
    await modelMethods.getAll()
      .then((response) => updateListModel(response))
      .catch(error => updateListError([...listError, error]));

    updateLoading(false);
    handleCloseModal();
  }

  async function handleModel(id: number): Promise<void> {
    // BUSCA MODEL
    await modelMethods.getById(id)
      .then(response => {
        setValue('id', response.id!);
        // SETAR OUTROS CAMPOS
      })
      .catch(error => updateListError([...listError, error]));
  }

  async function handleCreteOrEdit(modelFields: DefaultInputs): Promise<void> {
    // EDITA OU CRIA
    modelFields.id = inEditing ? getValues('id') : undefined;

    if (inEditing === true)
      await modelMethods.edit(modelFields, 0)
        .then(() => showAlert({ show: true, content: 'Editado com sucesso', color: 'success', time: 5000 }))
        .catch(error => updateListError([...listError, error]));
    else
      await modelMethods.create(modelFields)
        .then(() => showAlert({ show: true, content: 'Salvo com sucesso', color: 'success', time: 5000 }))
        .catch(error => updateListError([...listError, error]));

    await handleListModel();
  }

  async function handleDelete(): Promise<void> {
    // DELETA
    let id: number = getValues('id')!;

    await modelMethods.remove(id)
      .then(() => showAlert({ show: true, content: 'Deletado com sucesso', color: 'success', time: 5000 }))
      .catch(error => updateListError([...listError, error]));

    await handleListModel();
  }

  /**
   *
   * MODAL
   *
   */
  const [inEditing, updateInEditing] = useState<boolean>(false);
  const [openModal, updateOpenModal] = useState<boolean>(false);
  const [openModalFilter, updateOpenModalFilter] = useState<boolean>(false);

  async function handleModalEdit(id: number): Promise<void> {
    // ABRE MODAL PARA EDICAO
    await handleModel(id);
    updateInEditing(true);
    updateOpenModal(true);
  }

  function handleModalCreate() {
    // ABRE MODAL PARA CRIACAO
    updateLoading(false);
    updateOpenModal(true);
    updateInEditing(false);
    updateOpenModalFilter(false);
  }

  function handleModalFilter() {
    // ABRE MODAL PARA FILTRO
    updateOpenModalFilter(true);
  }

  function handleCloseModal() {
    // FECHA MODAL
    clearField();
    updateInEditing(false);
    updateOpenModal(false);
    updateOpenModalFilter(false);
  }

  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
      <SpinnerSC>
        <div className='spinner-border text-secondary' role='status'>
        </div>
      </SpinnerSC>
    );
  }


  /**
   *
   * INIT
   *
   */
  useEffect(() => {
    handlePanelInfo();
    handleListModel();
  }, []);


  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}
      {!loading && <CreatePageContent />}

      <Modal show={openModal} onHide={handleCloseModal} size={'xl'} fullscreen={'md-down'}>
        <Container>
          <form onSubmit={handleSubmit(handleCreteOrEdit)} className='mt-0' noValidate>
            <Modal.Header closeButton>
              <Modal.Title className='ms-3 lead' style={{ fontSize: '30px' }}>NOVO</Modal.Title>
            </Modal.Header>
            <AlertModal />
            <Modal.Body>
              <Form.Control type='hidden' {...register('id')} />

            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start'>
              <Button variant='primary' size='sm' type='submit'>Salvar</Button>
              <Button variant='outline-primary' size='sm' onClick={clearField}>Limpar</Button>
              {inEditing && <Button variant='danger' size='sm' onClick={handleDelete}>Deletar</Button>}
            </Modal.Footer>
          </form>
        </Container>
      </Modal>
      <Modal show={openModalFilter} onHide={handleCloseModal} size={'xl'} fullscreen={'md-down'}>
        <Container>

          <Modal.Header closeButton>
            <Modal.Title className='ms-3 lead' style={{ fontSize: '30px' }}>FILTRAR</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='mt-3'>
              <Col>
                <h5>Ops!!!, Estamos trabalhando nisso kkk</h5>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-start'>
            <Button variant='primary' size='sm'>Filtrar</Button>
            <Button variant='outline-primary' size='sm'>Limpar</Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </>
  );

  /**
   *
   * CREATE TABLE
   *
   */
  function CreateTable() {
    if (listModel !== undefined)
      return (
        <Table striped bordered hover responsive className='mt-3' style={{ cursor: 'pointer' }}>
          <thead>
            <tr>
              {tableDescriptions.map((value, index) => {
                return (<th key={index}>{value}</th>);
              })}
            </tr>
          </thead>
          <tbody>
            {listModel.map((value, index) => {
              return (
                <tr key={index} onClick={() => handleModalEdit(value.id!)}>
                  <td>{value.id}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    else
      return (
        <Table striped bordered hover responsive className='mt-3' style={{ cursor: 'pointer' }}>
          <thead>
            <tr>
              {tableDescriptions.map((value, index) => {
                return (<th key={index}>{value}</th>);
              })}
            </tr>
          </thead>
        </Table>
      );
  }

  /**
   *
   * CREATE INITIAL PAGE
   *
   */
  function CreatePageContent() {
    return (
      <>
        <Container>
          <Row>
            <Col>
              <TitlePageComponent titlePage={titlePage} />
            </Col>
            <Col className='d-flex align-items-center justify-content-end'>
              <Button variant='primary' onClick={handleModalCreate}>Novo</Button>
              <Button className='ms-1' variant='outline-primary' onClick={handleModalFilter}>Filtro</Button>
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <Container>
                <Painel panel={panelInfo} />
                <CreateTable />
              </Container>
            </Card.Body>
          </Card>
        </Container>
        <Footer />
      </>
    );
  }
};

export default PageDefault;
