/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { UseAuth } from 'hook/auth-hook';
import { AuthUseCase } from 'service/api/rest/auth-usecase';
import { RestUseCase } from 'service/api/rest/rest-usecase';
import { clearLocalStoragePlanning, getLocalStorage, setLocalStorege } from 'data/cache/localstorage-cache';
import { SelectOptionInterface } from '../../../@types/entity/interface/select-option-interface';
import ErrorNotAuthorizedPage from 'pages/layout/error/error-not-authorized-page';
import { useNavigate } from 'react-router-dom';
import { getEnv } from 'helper/window-helper';
import ChangeUserInternalCompanyPage from 'pages/layout/safety/change-user-internal-company-page';

export const ChangeUserInternalFactory: React.FC = () => {

  let navigate = useNavigate();

  /** CONFIGURACAO */
  const ENDPOINT_USERS = getEnv('REACT_APP_END_POINT_USER')!;
  const ENDPOINT_COMPANIES = getEnv('REACT_APP_END_POINT_COMPANIES')!;
  const ENDPOINT_INTERNAL_SWITCH_USER = getEnv('REACT_APP_END_POINT_INTERNAL_SWITCH_USER')!;
  const LOCALSTORAGE_TOKEN_USER_CHANGE = getEnv('REACT_APP_LOCALSTORAGE_TOKEN_USER_CHANGE')!;
  const LOCALSTORAGE_TOKEN_USER_LOGIN = getEnv('REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN')!;
  const tokenLocalStorageLogin: {
    token: string
  } = getLocalStorage(LOCALSTORAGE_TOKEN_USER_LOGIN);
  const changUserTokenLocalStorage: {
    token: string
  } = getLocalStorage(LOCALSTORAGE_TOKEN_USER_CHANGE);

  /** SELECT */
  const [user, updateUser] = useState<SelectOptionInterface>();
  const [company, updateCompany] = useState<SelectOptionInterface>();
  const [listModelCompanies, updateListModelCompanies] = useState<SelectOptionInterface[]>([]);
  const [listModelUsers, updateListModelUsers] = useState<SelectOptionInterface[]>([]);
  const [listModelRole] = useState<SelectOptionInterface[]>([
    { label: 'GUEST', value: 'GUEST' },
    { label: 'COMPRAS_GESTOR', value: 'COMPRAS_GESTOR' },
    { label: 'COMPRAS_OPERACIONAL', value: 'COMPRAS_OPERACIONAL' },
    { label: 'SCRAP_ADMINISTRADOR', value: 'SCRAP_ADMINISTRADOR' },
    { label: 'SCRAP_COMPRADOR', value: 'SCRAP_COMPRADOR' },
    { label: 'SCRAP_AUDITOR', value: 'SCRAP_AUDITOR' },
    { label: 'SCRAP_CONSULTOR', value: 'SCRAP_CONSULTOR' },
    { label: 'SCRAP_FINANCEIRO', value: 'SCRAP_FINANCEIRO' },
    { label: 'SCRAP_VENDAS', value: 'SCRAP_VENDAS' },
  ]);

  function changeCompaniesSelect(company: SelectOptionInterface) {
    listAllUserByCompanies(company);
    updateCompany(company);
  }

  function changeUserSelect(user: SelectOptionInterface) {
    updateUser(user);
  }

  function changeRoleSelect(roles: SelectOptionInterface[]) {
    listAllUserByCompanies(company!, roles);
  }

  /** CHAMADA BACKEND */
  async function listAllCompanies(): Promise<any> {
    await new RestUseCase(ENDPOINT_COMPANIES).Get()
      .then((response) => {
        if (response.statusCode !== 500 && response.statusCode !== undefined) {
          updateListModelCompanies(response.body.map((value: any) => {
            return { value: value.id, label: value.nome };
          }));
        }
      });
  }

  async function listAllUserByCompanies(company: SelectOptionInterface, roles?: SelectOptionInterface[]): Promise<any> {
    let query = `?empresa=${company?.value}`;

    if (roles)
      query += `&roles=${roles}`;

    await new RestUseCase(ENDPOINT_USERS + query).Get()
      .then((response) => {
        updateListModelUsers(response.body.map((value: any) => {
          return { value: value.id, label: value.nome };
        }));

      });
  }

  async function changeUser(): Promise<any> {
    if (user !== undefined) {
      await new AuthUseCase(ENDPOINT_INTERNAL_SWITCH_USER + `?id=${user?.value}`)
        .Post({
          headers: {
            Authorization: `Bearer ${!changUserTokenLocalStorage ?
              tokenLocalStorageLogin.token : changUserTokenLocalStorage.token}`,
          },
        })
        .then(response => {
          setLocalStorege(LOCALSTORAGE_TOKEN_USER_CHANGE, response.body);
          clearLocalStoragePlanning();
        });
      navigate('/');
      location.reload();
    }
  }

  /** INICIO */
  useEffect(() => {
    listAllCompanies();
  }, []);

  /** VERIFICA PERMISSAO DE ACESSO */
  const { loggedUserData } = UseAuth();
  const [showScreen, updateShowScreen] = useState<boolean>(false);
  useEffect(() => {
    if (loggedUserData?.role) {
      if (loggedUserData.role.includes('IS_GRUPO_EMPRESA')) {
        updateShowScreen(true);
      }
    }
  }, [loggedUserData]);

  /** RENDER */
  return (
    <>
      {showScreen ?
        <ChangeUserInternalCompanyPage
          titlePage={'Troca de Usuário'}
          change={changeUser}
          children={{
            header: undefined,
            body: <>
              <Row>
                <Col>
                  <Form.Label>Empresa:</Form.Label>
                  <Select options={listModelCompanies} onChange={val => {
                    changeCompaniesSelect(val!);
                  }} />
                </Col>
                <Col>
                  <Form.Label>Perfil:</Form.Label>
                  <Select isMulti={true} isDisabled={company ? false : true} options={listModelRole}
                    onChange={val => {
                      let data = val.map((elements: any) => elements.value);
                      changeRoleSelect(data);
                    }} />
                </Col>
                <Col>
                  <Form.Label>Usuário:</Form.Label>
                  <Select isDisabled={company ? false : true} options={listModelUsers} onChange={val => {
                    changeUserSelect(val!);
                  }} />
                </Col>
              </Row>
            </>,
            footer: undefined,
          }}
        />
        :
        <ErrorNotAuthorizedPage />
      }
    </>
  );

};
