import { Button, Modal } from "react-bootstrap"

interface PropsModalConfirmAction {
  showModalConfirmAction: boolean
  closeModalConfirmAction: (() => void)
  titleConfirmAction: string | undefined
  message: string | undefined
  confirmButtonMessage: string | undefined
  closeButtonMessage: string | undefined
  confirmActionFunction: (() => void)
}

export const ModalConfirmAction: React.FC<PropsModalConfirmAction> = ({
  showModalConfirmAction,
  closeModalConfirmAction,
  titleConfirmAction,
  message,
  confirmButtonMessage,
  closeButtonMessage,
  confirmActionFunction
}) => {
  return (
    <Modal show={showModalConfirmAction} onHide={closeModalConfirmAction} size="lg" centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{titleConfirmAction}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-2">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="sm" onClick={() => confirmActionFunction()}>
          {confirmButtonMessage}
        </Button>
        <Button variant="danger" size="sm" onClick={() => closeModalConfirmAction()}>
          {closeButtonMessage}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}