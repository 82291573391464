import { TableDescriptions } from "../../../@types/interfaces/table-interface"

export const ColumsTableAnalyticAdapter: TableDescriptions[] = [
    { ordination: { ordinationName: "ID", ordinationValue: "" }, align: "center", order: 1, id: 0, dataField: "id", text: "ID", hidden: false, fixed: true },
    { ordination: { ordinationName: "FILIAL", ordinationValue: "" }, align: "center", order: 2, id: 1, dataField: "filial", text: "Fil", hidden: false, fixed: true },
    { ordination: { ordinationName: "FILIAL_DESCRICAO", ordinationValue: "" }, align: "left", order: 3, id: 41, dataField: "nomeFantasia", text: "Filial Descrição", hidden: false, fixed: true },
    { ordination: { ordinationName: "BASE", ordinationValue: "" }, align: "center", order: 4, id: 2, dataField: "base", text: "Base", hidden: false },
    { ordination: { ordinationName: "CODIGO", ordinationValue: "" }, align: "center", order: 5, id: 3, dataField: "codigo", text: "Sufixo", hidden: false, fixed: true },
    { ordination: { ordinationName: "NOME", ordinationValue: "" }, align: "left", order: 6, id: 4, dataField: "nome", text: "Descrição", hidden: false },
    { ordination: { ordinationName: "ALERTA", ordinationValue: "" },align: "left", order: 7, id: 5, dataField: "ALERTA", text: "ALERTA", hidden: false, fixed: true },
    { ordination: { ordinationName: "QUANTIDADE", ordinationValue: "" }, align: "center", order: 8, id: 6, dataField: "quantidade", text: "Qtd", hidden: false, fixed: true },
    { align: "right", order: 9, id: 7, dataField: "qme", text: "Qme", hidden: false },
    { ordination: { ordinationName: "MEDIA", ordinationValue: "" }, align: "right", order: 10, id: 8, dataField: "media", text: "Média", hidden: false },
    { ordination: { ordinationName: "PARAMETRO_MES", ordinationValue: "" }, align: "right", order: 11, id: 9, dataField: "parametroMes", text: "PMês", hidden: false },
    { ordination: { ordinationName: "ESTOQUE", ordinationValue: "" }, align: "right", order: 12, id: 10, dataField: "estoque", text: "Est", hidden: false },
    { ordination: { ordinationName: "PENDENTE", ordinationValue: "" }, align: "right", order: 13, id: 11, dataField: "pendente", text: "Pen", hidden: false },
    { ordination: { ordinationName: "BLOQUEADO", ordinationValue: "" }, align: "right", order: 14, id: 12, dataField: "bloqueado", text: "Bloq", hidden: false },
    { ordination: { ordinationName: "TRANSITO", ordinationValue: "" }, align: "right", order: 15, id: 13, dataField: "transito", text: "Tran", hidden: false },
    { ordination: { ordinationName: "EXCESSO", ordinationValue: "" }, align: "right", order: 16, id: 14, dataField: "excesso", text: "Exc", hidden: false },
    { ordination: { ordinationName: "PONTUACAO", ordinationValue: "" }, align: "right", order: 17, id: 15, dataField: "pontuacao", text: "Ppt", hidden: false },
    { ordination: { ordinationName: "CURVA", ordinationValue: "" }, align: "left", order: 18, id: 16, dataField: "curva", text: "Cvf", hidden: false },
    { ordination: { ordinationName: "NOVA_CURVA", ordinationValue: "" }, align: "left", order: 19, id: 17, dataField: "novaCurva", text: "NCvf", hidden: false },
    { ordination: { ordinationName: "STATUS", ordinationValue: "" }, align: "left", order: 20, id: 18, dataField: "status", text: "Status", hidden: false },

    //todo: verificar nome da ordenacao com o backend nome VALOR_PEDIDD esta errado
    { ordination: { ordinationName: "VALOR_PEDIDD", ordinationValue: "" }, align: "right", order: 21, id: 19, dataField: "valorPedido", text: "Valor Pedido", hidden: false, numberFormat: "currency" }, //todo: ver esta nome de item
    { ordination: { ordinationName: "CUSTO_MEDIO", ordinationValue: "" }, align: "right", order: 22, id: 20, dataField: "custoMedio", text: "Cst Méd", hidden: true, numberFormat: "currency" },
    { ordination: { ordinationName: "ULTIMA_VENDA", ordinationValue: "" }, align: "right", order: 23, id: 21, dataField: "ultimaVenda", text: "Ult Ven", hidden: true, typeField: "dateTime" },
    { ordination: { ordinationName: "ULTIMA_COMPRA", ordinationValue: "" }, align: "right", order: 24, id: 22, dataField: "ultimaCompra", text: "Ult Com", hidden: true, typeField: "dateTime"},

    { ordination: { ordinationName: "ESTOQUE_PENDENTE", ordinationValue: "" }, align: "right", order: 25, id: 23, dataField: "estoquePendente", text: "Dias Pend", hidden: true, },
    { ordination: { ordinationName: "ESTOQUE_PROJETADO", ordinationValue: "" }, align: "right", order: 26, id: 24, dataField: "estoqueProjetado", text: "Dias Proj", hidden: true },
    { ordination: { ordinationName: "ESTOQUE_FUTURO", ordinationValue: "" }, align: "right", order: 27, id: 25, dataField: "estoqueFuturo", text: "Dias Futuro", hidden: true },
    { ordination: { ordinationName: "CUSTO_MEDIO_X_ESTOCAGEM", ordinationValue: "" }, align: "right", order: 28, id: 26, dataField: "custoMedioXEstocagem", text: "Cst. Med X Est", hidden: true, numberFormat: "currency" },
    { ordination: { ordinationName: "CUSTO_MEDIO_X_EXC", ordinationValue: "" }, align: "right", order: 29, id: 27, dataField: "custoMedioXExc", text: "Cst. Med X Exc", hidden: true, numberFormat: "currency" },

    //todo: ver todos abaixo
    { ordination: { ordinationName: "PERCENTUAL_DESCONTO", ordinationValue: "" }, align: "right", order: 30, id: 28, dataField: "percentualDesconto", text: "% Desc", hidden: true, numberFormat: "percent" },
    { align: "center", order: 31, id: 29, dataField: "siglaFilial", text: "Filial", hidden: true },
    { align: "center", order: 32, id: 30, dataField: "observacao", text: "Observação", hidden: false, fixed: true },
    { ordination: { ordinationName: "BLOQUEADO_OSS", ordinationValue: "" }, align: "center", order: 33, id: 31, dataField: "bloqueadoOrdemServico", text: "Bloqueado Ordem Servico", hidden: true },
    // { align: "center", order: 33, id: 32, dataField: "dataPesquisa", text: "Data Pesquisa", hidden: true },
    { ordination: { ordinationName: "ESTOQUE_ATUAL", ordinationValue: "" }, align: "right", order: 34, id: 33, dataField: "estoqueAtual", text: "Dias Estoque Atual", hidden: true },
    { ordination: { ordinationName: "PRECO_REPOSICAO", ordinationValue: "" }, align: "right", order: 35, id: 34, dataField: "precoReposicao", text: "Preço Reposição", hidden: true, typeField: "monetary" },
    { align: "center", order: 36, id: 35, dataField: "demandas", text: "Demandas", hidden: true },
    { align: "center", order: 37, id: 36, dataField: "alterado", text: "Alterado", hidden: false, fixed: true },
    { align: "right", order: 37, id: 36, dataField: "bloquadoCompra", text: "BloqCompra", hidden: false, fixed: true },
    { ordination: { ordinationName: "PRECO_VENDA", ordinationValue: "" }, align: "right", order: 38, id: 37, dataField: "precoVenda", text: "Prç Vnd", hidden: true, typeField: "monetary"},
    { ordination: { ordinationName: "PRECO_GARANTIA", ordinationValue: "" }, align: "right", order: 39, id: 38, dataField: "precoGarantia", text: "Prç Gar", hidden: true, typeField: "monetary" },
    { ordination: { ordinationName: "LOCACAO", ordinationValue: "" }, align: "right", order: 40, id: 39, dataField: "locacao", text: "Locação", hidden: true },
    { align: "right", order: 41, id: 40, dataField: "custoMedioXBloq", text: "Cst.Med x Bloq.", hidden: true, typeField: "monetary" },
]