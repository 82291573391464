/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { useState } from 'react';
import { Accordion, Button, Card, Col, Container, Form, ProgressBar, Row, Table } from 'react-bootstrap';
import { UseAlert } from 'hook/alert-hook';
import { SpinnerSC } from 'pages/style/spinner/spinner-styled';
import { Headers } from 'components/header/headers-component';
import { Footer } from 'components/footer/footer-component';
import { sleep } from 'helper/sleep-helper';
import LoadFile from 'service/load-file-service';
import { SelectOptionInterface } from '../../@types/entity/interface/select-option-interface';
import { RestUseCase } from 'service/api/rest/rest-usecase';
import { UseAuth } from 'hook/auth-hook';
import { TableHeaderStyled } from 'pages/style/table/table-header';
import { getEnv } from 'helper/window-helper';
import { TitleGroupComponent } from '../../components/title/title-group-component';

type Props = {
  uploadFileAnalysis: (params: {
    file: File
  }) => Promise<any>
  uploadFileRegistrationInitial: (params: {
    file: File
  }) => Promise<any>
  uploadFileRegistrationInitialUsers: (params: {
    file: File,
    users: string
  }) => Promise<any>
  exportFile: () => Promise<any>
  companyId: number
}

const titlePage = 'Carga de Arquivos';

const FileLoadPage: React.FC<Props> = ({ uploadFileAnalysis, uploadFileRegistrationInitial, uploadFileRegistrationInitialUsers, exportFile, companyId }: Props) => {

  /**
   *
   *
   *
   *
   *  loading */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return <SpinnerSC>
      <div className='spinner-border text-secondary' role='status'></div>
    </SpinnerSC>;
  }

  /**
   *
   * UPLOAD FILE
   *
   */
  const NAME_FILE_DEFAULT = 'Nenhum arquivo selecionado';
  const [nameFile, updateFileName] = useState<string>(NAME_FILE_DEFAULT);
  const [typeFile, updateTypeFile] = useState<number>(10);
  const [processBar, updateProcessBar] = useState<number>(0);
  const [contentFile, updateContentFile] = useState<File | undefined>();
  const [mountUploadFile, updateMountUploadFile] = useState<boolean>(false);
  const [listModelReturnFile, updateListModelReturnFile] = useState<[]>([]);
  const [listModelUsers, updateListModelUsers] = useState<SelectOptionInterface[]>([]);
  const { loggedUserData } = UseAuth();

  async function loadFile(file: File) {
    await cleanStatusBar();

    if (file !== null || file !== undefined) {
      updateContentFile(file);
      updateMountUploadFile(true);
      updateFileName(file.name);
    }
  }

  const typeFileOption: SelectOptionInterface[] = [
    { selected: false, value: 11, label: 'Cadastro Inicial' },
    { selected: false, value: 10, label: 'Análise' },
    { selected: false, value: 9, label: 'Análise Out. Usu.' },

  ];

  async function preValidate() {
    updateProcessBar(0);

    const numberOfColumns = 22;
    const lineSize = 302;
    const fileRead = contentFile!;
    const companyCode = companyId;
    const fileType = typeFile <= 10 ? 10 : 11;

    var resultReadingFile = await LoadFile(fileRead, lineSize, numberOfColumns, fileType, companyCode);
    console.log('RESULTADO LEITURA ==> ', resultReadingFile);

    updateProcessBar(resultReadingFile.readingProgress);

    await sleep(800);

    if (resultReadingFile.error != null) {
      showAlert({ show: true, content: resultReadingFile.error, color: 'danger' });
    } else {
      showAlert({ show: true, content: 'Arquivo processado e validado', color: 'success' });
    }
  }

  const cleanStatusBar = () => updateProcessBar(0);

  function clearField(): void {
    updateProcessBar(0);
    updateContentFile(undefined);
    updateMountUploadFile(false);
    updateFileName(NAME_FILE_DEFAULT);
  }

  async function sendFile() {
    if (!contentFile) {
      showAlert({ show: true, content: 'Selecione um arquivo', color: 'danger', time: 2000 });
      return;
    }
    /** inicia o loading */
    updateLoading(true);
    showAlert({ show: true, content: 'Aguarde um momento', color: 'warning', time: 2000 });

    if (typeFile === 10) {
      await uploadFileAnalysis({ file: contentFile! })
        .then(response => {
          if (response !== undefined) {
            if (response.statusCode !== 500) {
              updateListModelReturnFile(response);
            } else {
              showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
            }
          } else {
            showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
          }
        })
        .catch(() => showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 }));
    } else if (typeFile === 9) {
      var userParams = '?';
      listModelUsers.forEach((value) => {
        if (value.selected === true) {
          userParams += `${encodeURIComponent('idsUsuarios[]')}=${value.value}&`;
        }
      });
      await uploadFileRegistrationInitialUsers({ file: contentFile!, users: userParams.substring(0, userParams.length - 1) })
        .then(response => {
          if (response !== undefined) {
            if (response.statusCode !== 500) {
              updateListModelReturnFile(response);
            } else {
              showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
            }
          } else {
            showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
          }
        })
        .catch(() => showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 }));
    } else {
      await uploadFileRegistrationInitial({ file: contentFile! })
        .then(response => {
          if (response !== undefined) {
            if (response.statusCode !== 500) {
              updateListModelReturnFile(response);
            } else {
              showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
            }
          } else {
            showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
          }
        })
        .catch(() => showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 }));
    }
    /** finaliza o loading */
    updateLoading(false);
  }

  async function listAllUserByCompanies(): Promise<any> {

    const ENDPOINT_USERS = getEnv('REACT_APP_END_POINT_USER')!;
    const companiesActive = true;

    let query = `?empresa=${loggedUserData?.companyId}&ativo=${companiesActive}`;
    let queryByRole = `${query}&roles=COMPRAS_GESTOR&roles=COMPRAS_OPERACIONAL&roles=SCRAP_COMPRADOR`;

    let responseUser: any[] = await new RestUseCase(ENDPOINT_USERS! + query).Get()
      .then((response) => {
        return response.body;
      });

    let responseUserByRoles: any[] = await new RestUseCase(ENDPOINT_USERS! + queryByRole).Get()
      .then((res) => {
        return res.body;
      });

    let Users = responseUser.map((value: any) => {
      const exists = checkObjectExists(value, responseUserByRoles);
      return {
        value: value.id, label: value.nome, selected: exists,
      };
    });

    updateListModelUsers(Users);
  }

  function checkObjectExists(obj: any, array: any[]): boolean {
    return array.some(item => item.id === obj.id);
  }

  async function handleExport() {
    /** inicia o loading */
    showAlert({ show: true, content: 'Aguarde um momento', color: 'warning', time: 2000 });
    let response = await exportFile();
    /** dados da requisicao */
    if (response.statusCode !== 500) {
      const url = window.URL.createObjectURL(new Blob([response.body]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Relatorio_Erro_Carga.csv`);
      document.body.appendChild(link);
      link.click();
      link!.parentNode!.removeChild(link);
      showAlert({ show: true, content: 'Exportado com sucesso', color: 'success', time: 2000 });
    } else
      showAlert({ show: true, content: 'Ops!!! Algo deu errado tente novamente mais tarde...', color: 'danger', time: 2000 });
    /** finaliza o loading */
  }

  /**
   *
   * ALERT / ALERT MODAL
   *
   */
  const { showAlert } = UseAlert();

  function handleSelectTypeItem(params: number) {
    updateTypeFile(params);
  }

  function handleCheckbox(value: number) {
    updateListModelUsers(listModelUsers.map(item => {
      if (item.value === value) {
        item.selected = item.selected === true ? false : true;
      }
      return item;
    }));
  }

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />
      <Container>
        <TitleGroupComponent titlePage={titlePage}>
          <Row>
            <Col>
            </Col>
          </Row>
        </TitleGroupComponent>

        {loading
          ? <Loading />
          :
          <Card>
            <Card.Body>
              <Container>
                <Row className='mt-3'>
                  <Col md={3}>
                    <Form.Label>Tipo do arquivo:</Form.Label>
                    <Select
                      defaultValue={typeFileOption[1]}
                      onChange={val => {
                        updateListModelUsers([]);
                        if (val?.label === 'Análise Out. Usu.') {
                          listAllUserByCompanies();
                        }

                        handleSelectTypeItem(val?.value);
                      }}
                      options={typeFileOption}
                    />
                  </Col>
                  {listModelUsers.length > 0 ?
                    <>
                      <Accordion className='mt-3' defaultActiveKey='0'>
                        <Accordion.Item eventKey='0'>
                          <Accordion.Header>Usuários</Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              {listModelUsers.map((checkboxValue, index) => {
                                return (
                                  <Col key={index} md={6}>
                                    <Form.Check label={checkboxValue.label} type='checkbox' value={checkboxValue.value}
                                      checked={checkboxValue.selected} onChange={(e) => {
                                        handleCheckbox(Number(e.target.value));
                                      }} />
                                  </Col>
                                );
                              })}
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                    :
                    <></>
                  }
                  {/* {listModelUsers.length > 0 ?
                                        <Col>
                                            <Form.Label>Usuários:</Form.Label>
                                            <Select
                                                isMulti={true}
                                                onChange={val => {
                                                    updateModelUsers(val as any)
                                                }}
                                                options={listModelUsers}
                                            />
                                        </Col>
                                        :
                                        <></>
                                    } */}
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Card className='py-5 mb-4' style={{ backgroundColor: '#F0F0F0', border: 'none' }}>
                      <Row className='d-flex justify-content-center mb-5'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' fill='currentColor'
                          className='bi bi-file-earmark-text' viewBox='0 0 16 16' color='#0433BF'>
                          <path
                            d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z' />
                          <path
                            d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z' />
                        </svg>
                      </Row>
                      <Row className='d-flex justify-items-center m-auto mb-2'>
                        <span>{nameFile}</span>
                      </Row>
                      <UploadFile />
                    </Card>
                  </Col>
                </Row>
                <div>
                  <Button variant='primary' size='sm' type='submit' onClick={sendFile}>Carregar</Button>
                  <Button className='mx-1' variant='outline-primary' size='sm' onClick={preValidate}>Pré
                    validar</Button>
                  <Button variant='outline-primary' size='sm' onClick={clearField}>Limpar</Button>
                </div>
              </Container>
              <CreateTable />
            </Card.Body>
          </Card>
        }

      </Container>
      <Footer />
    </>
  );

  /**
   *
   * CREATE TABLE
   *
   */
  function CreateTable() {
    return (
      <>
        {listModelReturnFile.length > 0
          ?
          <Container className='mt-3'>
            <TableHeaderStyled>
              <Table>
                <thead>
                  <tr>
                    <th>status</th>
                    <th>Quantidade</th>
                    <th>Mensagem</th>
                  </tr>
                </thead>
                <tbody>
                  {listModelReturnFile.map((value: any, index) => {
                    return (
                      <tr key={index}>
                        <td>{value.lacStrStatus}</td>
                        <td>{value.lacIntQuantidade}</td>
                        <td>{value.lacStrMensagem}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableHeaderStyled>
            <div>
              <Button variant='primary' size='sm' type='submit' onClick={handleExport}>Exportar Log de Erros</Button>
            </div>
          </Container>
          :
          <></>

        }
      </>
    );
  }

  /**
   *
   * CREATE UPDATE FILE
   *
   */
  function UploadFile() {

    if (!mountUploadFile) {
      return (
        <>
          <Col md={10} sm={12} lg={10} className='m-auto  mb-2'>
            <input type='file' className='form-control' id='campoFile'
              onChange={event => loadFile(event.target.files![0])} />
          </Col>
        </>
      );
    } else {
      return (
        <>

          <Col lg={10} md={10} sm={10} xs={9} className='m-auto  mb-5'>
            <ProgressBar animated now={processBar} />
          </Col>
        </>
      );
    }
  }
};

export default FileLoadPage;
