/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Pagination, Row, Table } from 'react-bootstrap';
import { CrudInterface } from '../../../@types/entity/interface/crud-interface';
import { UseAlert } from 'hook/alert-hook';
import { UseAlertModal } from 'hook/alert-modal-hook';
import { Footer } from 'components/footer/footer-component';
import { Headers } from 'components/header/headers-component';
import { Loading } from 'components/loading/loading-component';
import { SelectOptionInterface } from '../../../@types/entity/interface/select-option-interface';
import { OriginalItemStatusEntity, OriginalItemStatusInputs } from '../../../@types/entity/original-item-status-entity';
import { SelectProvider } from 'components/select/provider-select-component';
import { TableHeaderFixedStyled } from 'pages/style/table/table-header-fixed-styled';
import { getParameterUrlByName, parseLinkHeader } from 'helper/request-helper';
import { TitleGroupComponent } from '../../../components/title/title-group-component';

interface LinkHeader {
  first: string,
  last: string,
  next: string,
  prev: string
}

type Props = {
  modelMethods: CrudInterface<OriginalItemStatusEntity>,
  providerOption: SelectOptionInterface[],
}

const tableDescriptions: {
  label: string,
  align: 'center' | 'left' | 'right'
}[] = [
    { label: 'ID', align: 'center' },
    { label: 'Código do Status 1', align: 'center' },
    { label: 'Código do Status 2', align: 'center' },
    { label: 'Código do Status 3', align: 'center' },
    { label: 'Nome', align: 'left' },
  ];

const titlePage = 'Cadastrar Status de Item Original';

const OriginalItemStatusPage: React.FC<Props> = ({ modelMethods, providerOption }: Props) => {

  /**
   *
   * ALERT / ALERT MODAL
   *
   */
  const { showAlert } = UseAlert();
  const { messageAlertModal, showAlertModal } = UseAlertModal();
  const cleanAlertModal = () => showAlertModal({ show: false, content: '', color: '' });

  function AlertModal() {
    if (messageAlertModal?.show) {
      return (
        <div className={'alert alert-' + messageAlertModal.color + ' fade show mx-5 mt-2 alert-dismissible'}
          role='alert'>
          {messageAlertModal.content}
          {!messageAlertModal.time &&
            <button
              type='button'
              className='ms-1 btn-close btn-sm'
              data-bs-dismiss='alert'
              aria-label='Close'
              onClick={() => cleanAlertModal()}>
            </button>
          }
        </div>
      );
    } else {
      return (
        <></>
      );
    }
  }

  /**
   *
   * FORM HOOK
   *
   */
  const { register, handleSubmit, reset, getValues, setValue, formState: { errors }, control } = useForm<OriginalItemStatusInputs>();

  function clearField(): void {
    // LIMPA OS CAMPOS
    reset({
      fornecedor: 0,
      dataCadastro: '',
      label: '',
      codigo1: '',
      codigo2: '',
      codigo3: '',
    });
  }

  /**
   *
   * ERROR
   *
   */
  const [listError, updateListError] = useState<string[]>([]);

  /**
   *
   * CRUD MODEL / MODELS
   *
   */
  const [listModel, updateListModel] = useState<OriginalItemStatusEntity[]>([]);

  async function handleListModel(params?: {
    pagination?: string,
    page: number,
    idProvider?: number
  }): Promise<void> {

    let pagePagination;

    updateIdProvider(params?.idProvider);

    var modelRequest = { pagination: params === undefined ? 0 : params?.pagination, idProvider: params === undefined ? idProvider : params?.idProvider };

    updateLoading(true);

    if (params) {
      pagePagination = params.page;
    } else {
      pagePagination = 0;
    }

    let response;
    response = await modelMethods.getAll(modelRequest);

    /** links da paginacao */
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(response.headers['link']) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(pagePagination, parseInt(getParameterUrlByName('page', links.first)!), parseInt(getParameterUrlByName('page', links.last)!));
    }

    // BUSCA LISTAGEM DE MODELS
    if (response.statusCode === 200) {
      updateListModel(response.body);
    }
    updateLoading(false);
    handleCloseModal();
  }

  async function handleModel(id: number): Promise<void> {
    // BUSCA MODEL
    await modelMethods.getById(id)
      .then(response => {
        // SETAR OUTROS CAMPOS
        setValue('id', response.id!);
        setValue('fornecedor', response.fornecedor);
        setValue('dataCadastro', response.dataCadastro);
        setValue('label', response.label);
        setValue('codigo1', response.codigo1);
        setValue('codigo2', response.codigo2);
        setValue('codigo3', response.codigo3);
      })
      .catch(error => updateListError([...listError, error]));
  }

  async function handleCreteOrEdit(modelFields: OriginalItemStatusInputs): Promise<void> {
    // EDITA OU CRIA
    const model: OriginalItemStatusInputs = {
      fornecedor: idProvider!,
      dataCadastro: '2022-08-09T17:14:56.303Z',
      label: modelFields.label,
      codigo1: modelFields.codigo1,
      codigo2: modelFields.codigo2,
      codigo3: modelFields.codigo3,
    };

    if (inEditing === true)
      await modelMethods.edit(model, modelFields.id!)
        .then(() => showAlert({ show: true, content: 'Editado com sucesso', color: 'success', time: 5000 }))
        .catch(error => updateListError([...listError, error]));
    else
      await modelMethods.create(model)
        .then(() => showAlert({ show: true, content: 'Salvo com sucesso', color: 'success', time: 5000 }))
        .catch(error => updateListError([...listError, error]));

    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleListModel({ pagination: `&page=${0}&size=${size}`, page: 0, idProvider: idProvider });
  }

  async function handleDelete(): Promise<void> {
    // DELETA
    let id: number = getValues('id')!;

    await modelMethods.remove(id)
      .then((response) => {
        if (response === true)
          showAlert({ show: true, content: 'Deletado com sucesso', color: 'success', time: 5000 });
        else
          showAlert({ show: true, content: 'Não Foi possível deletar, verifique suas credenciais', color: 'danger', time: 5000 });
      })
      .catch(error => updateListError([...listError, error]));

    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleListModel({ pagination: `&page=${0}&size=${size}`, page: 0, idProvider: idProvider });
  }

  /**
   *
   * MODAL
   *
   */
  const [inEditing, updateInEditing] = useState<boolean>(false);
  const [openModal, updateOpenModal] = useState<boolean>(false);

  async function handleModalEdit(id: number): Promise<void> {
    // ABRE MODAL PARA EDICAO
    await handleModel(id);
    updateInEditing(true);
    updateOpenModal(true);
  }

  function handleModalCreate() {
    // ABRE MODAL PARA CRIACAO
    updateLoading(false);
    updateOpenModal(true);
    updateInEditing(false);
  }

  function handleCloseModal() {
    // FECHA MODAL
    clearField();
    updateInEditing(false);
    updateOpenModal(false);
  }

  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  /**
   *
   *
   *
   *
   *  paginacao */
  var totalPageSize: number = 50;
  const [firstPagination, updateFirstPagination] = useState<string>('');
  const [lastPagination, updateLastPagination] = useState<string>('');
  const [nextPagination, updateNextPagination] = useState<string>('');
  const [prevPagination, updatePrevPagination] = useState<string>('');
  const [paginationItemList, updatePaginationItemList] = useState<{
    item: number,
    active: boolean
  }[]>();
  const setValuePaginations = (paginationsActive: number, prev: number, next: number) => {
    let modelPaginationList: any[] = [];
    for (let index = paginationsActive; index < (6 + paginationsActive); index++) {
      if (!(index > next))
        modelPaginationList.push({ item: index, active: index === paginationsActive ? true : false });
    }
    updatePaginationItemList(modelPaginationList);
  };
  const onClickItemPagination = async (pagination: any) => {
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    handleListModel({ pagination: `&page=${pagination.item}&size=${totalPageSize}`, page: parseInt(pagination.item), idProvider: idProvider });
  };
  const onClickFirstPagination = async () => {
    const size: string = getParameterUrlByName('size', firstPagination)!;
    handleListModel({ pagination: `&page=${0}&size=${size}`, page: 0, idProvider: idProvider });
  };
  const onClickLastPagination = async () => {
    const page: string = getParameterUrlByName('page', lastPagination)!;
    const size: string = getParameterUrlByName('size', lastPagination)!;
    handleListModel({ pagination: `&page=${page}&size=${size}`, page: parseInt(page), idProvider: idProvider });
  };
  const onClickNextPagination = async () => {
    const page: string = getParameterUrlByName('page', nextPagination)!;
    const size: string = getParameterUrlByName('size', nextPagination)!;
    handleListModel({ pagination: `&page=${page}&size=${size}`, page: parseInt(page), idProvider: idProvider });
  };
  const onClickPrevPagination = async () => {
    const page: string = getParameterUrlByName('page', prevPagination)!;
    const size: string = getParameterUrlByName('size', prevPagination)!;
    handleListModel({ pagination: `&page=${page}&size=${size}`, page: parseInt(page), idProvider: idProvider });
  };

  /**
   *
   * PROVIDER
   *
   */
  const [idProvider, updateIdProvider] = useState<number>();
  const [modelProvider, updateModelProvider] = useState<SelectOptionInterface>();

  function handleSelectProvider(filial: {
    value: number,
    label: string
  }) {
    updateModelProvider(filial);
    updateIdProvider(filial.value);
    handleListModel({ pagination: `&page=${0}&size=${totalPageSize}`, page: 0, idProvider: filial.value });
  }

  function CreateSelectProvider() {
    return (
      <div className='m-3'>
        <Row className='d-flex align-items-center justify-content-start'>
          <Col md={1}>
            <Form.Label>Fornecedor:</Form.Label>
          </Col>
          <Col md={3}>
            <SelectProvider passOnTheValue={handleSelectProvider} defaultValue={modelProvider} />
          </Col>
        </Row>
      </div>
    );
  }

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
  }, []);

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}
      {!loading && <CreatePageContent />}

      <Modal show={openModal} onHide={handleCloseModal} size={'xl'} fullscreen={'md-down'}>
        <Container>
          <form onSubmit={handleSubmit(handleCreteOrEdit)} className='mt-0' noValidate>
            <Modal.Header closeButton>
              <Modal.Title className='ms-3 lead'
                style={{ fontSize: '30px' }}>{inEditing ? 'EDITAR' : 'NOVO'}</Modal.Title>
            </Modal.Header>
            <AlertModal />
            <Modal.Body>

              <Form.Control type='hidden' {...register('id')} />
              <Row className='mt-3'>
                <Col md={2}>
                  <Form.Label column>Codigo: <h6>{getValues('id')}</h6></Form.Label>
                </Col>
                <Col md={10}>
                  <Form.Label>Nome:</Form.Label>
                  <Col>
                    <Form.Control type='text' {...register('label')} />
                  </Col>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={4}>
                  <Form.Label>Codigo 1:</Form.Label>
                  <Col>
                    <Form.Control type='text' {...register('codigo1')} />
                  </Col>
                </Col>
                <Col md={4}>
                  <Form.Label>Codigo 2:</Form.Label>
                  <Col>
                    <Form.Control type='text' {...register('codigo2')} />
                  </Col>
                </Col>
                <Col md={4}>
                  <Form.Label>Codigo 3:</Form.Label>
                  <Col>
                    <Form.Control type='text' {...register('codigo3')} />
                  </Col>
                </Col>
              </Row>

            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start'>
              <Button variant='primary' size='sm' type='submit'>Salvar</Button>
              <Button variant='outline-primary' size='sm' onClick={clearField}>Limpar</Button>
              {inEditing && <Button variant='danger' size='sm' onClick={handleDelete}>Deletar</Button>}
            </Modal.Footer>
          </form>
        </Container>
      </Modal>
    </>
  );

  /**
   *
   * CREATE TABLE
   *
   */
  function CreateTable() {
    if (listModel !== undefined)
      return (
        <TableHeaderFixedStyled>
          <Table>
            <thead>
              <tr>
                {tableDescriptions.map((value, index) => {
                  return (
                    <th className='px-1' key={index}>
                      <div>
                        <p style={{ whiteSpace: 'nowrap', textAlign: value.align }} className='m-1'>{value.label}</p>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {listModel.map((value, index) => {
                return (
                  <tr key={index} onClick={() => handleModalEdit(value.id!)}>
                    <td align='center'>{value.id}</td>
                    <td align='center'>{value.codigo1}</td>
                    <td align='center'>{value.codigo2}</td>
                    <td align='center'>{value.codigo3}</td>
                    <td align='left'>{value.label}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableHeaderFixedStyled>
      );
    else
      return (
        <TableHeaderFixedStyled>
          <Table>
            <thead>
              <tr>
                {tableDescriptions.map((value, index) => {
                  return (<th key={index} style={{ whiteSpace: 'nowrap', textAlign: value.align }}>{value.label}</th>);
                })}
              </tr>
            </thead>
          </Table>
        </TableHeaderFixedStyled>
      );
  }

  /**
   *
   * CREATE INITIAL PAGE
   *
   */
  function CreatePageContent() {
    return (
      <>
        <div>
          <div className='mx-4'>
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className='d-flex align-items-center justify-content-end'>
                  <Button variant='primary' onClick={handleModalCreate} size='sm'>Novo</Button>
                </Col>
              </Row>
            </TitleGroupComponent>
          </div>
          <div className='mx-2'>
            <Card>
              <CreateSelectProvider />

              {listModel.length > 0 &&
                <Card.Body>
                  <div>
                    <CreateTable />
                    <div className='mt-4'>
                      <Row className='d-flex align-items-center justify-content-center'>
                        <Col className='d-flex align-items-center justify-content-center'>
                          <Pagination size='sm'>
                            <Pagination.First onClick={onClickFirstPagination}>Primeira</Pagination.First>
                            <Pagination.Prev onClick={onClickPrevPagination}>Anterior</Pagination.Prev>
                            {paginationItemList?.map((value, key) => {
                              return (<Pagination.Item key={key} active={value.active}
                                onClick={() => onClickItemPagination(value)}>{value.item + 1}</Pagination.Item>);
                            })}
                            <Pagination.Next onClick={onClickNextPagination}>Próxima</Pagination.Next>
                            <Pagination.Last onClick={onClickLastPagination}>Última</Pagination.Last>
                          </Pagination>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card.Body>
              }

              {listModel.length === 0 &&
                <div className='d-flex align-items-center justify-content-center'>
                  <p className='m-5'>Não há nada a ser exibido, clique em processar ou tente mais tarde</p>
                </div>
              }

            </Card>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default OriginalItemStatusPage;
