import { ReactNode } from "react"
import { Modal } from "react-bootstrap"

interface PropsModalIcon {
  showModalIcon: boolean
  closeIcon: (() => void) | undefined
  typeModalIcon: string | undefined
  dataModalIcon: ReactNode
}

export const ModalIcon: React.FC<PropsModalIcon> = ({
  showModalIcon,
  closeIcon,
  typeModalIcon,
  dataModalIcon
 }) => {
  return (
    <Modal show={showModalIcon} onHide={closeIcon} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{typeModalIcon}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-2">{dataModalIcon}</div>
      </Modal.Body>
    </Modal>
  )
}
