
import { ReactNode } from "react"
import { Modal } from "react-bootstrap"

type Props = {
    isOpen: boolean,
    isEditing?: boolean,
    title: string,
    children: {
        body: ReactNode
        footer: ReactNode
    }
    open: any
    close: any
}

export function ModalListItemGenerateScrap({ title, children, close, isOpen }: Props) {
    return (
        <>
            <Modal show={isOpen} onHide={() => close()} size={"xl"} fullscreen={"md-down"}>
                <Modal.Header closeButton>
                    <Modal.Title className="ms-3 lead" style={{ fontSize: "30px" }}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children.body}

                    {/* {children[0]}
                    <div className="mt-3">
                        <CreateModalReport tableHeader={tableHeader} tableBody={tableBody} entityFields={entityFields} configTable={configTable} />
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    {children.footer}

                    {/* <Button
                        variant="outline-primary"
                        onClick={close}
                        size="sm"
                    >
                        Fechar
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )

}
