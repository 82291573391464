import {
  FiltersDefault,
  FilterItemInputDate,
  FilterItemInputNumber,
  FilterItemInputSelect,
  FilterItemInputMultiSelect,
  FilterItemInputTextList,
} from '../../../@types/interfaces/filter-interface';

let listMesesUltimaCompra = [];
let listMesesUltimaVenda = [];
let listPontuacaoItem = [];
for (let index = 0; index <= 21; index++) {
  listPontuacaoItem.push({ label: `${index}`, value: index });
  listMesesUltimaCompra.push({ label: `${index}`, value: index });
  listMesesUltimaVenda.push({ label: `${index}`, value: index });
}

export const ManagerialFilterPlanningAdapter: FiltersDefault[] = [
  {
    label: 'Base',
    typeFilter: 'BASE',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'bases',
        typeInput: 'multi-select',
        optionsInput: [],
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Base Descrição',
    typeFilter: 'BASE_DESC',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'descricoes',
        typeInput: 'multi-select',
        optionsInput: [],//lista de descricoes do endpoint
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Código do Item',
    typeFilter: 'CODIGO_ITEM',
    field: [
      {
        name: 'codigos',
        typeInput: 'text-list',
        separator: ';',
      } as FilterItemInputTextList,
    ],
  },
  {
    label: 'Código do Fabricante',
    typeFilter: 'CODIGO_FABRICANTE',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'codigos_fabricantes',
        typeInput: 'multi-select',
        optionsInput: [],
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Curva Freq. e Faixa de Preço',
    typeFilter: 'CURVA_FAIXA',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'curvas',
        typeInput: 'multi-select',
        optionsInput: [
          { label: 'A', value: 'A' },
          { label: 'B', value: 'B' },
          { label: 'C', value: 'C' },
          { label: 'D', value: 'D' },
          { label: 'E', value: 'E' },
          { label: 'F', value: 'F' },
          { label: 'N', value: 'N' },
        ],
      } as FilterItemInputMultiSelect,
      {
        name: 'faixas',
        typeInput: 'multi-select',
        optionsInput: [
          { label: '1 - 250.000 à 99999999.99', value: 1 },
          { label: '2 - 70.00 à 249.99', value: 2 },
          { label: '3 - 13.00 à 69.99', value: 3 },
          { label: '4 - 0.00 à 12.99', value: 4 },
        ],
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Filial',
    typeFilter: 'FILIAL',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'filiais',
        typeInput: 'multi-select',
        optionsInput: [],
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Status',
    typeFilter: 'STATUS',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'status',
        typeInput: 'multi-select',
        optionsInput: [
          { label: 'Blq.Def.Asc.', value: 'Blq.Def.Asc.' },
          { label: 'Blq.Def.Desc.', value: 'Blq.Def.Desc.' },
          { label: 'Blq.Definitivo', value: 'Blq.Definitivo' },
          { label: 'Blq.Téc.Asc.', value: 'Blq.Téc.Asc.' },
          { label: 'Blq.Téc.Desc.', value: 'Blq.Téc.Desc.' },
          { label: 'Blq.Técnico', value: 'Blq.Técnico' },
          { label: 'Crítico', value: 'Crítico' },
          { label: 'Crítico Asc.', value: 'Crítico Asc.' },
          { label: 'Crítico Des.', value: 'Crítico Des.' },
          { label: 'Dem. Zero', value: 'Dem. Zero' },
          { label: 'Dem.Zero Asc.', value: 'Dem.Zero Asc.' },
          { label: 'Dm.Zero S/Vda', value: 'Dm.Zero S/Vda' },
          { label: 'Estável', value: 'Estável' },
          { label: 'Estável Asc.', value: 'Estável Asc.' },
          { label: 'Estável Des.', value: 'Estável Des.' },
          { label: 'Excesso', value: 'Excesso' },
          { label: 'Excesso Asc.', value: 'Excesso Asc.' },
          { label: 'Excesso Des.', value: 'Excesso Des.' },
          { label: 'Novo Asc.', value: 'Novo Asc.' },
          { label: 'Novo Crítico', value: 'Novo Crítico' },
          { label: 'Novo Des.', value: 'Novo Des.' },
          { label: 'Novo Premiun', value: 'Novo Premiun' },
          { label: 'Novo S/Vda', value: 'Novo S/Vda' },
          { label: 'Pleno', value: 'Pleno' },
          { label: 'Pleno Asc.', value: 'Pleno Asc.' },
          { label: 'Pleno Des.', value: 'Pleno Des.' },
        ],
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Data da ultima compra',
    typeFilter: 'DATA_ULTIMA_COMPRA',
    field: [
      {
        name: 'inicio_periodo_compra',
        typeInput: 'date',
      } as FilterItemInputDate,
      {
        name: 'fim_periodo_compra',
        typeInput: 'date',
      } as FilterItemInputDate,
    ],
  },
  {
    label: 'Data da última venda',
    typeFilter: 'DATA_ULTIMA_VENDA',
    field: [
      {
        name: 'inicio_periodo_venda',
        typeInput: 'date',
      } as FilterItemInputDate,
      {
        name: 'fim_periodo_venda',
        typeInput: 'date',
      } as FilterItemInputDate,
    ],
  },
  {
    //todo: ajustado pontuacao do item colocando no front, validar com o backend o envio dos dados
    label: 'Pontuação do item',
    typeFilter: 'PONTUACAO_ITEM',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'pontuacoes',
        typeInput: 'multi-select',
        optionsInput: listPontuacaoItem,
      } as FilterItemInputMultiSelect,
    ],
  },
  {
    label: 'Disponibilidade em Estoque',
    typeFilter: 'ESTOQUE_DISPONIVEL',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'estoque_disponivel',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Dias estoque atual',
    typeFilter: 'DIAS_ESTOQUE_ATUAL',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'estoque_atual',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Dias estoque pendente',
    typeFilter: 'DIAS_ESTOQUE_PENDENTE',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'estoque_pendente',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Dias estoque projeção',
    typeFilter: 'DIAS_ESTOQUE_PROJECAO',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'estoque_projecao',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },

  {
    label: 'Dias estoque futuro',
    typeFilter: 'DIAS_ESTOQUE_FUTURO',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'estoque_futuro',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Pendência estoque',
    typeFilter: 'PENDENCIA_ESTOQUE',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'pendencia_estoque',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Qtd de Compra por Unidade',
    typeFilter: 'COMPRA_UNIDADE',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'compra_unidade',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Meses da última compra',
    typeFilter: 'MESES_ULTIMA_COMPRA',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'meses_ultima_compra',
        typeInput: 'text-list',
        separator: ';',
      } as FilterItemInputTextList,
    ],
  },
  {
    label: 'Meses da última venda',
    typeFilter: 'MESES_ULTIMA_VENDA',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Contém', value: 'IN' },
          { label: 'Não contém', value: 'NOT_IN' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'meses_ultima_venda',
        typeInput: 'text-list',
        separator: ';',
      } as FilterItemInputTextList,
    ],
  },

  {
    label: 'Quantidade de excesso',
    typeFilter: 'QUANTIDADE_EXCESSO',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'quantidade_excesso',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Estoque por custo médio',
    typeFilter: 'ESTOQUE_POR_CUSTO_MEDIO',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'custo_medio',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Média fixa',
    typeFilter: 'MEDIA_FIXA',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'media_fixa',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
  {
    label: 'Vlr. PMês Projetada',
    typeFilter: 'PARAMETRO_MES',
    field: [
      {
        name: 'type',
        typeInput: 'select',
        optionsInput: [
          { label: 'Maior', value: 'GREATER' },
          { label: 'Menor', value: 'LESSER' },
          { label: 'Diferente', value: 'NOT_EQUALS' },
          { label: 'Igual', value: 'EQUALS' },
        ],
      } as FilterItemInputSelect,
      {
        name: 'parametro_mes',
        typeInput: 'number',
      } as FilterItemInputNumber,
    ],
  },
];
