import Select from 'react-select';
import { useEffect, useState } from 'react';
import { Filial } from 'service/api/rest/filial-usecase';
import { SelectOptionInterface } from '../../@types/entity/interface/select-option-interface';
import { SortByKey } from 'helper/arrays-helper';
import { getEnv } from 'helper/window-helper';

type Props = {
  passOnTheValue: any;
  isMulti?: boolean;
  defaultValue?: SelectOptionInterface[];
  addKey?: { id: any; nome: string };
};

export const SelectFilial: React.FC<Props> = ({ passOnTheValue, isMulti, defaultValue, addKey }: Props) => {
  const ENDPOINT_FILIAL = getEnv('REACT_APP_END_POINT_FILIAL');
  const [options, updateOption] = useState<[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOptionInterface[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  async function handleOptions() {
    await new Filial(`${ENDPOINT_FILIAL!}?modulos=COMPRAS`)
      .Get()
      .then((response) => {
        if (response.statusCode !== 500 && response.statusCode !== undefined) {

          // Verifica se `addKey` já existe na lista com base no id
          if (addKey && !response.body.some((item: any) => item.id === addKey.id)) {
            response.body.push(addKey);
          }

          // Ordena e cria as opções de seleção
          SortByKey(response.body, 'id');
          updateOption(
            response.body.map((value: any) => ({
              value: value.id,
              label: value.nome,
            }))
          );
        }
      })
      .catch((error) => console.log('error', error));
  }


  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(options);
    }
    setSelectAll(!selectAll);
    passOnTheValue(options);
  };

  const handleOptionChange = (selected: any) => {
    setSelectedOptions(selected as SelectOptionInterface[]);
    setSelectAll((selected as SelectOptionInterface[]).length === options.length);
    passOnTheValue(selected);
  };

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
    handleOptions();
  }, []);

  return (
    <>
      <Select
        options={options}
        defaultValue={defaultValue}
        onChange={(val) => handleOptionChange(val)}
        placeholder="Selecione a Filial"
        isMulti={isMulti ? true : false}
        value={selectedOptions}
      />
      <label>
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} /> Selecionar Todos
      </label>
    </>
  );
};
