import { TableDescriptions } from '../../../@types/interfaces/table-interface';

export const ColumsTableSyntheticAdapter: TableDescriptions[] = [
  {
    ordination: { ordinationName: 'ID', ordinationValue: '' },
    align: 'center',
    order: 1,
    id: 0,
    dataField: 'id',
    text: 'ID',
    hidden: false,
    fixed: true,
  },
  {
    ordination: { ordinationName: 'BASE', ordinationValue: '' },
    align: 'center',
    order: 3,
    id: 2,
    dataField: 'base',
    text: 'Base',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'CODIGO', ordinationValue: '' },
    align: 'center',
    order: 4,
    id: 3,
    dataField: 'codigo',
    text: 'Sufixo',
    hidden: false,
    fixed: true,
  },
  {
    ordination: { ordinationName: 'NOME', ordinationValue: '' },
    align: 'left',
    order: 5,
    id: 4,
    dataField: 'nome',
    text: 'Descrição',
    hidden: false,
  },
  {
    ordination: { ordinationName: '', ordinationValue: '' },
    align: 'left',
    order: 6,
    id: 5,
    dataField: 'ALERTA',
    text: 'ALERTA',
    hidden: false,
    fixed: true,
  },
  {
    ordination: { ordinationName: 'QUANTIDADE', ordinationValue: '' },
    align: 'center',
    order: 7,
    id: 6,
    dataField: 'quantidade',
    text: 'Qtd',
    hidden: false,
    fixed: true,
  },
  { align: 'right', order: 8, id: 7, dataField: 'qme', text: 'Qme', hidden: false },
  {
    ordination: { ordinationName: 'MEDIA', ordinationValue: '' },
    align: 'right',
    order: 9,
    id: 8,
    dataField: 'media',
    text: 'Média',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'PROJECAO_MES', ordinationValue: '' },
    align: 'right',
    order: 10,
    id: 9,
    dataField: 'projecaoMes',
    text: 'PMês',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'ESTOQUE', ordinationValue: '' },
    align: 'right',
    order: 11,
    id: 10,
    dataField: 'estoque',
    text: 'Est',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'PENDENTE', ordinationValue: '' },
    align: 'right',
    order: 12,
    id: 11,
    dataField: 'pendente',
    text: 'Pen',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'EXCESSO', ordinationValue: '' },
    align: 'right',
    order: 15,
    id: 14,
    dataField: 'excesso',
    text: 'Exc',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'PONTUACAO', ordinationValue: '' },
    align: 'right',
    order: 16,
    id: 15,
    dataField: 'pontuacao',
    text: 'Ppt',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'CURVA', ordinationValue: '' },
    align: 'left',
    order: 17,
    id: 16,
    dataField: 'curva',
    text: 'Cvf',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'NOVA_CURVA', ordinationValue: '' },
    align: 'left',
    order: 18,
    id: 17,
    dataField: 'novaCurva',
    text: 'NCvf',
    hidden: false,
  },
  {
    ordination: { ordinationName: 'STATUS', ordinationValue: '' },
    align: 'left',
    order: 19,
    id: 18,
    dataField: 'status',
    text: 'Status',
    hidden: false,
  },

  {
    ordination: { ordinationName: 'VALOR_PEDIDO', ordinationValue: '' },
    align: 'right',
    order: 20,
    id: 19,
    dataField: 'valorPedido',
    text: 'Valor Pedido',
    hidden: true,
    numberFormat: 'currency',
  }, //todo: ver esta nome de item

  {
    ordination: { ordinationName: 'ESTOQUE_PENDENTE', ordinationValue: '' },
    align: 'right',
    order: 24,
    id: 23,
    dataField: 'estoquePendente',
    text: 'Dias Pend',
    hidden: true,
  },
  {
    ordination: { ordinationName: 'ESTOQUE_PROJECAO', ordinationValue: '' },
    align: 'right',
    order: 25,
    id: 24,
    dataField: 'estoqueProjecao',
    text: 'Dias Proj',
    hidden: true,
  },
  {
    ordination: { ordinationName: 'ESTOQUE_FUTURO', ordinationValue: '' },
    align: 'right',
    order: 26,
    id: 25,
    dataField: 'estoqueFuturo',
    text: 'Dias Futuro',
    hidden: true,
  },

  //todo: ver todos abaixo
  {
    ordination: { ordinationName: 'PERCENTUAL_DESCONTO', ordinationValue: '' },
    align: 'center',
    order: 29,
    id: 28,
    dataField: 'percentualDesconto',
    text: '% Desc',
    hidden: true,
  },
  {
    ordination: { ordinationName: 'ESTOQUE_ATUAL', ordinationValue: '' },
    align: 'right',
    order: 34,
    id: 33,
    dataField: 'estoqueAtual',
    text: 'Dias Estoque Atual',
    hidden: true,
  },
  {
    ordination: { ordinationName: '', ordinationValue: '' },
    align: 'center',
    order: 35,
    id: 34,
    dataField: 'demandas',
    text: 'Demandas',
    hidden: true,
  },
  {
    ordination: { ordinationName: '', ordinationValue: '' },
    align: 'center',
    order: 36,
    id: 35,
    dataField: 'alterado',
    text: 'Alterado',
    hidden: false,
    fixed: true,
  },
  {
    ordination: { ordinationName: '', ordinationValue: '' },
    align: 'right',
    order: 37,
    id: 36,
    dataField: 'bloquadoCompra',
    text: 'BloqCompra',
    hidden: false,
    fixed: true,
  },
  { align: 'center', order: 38, id: 37, dataField: 'filial', text: 'FILIAL', hidden: false, fixed: true },
  {
    ordination: { ordinationName: 'PRECO_REPOSICAO', ordinationValue: '' },
    align: 'right',
    order: 39,
    id: 36,
    dataField: 'precoReposicao',
    text: 'Preço Reposição',
    hidden: true,
    typeField: 'monetary',
    numberFormat: 'currency',
  },
  { align: "center", order: 31, id: 30, dataField: "observacao", text: "Observação", hidden: false, fixed: true },
];
