import { useEffect, useState } from "react";
import { UseAuth } from "hook/auth-hook";
import { RestUseCase } from "service/api/rest/rest-usecase";
import { ReturnResponse } from "../../@types/interfaces/request-interface";
import FileLoadItemPage from "pages/layout/file-load-item-page";
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page";
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter";
import { getEnv } from "helper/window-helper";

export const FileLoadItemFactory: React.FC = () => {
    const ENDPOINT_UPLOAD_ORIGINAL_ITEMS =
        getEnv("REACT_APP_END_POINT_ORIGINAL_ITEMS") + `/carregar`;
    const ENDPOINT_UPLOAD_CORRELATE_ITEMS =
        getEnv("REACT_APP_END_POINT_CORRELATE_ITEMS") + `/carregar`;
    const ENDPOINT_UPLOAD_PROMOTIONAL_ITEMS =
        getEnv("REACT_APP_END_POINT_ITEM_PROMOTIONAL_LOAD") + `/carregar`;
    const ENDPOINT_UPLOAD_PURCHASES_PROVIDER =
        getEnv("REACT_APP_END_POINT_BUY_SUPPLIER") + `/carregar`;
    const ENDPOINT_UPLOAD_FILIAL_PROVIDER =
        getEnv("REACT_APP_END_POINT_FILIAL_PROVIDER") + `/carregar`;

    const ENDPOINT_DOWNLOAD_ORIGINAL_ITEMS =
        getEnv("REACT_APP_END_POINT_ORIGINAL_ITEMS") + `/layout`;
    const ENDPOINT_DOWNLOAD_CORRELATE_ITEMS =
        getEnv("REACT_APP_END_POINT_CORRELATE_ITEMS") + `/layout`;
    const ENDPOINT_DOWNLOAD_FILIAL_PROVIDER =
        getEnv("REACT_APP_END_POINT_FILIAL_PROVIDER") + `/layout`;
    const ENDPOINT_DOWNLOAD_BUY_SUPPLIER =
        getEnv("REACT_APP_END_POINT_BUY_SUPPLIER") + `/layout`;

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    const [loading, updateLoading] = useState<boolean>(false);
    const [requestMessage, updateRequestMessage] = useState<{
        message: string;
        type: "success" | "danger" | "";
    }>({
        message: "",
        type: "",
    });
    async function handleRequest(
        idProvider: number,
        file: File,
        endPoint: string,
        typeFile?: string
    ): Promise<any> {
        updateLoading(true);
        updateRequestMessage({
            message: "",
            type: "",
        });
        let query = `?idFornecedor=${idProvider}`;

        if (typeFile) {
            query += `&tipo=${typeFile}`;
        }

        const formData = new FormData();
        formData.append("file", file);

        await new RestUseCase(endPoint + `${query}`)
            .Post({
                data: formData,
                headers: headers,
            })
            .then((response) => {
                if (
                    response.statusCode === undefined ||
                    response.statusCode < 200 ||
                    response.statusCode >= 300
                ) {
                    updateLoading(false);
                    updateRequestMessage({
                        message: "Erro ao Importar Arquivo",
                        type: "danger",
                    })
                } else {
                    updateLoading(false);
                    updateRequestMessage({
                        message: "Arquivo Importado com sucesso",
                        type: "success",
                    })
                }
            })
            .catch(() => {
                updateLoading(false);
                updateRequestMessage({
                    message: "Erro ao Importar Arquivo",
                    type: "danger",
                });
            })
            .finally(() => {
                updateLoading(false);
            });
    }

    async function handleRequestDownload(
        returnResponse: ReturnResponse
    ): Promise<any> {
        let reponse: ReturnResponse = {
            statusCode: 500,
            headers: {},
            body: {},
        };

        if (returnResponse.body === undefined || returnResponse.body === 500) {
            reponse.statusCode = 500;
            reponse.headers = {};
            reponse.body = {};

            return reponse;
        }

        reponse.body = returnResponse.body;
        reponse.headers = returnResponse.headers;
        reponse.statusCode = returnResponse.statusCode;

        return reponse;
    }

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Fornecedor"
        )[0].navItem.filter(
            (item) => item.title === "Carregar Arquivos Fornecedor"
        )[0].role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
    }, [loggedUserData]);

    return (
        <>
            {showScreen ? (
                <FileLoadItemPage
                    clearRequestMessage={() =>
                        updateRequestMessage({
                            message: "",
                            type: "",
                        })
                    }
                    loading={loading}
                    requestMessage={requestMessage}
                    uploadFileItemCorrelate={async function (params?: {
                        idProvider: number;
                        file: File;
                    }): Promise<any> {
                        handleRequest(
                            params?.idProvider!,
                            params?.file!,
                            ENDPOINT_UPLOAD_CORRELATE_ITEMS
                        );
                    }}
                    uploadFileItemOriginal={async function (params?: {
                        idProvider: number;
                        file: File;
                        typeFile: string;
                    }): Promise<any> {
                        handleRequest(
                            params?.idProvider!,
                            params?.file!,
                            ENDPOINT_UPLOAD_ORIGINAL_ITEMS,
                            params?.typeFile!
                        );
                    }}
                    uploadFileItemPromotional={async function (params?: {
                        idProvider: number;
                        file: File;
                    }): Promise<any> {
                        handleRequest(
                            params?.idProvider!,
                            params?.file!,
                            ENDPOINT_UPLOAD_PROMOTIONAL_ITEMS
                        );
                    }}
                    uploadFilePurchasesProvider={async function (params?: {
                        idProvider: number;
                        file: File;
                    }): Promise<any> {
                        handleRequest(
                            params?.idProvider!,
                            params?.file!,
                            ENDPOINT_UPLOAD_PURCHASES_PROVIDER
                        );
                    }}
                    uploadFileFilialProvider={async function (params?: {
                        idProvider: number;
                        file: File;
                    }): Promise<any> {
                        handleRequest(
                            params?.idProvider!,
                            params?.file!,
                            ENDPOINT_UPLOAD_FILIAL_PROVIDER
                        );
                    }}
                    download={async function (params?: {
                        typeRequest: string;
                        typeFile?: string;
                    }): Promise<any> {
                        let url = "";

                        if (params?.typeRequest === "ITEM_ORIGINAL") {
                            url =
                                ENDPOINT_DOWNLOAD_ORIGINAL_ITEMS +
                                `?tipo=${params.typeFile}`;
                        }
                        if (params?.typeRequest === "ITEM_CORRELATO") {
                            url = ENDPOINT_DOWNLOAD_CORRELATE_ITEMS;
                        }
                        if (params?.typeRequest === "FILIAL_FORNECEDOR") {
                            url = ENDPOINT_DOWNLOAD_FILIAL_PROVIDER;
                        }

                        if (params?.typeRequest === "COMPRA_FORNECEDOR") {
                            url = ENDPOINT_DOWNLOAD_BUY_SUPPLIER;
                        }

                        const response = await new RestUseCase(url).Get({
                            responseType: "blob",
                        });

                        return handleRequestDownload(response);
                    }}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
