import { RestUseCase } from "service/api/rest/rest-usecase"
import { EntityField } from "../../../@types/entity/field/crud/field-crud-entity"
import { CompaniesSelect } from "../../../@types/entity/interface/select-option-interface"
import { TableComponents } from "../../../@types/entity/interface/table-interface"
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter"
import { ParseDateToEn } from "helper/format-date-helper"
import { UseAlert } from "hook/alert-hook"
import { UseAuth } from "hook/auth-hook"
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page"
import UserPageV2 from "pages/layout/safety/user-page-v2"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { AiFillLock } from "react-icons/ai";
import { getEnv } from "helper/window-helper";

export const UserFactoryV2: React.FC = () => {

    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_USER")!
    const ENDPOINT_LOG_USER = getEnv("REACT_APP_END_POINT_USER_LOG")!
    const ENDPOINT_COMPANIES = getEnv("REACT_APP_END_POINT_COMPANIES")!
    const ENDPOINT_RESET_PASSWORD = getEnv("REACT_APP_END_POINT_USER_RESET_PASSWORD_REQUEST")!

    /** USUARIO LOGADO */
    const { loggedUserData } = UseAuth()

    /** ENTIDADE PARA A TABELA E MODAL */
    const entityFields: EntityField[] = [
        { name: "id", labelField: "", labelTable: "ID", typeField: "hidden", valueField: "", sizeField: 0, required: false, align: "left", tableField: { column: "id", columnVisible: true }, sort: true, filter: true },
        { name: "idEmpresa", labelField: "Empresa:", labelTable: "Empresa", valueField: null, typeField: "select", sizeField: 6, required: false, align: "left", tableField: { column: "Texto", columnVisible: false }, labelFieldDisable: true, filter: true, sort: true },
        { name: "cpf", labelField: "CPF:", labelTable: "CPF", typeField: "text", valueField: "", sizeField: 3, required: false, align: "center", tableField: { column: "Texto", columnVisible: true }, labelFieldDisable: false, filter: true, sort: true },
        { name: "login", labelField: "Login:", labelTable: "Login", typeField: "text", valueField: "", sizeField: 3, required: false, align: "left", tableField: { column: "Texto", columnVisible: true }, labelFieldDisable: true, hideFieldOnCreate: true, filter: true, sort: true },
        { name: "nome", labelField: "Nome:", labelTable: "Nome", typeField: "text", valueField: "", sizeField: 6, required: false, align: "left", tableField: { column: "Texto", columnVisible: true }, labelFieldDisable: false, sort: true, filter: true },
        { name: "email", labelField: "Email:", labelTable: "Email", typeField: "text", valueField: "", sizeField: 6, required: false, align: "center", tableField: { column: "Texto", columnVisible: true }, labelFieldDisable: false, filter: true, sort: true },
        { name: "status", labelField: "Status:", labelTable: "Status", typeField: "text", valueField: "", sizeField: 6, required: false, align: "center", tableField: { column: "Texto", columnVisible: false }, labelFieldDisable: false, filter: false, sort: false, hideFieldOnCreate: true, hideFieldOnEdit: true },
        { name: "bloqueado", labelField: "Bloqueado:", labelTable: "Bloqueado", typeField: "icon", valueField: false, sizeField: 6, required: false, align: "center", tableField: { column: "Texto", columnVisible: true }, labelFieldDisable: false, filter: false, sort: false, hideFieldOnCreate: true, hideFieldOnEdit: true }
    ]
    const [entity, updateEntity] = useState(entityFields)

    /** VERIFICA FLUXO DE CRIAÇÃO DE USUARIO **/
    const [isCreatingUserFlow, updateCreatingUserFlow] = useState<boolean>()

    /** SELECT */
    const [companiesSelect, updateCompaniesSelect] = useState<CompaniesSelect>()

    /** TABELA */
    const [tableComponents, updateTableComponents] = useState<TableComponents>({ tableBody: [], tableHeader: [] })

    /** PERFIL */
    const [profile, updateProfile] = useState<string[]>([])

    /** USUARIO EDIÇÃO */
    const [idUser, updateIdUser] = useState<number>()

    /** ALERTA **/
    const { showAlert } = UseAlert()

    /** VERIFICA PERMISSAO DE ACESSO */
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Configurações")[0].navItem.filter(item => item.title === "Cadastrar Usuário")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value)) {
                    updateShowScreen(true)
                    listAllByCompany(loggedUserData.companyId)
                    listAllCompanies()
                }
            })
        }
    }, [loggedUserData])

    /** ATUALIZA LISTA DE EMPRESAS **/
    useMemo(() => {
        updateEntity(entityFields.map(value => {
            if (value.name === "idEmpresa") {
                value.listValueField = companiesSelect?.companies
                companiesSelect?.companies?.forEach(comp => {
                    if (comp.value === companiesSelect?.companyIdSelected) {
                        value.valueField = comp.value
                    }
                })
            }
            return value
        }))
    }, [companiesSelect?.companies])

    /** FUNÇÕES AUXILIARES **/
    function pegaIdUsuarioDoHeader(response: any) {
        let uri = response.headers["location"] === "null" ? "" : String(response.headers["location"])
        var n = uri.lastIndexOf("/");
        return uri.substring(n + 1);
    }

    /** CHAMADA BACKEND */
    const addProfile = async (role: string) => {
        await new RestUseCase(`${ENDPOINT_CRUD}/${idUser}/perfis?role=${role}`)
            .Put()
            .then((response) => {
                if (!isCreatingUserFlow) {
                    if (response.statusCode === 204) {
                        showAlert({ show: true, content: "Perfil alterado com sucesso", color: "success", time: 5000 })
                    } else {
                        showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                    }
                }
            })
    }
    const removeProfile = async (role: string) => {
        await new RestUseCase(`${ENDPOINT_CRUD}/${idUser}/perfis?role=${role}`)
            .Delete()
            .then((response) => {
                if (!isCreatingUserFlow) {
                    if (response.statusCode === 204) {
                        showAlert({ show: true, content: "Perfil alterado com sucesso", color: "success", time: 5000 })
                    } else {
                        showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                    }
                }
            })
    }
    const changeProfile = async (params: string[]) => {
        const listProfile = ["ADMIN", "GUEST", "COMPRAS_GESTOR", "COMPRAS_OPERACIONAL", "SCRAP_ADMINISTRADOR", "SCRAP_COMPRADOR", "SCRAP_AUDITOR", "SCRAP_CONSULTOR", "SCRAP_FINANCEIRO", "SCRAP_VENDAS"]
        listProfile.forEach((value: string) => {
            if (params.includes(value))
                addProfile(value)
            else
                removeProfile(value)
        })
    }

    async function listAllByCompany(companyID: number) {
        if (loggedUserData !== undefined) {
            await listAllCompanies()
            await new RestUseCase(`${ENDPOINT_CRUD}?empresa=${companyID}`)
                .Get()
                .then(response => {
                    let tableComponents: TableComponents = {
                        tableHeader: entityFields.map((value) => {
                            return {
                                name: value.name,
                                labelTable: value.labelTable,
                                align: value.align,
                                columnVisible: value.tableField.columnVisible,
                                sort: value.sort === true ? true : false,
                                filter: value.filter === true ? true : false
                            };
                        }),
                        tableBody: response.body.map((value: { cpf: string, email: string, id: string, idEmpresa: string, login: string, nome: string, status: string, bloqueado: ReactNode }) => {
                            if (value.status === "B") {
                                value.bloqueado = <AiFillLock />
                            } else {
                                value.bloqueado = null
                            }
                            return value
                        })
                    }
                    updateTableComponents(tableComponents)
                })
        }
    }

    async function listAllCompanies(): Promise<any> {
        await new RestUseCase(ENDPOINT_COMPANIES).Get()
            .then((response) => {
                if (response.statusCode !== 500 && response.statusCode !== undefined) {
                    let companiesS: CompaniesSelect = {
                        companies: response.body.map((value: any) => { return { value: value.id, label: value.nome, } }),
                        companyIdSelected: companiesSelect ? companiesSelect.companyIdSelected : loggedUserData!.companyId
                    }
                    updateCompaniesSelect(companiesS)
                }
            })
    }

    return (
        <>
            {showScreen ?
                <UserPageV2
                    titlePage="Usuário"
                    entityFields={entity}
                    tableComponents={tableComponents!}
                    companies={companiesSelect?.companies!}
                    userCompanyID={companiesSelect?.companyIdSelected}
                    profile={profile}
                    modelMethods={{
                        create: async function (model?: any, profile?: string[]) {
                            if (profile) {
                                updateProfile(profile)
                                updateCreatingUserFlow(true)
                                await new RestUseCase(ENDPOINT_CRUD + `/create`).Post({ data: model }).then((response) => {
                                    var idUsuario = Number(pegaIdUsuarioDoHeader(response))
                                    updateIdUser(idUsuario)
                                    if (response.statusCode === 201) {
                                        if (profile.length > 0) {
                                            changeProfile(profile)
                                            showAlert({ show: true, content: "Usuario salvo com sucesso", color: "success", time: 5000 })
                                        } else {
                                            showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                                        }
                                        showAlert({ show: true, content: "Usuario criado com sucesso", color: "success", time: 5000 })
                                    } else {
                                        showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                                    }
                                })
                            }
                        },
                        edit: async function (model?: any, profile?: string[]) {
                            if (profile) {
                                updateProfile(profile)
                                await new RestUseCase(ENDPOINT_CRUD + `/` + idUser).Patch({ data: model }).then((response) => {
                                    if (response.statusCode === 204) {
                                        changeProfile(profile)
                                        showAlert({ show: true, content: "Usuario salvo com sucesso", color: "success", time: 5000 })
                                    } else {
                                        showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                                    }
                                })
                            }
                        },
                        block: async function (id: number) {
                            await new RestUseCase(`${ENDPOINT_CRUD}/${id}`).Delete().then((response) => {
                                if (response.statusCode === 204) {
                                    showAlert({ show: true, content: "Usuario bloqueado com sucesso", color: "success", time: 5000 })
                                } else {
                                    showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                                }
                            })
                        },
                        unblock: async function (id: number) {
                            await new RestUseCase(`${ENDPOINT_CRUD}/${id}/reactive`).Patch().then((response) => {
                                if (response.statusCode === 204) {
                                    showAlert({ show: true, content: "Usuario desbloqueado com sucesso", color: "success", time: 5000 })
                                } else {
                                    showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                                }
                            })
                        },
                        getById: async function (id: number) {
                            updateIdUser(id)
                            updateProfile([])
                            await listAllCompanies()
                            await new RestUseCase(`${ENDPOINT_CRUD}/${id}`)
                                .GetById()
                                .then(response => {
                                    updateEntity(entityFields.map((value) => {

                                        if (value.name === "idEmpresa") {
                                            value.listValueField = companiesSelect?.companies
                                        }
                                        value.valueField = response.body[value.name]
                                        return value
                                    }))
                                })
                            await new RestUseCase(`${ENDPOINT_CRUD}/${id}/perfis`)
                                .Get()
                                .then(response => {
                                    if (response.statusCode === 200) {
                                        updateProfile(response.body.map((value: any) => {
                                            return value.role
                                        }))
                                    }
                                })
                        },
                        listAllByCompany: listAllByCompany,
                        handleListLogUser: async function (dateInit, dateEnd) {
                            return await new RestUseCase(ENDPOINT_LOG_USER + `/${idUser}?inicio=${ParseDateToEn(dateInit)}&fim=${ParseDateToEn(dateEnd)}`)
                                .Get().finally()
                        },
                        resetPassword: async function (params: { userEmail: string, userLogin?: string }) {
                            let query = `?email=${params.userEmail}`

                            if (params.userLogin !== null && params.userLogin !== undefined && params.userLogin !== "") {
                                query += `&login=${params.userLogin}`
                            }
                            await new RestUseCase(ENDPOINT_RESET_PASSWORD + query).Post().then((response) => {
                                if (response.statusCode === 202) {
                                    showAlert({ show: true, content: "Senha reenviada com sucesso", color: "success", time: 5000 })
                                } else {
                                    if (response.statusCode === 404) {
                                        showAlert({ show: true, content: "E-mail não encontrado", color: "danger", time: 5000 })
                                    } else {
                                        showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
                                    }
                                }
                            })
                        }
                    }} />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )
}