import { SCDesctiption } from '../../pages/style/typography/titlePage';
import { useLocation } from 'react-router-dom';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row } from 'react-bootstrap';
import { IoChatbubbleEllipsesOutline, IoInformationCircleOutline } from 'react-icons/io5';
import { RestUseCase } from '../../service/api/rest/rest-usecase';
import { getEnv } from '../../helper/window-helper';


type Props = {
  titlePage: string,
  children: ReactNode
};

export function TitleGroupComponent({ titlePage, children }: Props) {

  const ENDPOINT_CRUD = getEnv('REACT_APP_END_POINT_HELP')!;
  const location = useLocation();

  const [showModalInfo, updateShowModalInfo] = useState(false);
  const closeModalInfo = () => updateShowModalInfo(false);
  const showInfo = () => updateShowModalInfo(true);
  const [infoPageContent, updateInfoPageContent] = useState<string>();

  function ModalInfo() {
    return (
      <Modal show={showModalInfo} onHide={closeModalInfo} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title className='ms-3 lead' style={{ fontSize: '20px' }}>
            {`Tutorial da tela - ${titlePage}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: infoPageContent ?? '' }} />
        </Modal.Body>
      </Modal>
    );
  }

  const callHelps = useCallback(async () => {
    const response = await new RestUseCase(
      `${ENDPOINT_CRUD}`,
    )
      .Get()
      .finally();

    const tutorialResponse = response.body.filter((item: any) => item.tela === location.pathname && item.label === 'IS_COMPRAS');

    let info = '';
    if (tutorialResponse.length > 0) {
      if (tutorialResponse.length > 1) {
        info = tutorialResponse.map((item: any) => item.conteudo).join('\n');
      } else {
        info = tutorialResponse[0].conteudo;
      }
    }

    updateInfoPageContent(info);
  }, []);

  useEffect(() => {
    callHelps();
  }, []);

  const [showModalChat, updateShowModalChat] = useState(false);
  const closeModalChat = () => updateShowModalChat(false);
  const showChat = () => updateShowModalChat(true);

  function ModalChat() {
    return (
      <Modal show={showModalChat} onHide={closeModalChat} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title className='ms-3 lead' style={{ fontSize: '20px' }}>
            Chat
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Estamos desenvolvendo esta funcionalidade, em breve ela estará pronta
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <SCDesctiption>{titlePage}</SCDesctiption>
        </Col>
        <Col className='d-flex align-items-center justify-content-end'>
          <ButtonGroup className='me-2' size='sm'>
            <Button className='d-flex align-items-center' variant='outline-primary' onClick={showInfo}>
              <IoInformationCircleOutline className='me-1' />
              Tutorial
            </Button>
            <Button className='d-flex align-items-center' variant='outline-primary' onClick={showChat}>
              <IoChatbubbleEllipsesOutline className='me-1' />
              Chat
            </Button>
          </ButtonGroup>
          {children}
        </Col>
      </Row>
      <ModalChat />
      <ModalInfo />
    </>
  );

}