import { FiltersDefault } from "../../../../../@types/interfaces/filter-interface";
import ContainerFilter from "components/container/container-filter-component";
import { getLocalStorage } from "data/cache/localstorage-cache";
import { Modal, Row, Col } from "react-bootstrap";


interface PropsModalPlanning {
  showModalPlanning: boolean
  closePlanning: (() => void) | undefined
  keyPlanningLocalStorage: string
  namePlanningLocalStorage: string
  applyAction: (model: any) => Promise<void>
  optionsFiltersDefault: FiltersDefault[]
  listAllPlanning: (params?: any) => Promise<any>
  createPlanning: (params?: any) => Promise<any>
  editPlanning: (params?: any) => Promise<any>
  removePlanning: (params?: any) => Promise<any>
}

export const ModalPlanning: React.FC<PropsModalPlanning> = ({
  showModalPlanning,
  closePlanning,
  keyPlanningLocalStorage,
  namePlanningLocalStorage,
  applyAction,
  optionsFiltersDefault,
  listAllPlanning,
  createPlanning,
  editPlanning,
  removePlanning
  }) => {
  return (
    <Modal show={showModalPlanning} onHide={closePlanning} size={'xl'} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title className="ms-3 lead" style={{ fontSize: '30px' }}>
          Planejamento
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ContainerFilter
              keyLocalStorage={keyPlanningLocalStorage}
              filterLocalStorage={getLocalStorage(namePlanningLocalStorage)}
              apply={applyAction}
              filtersDefault={optionsFiltersDefault}
              listAllPlanning={listAllPlanning}
              listByIdPlanning={function (params?: any): Promise<any> {
                throw new Error('listByIdPlanning ==> não foi implementada');
              }}
              createPlanning={createPlanning}
              editPlanning={editPlanning}
              removePlanning={removePlanning}
              isOnlineConsultant={false} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}