import { TableDescriptions } from "../../../@types/interfaces/table-interface";

export const ColumnsActiveCampaignAdapter: TableDescriptions[] = [
  { align: "center", order: 1, id: 1, dataField: "fitStrCodFabricante", text: "Sufixo", hidden: false, fixed: true },
  { align: "left", order: 2, id: 2, dataField: "fitStrNomeItemCampanha", text: "Descrição", hidden: false, fixed: true },
  { align: "left", order: 3, id: 3, dataField: "ALERTA", text: "ALERTA", hidden: false, fixed: true },
  { align: "center", order: 4, id: 4, dataField: "aacStrDeltaLegenda", text: "DeltaLegenda", hidden: false, fixed: true },
  { align: "center", order: 5, id: 5, dataField: "aacFltDelta", text: "Delta", hidden: false, fixed: true, numberFormat: "percent" },
  { align: "center", order: 6, id: 6, dataField: "qtdPendente", text: "Qtd *", hidden: false, fixed: true },
  { align: "right", order: 7, id: 7, dataField: "pitIntQme", text: "Qme =", hidden: false },
  { align: "right", order: 8, id: 8, dataField: "intTotal", text: "Total", hidden: false },
  { align: "center", order: 9, id: 9, dataField: "caaIntQuantidade", text: "Qtd Sugerida", hidden: false, fixed: true },
  { align: "center", order: 10, id: 10, dataField: "qtdIntencoes", text: "Qtd Enviada", hidden: false, fixed: true },
  { align: "center", order: 11, id: 11, dataField: "ppiFltDesconto", text: "Desconto", hidden: false, numberFormat: "percent" },
  { align: "center", order: 12, id: 12, dataField: "precoSemStSemDesconto", text: "Preco Sem ST", hidden: false, numberFormat: "currency" },
  { align: "center", order: 13, id: 13, dataField: "precoSemStComDesconto", text: "Preco Sem ST com Desconto", hidden: false, numberFormat: "currency" },
  { align: "center", order: 14, id: 14, dataField: "precoComStSemDesconto", text: "Preco com ST", hidden: false, numberFormat: "currency" },
  { align: "center", order: 15, id: 15, dataField: "precoComStComDesconto", text: "Preco Com ST com Desconto", hidden: false, numberFormat: "currency" },
  { align: "right", order: 16, id: 16, dataField: "caaFltMedia", text: "Média", hidden: false },
  { align: "right", order: 17, id: 17, dataField: "caaFltPMes", text: "PMês", hidden: false },
  { align: "right", order: 18, id: 18, dataField: "caaIntQtdEstoque", text: "Est", hidden: false },
  { align: "right", order: 19, id: 19, dataField: "caaIntQtdPendente", text: "Pen", hidden: false },
  { align: "right", order: 20, id: 20, dataField: "arqIntQtdBloqueada", text: "Bloq", hidden: false },
  { align: "right", order: 21, id: 21, dataField: "caaIntQtdPecaExcesso", text: "Exc", hidden: false },
  { align: "right", order: 22, id: 22, dataField: "caaIntPontuacao", text: "Ppt", hidden: false },
  { align: "left", order: 23, id: 23, dataField: "caaChaCurva", text: "Cvf", hidden: false },
  { align: "left", order: 24, id: 24, dataField: "caaChaNovaCurva", text: "NCvf", hidden: false },
  { align: "center", order: 25, id: 25, dataField: "caaChaStatus", text: "Status", hidden: false, fixed: false },
  { align: "center", order: 26, id: 26, dataField: "fltValorPedido", text: "Valor Pedido", hidden: false, fixed: false, numberFormat: "currency" },
  { align: "center", order: 27, id: 27, dataField: "fitChaLinha", text: "Base", hidden: true },
  { align: "center", order: 28, id: 28, dataField: "filIntIdFilial", text: "Fil", hidden: true, fixed: false },
]