import { getLocalStorage, updateLocalStorege } from "data/cache/localstorage-cache";
import { TableDescriptions } from "../@types/interfaces/table-interface";
import { getParameterUrlByName } from "./request-helper";

export function checkColumns(localStorageColumnsName: string, ColunmsTableAdapter: TableDescriptions[]) {
  let localStorageColumns: TableDescriptions[] = getLocalStorage(localStorageColumnsName);
  let columnsAdapter: TableDescriptions[] = ColunmsTableAdapter
  let newColumns: TableDescriptions[] = []

  if (localStorageColumns !== null) {
    newColumns = columnsAdapter.map(columnAdapter => {
      if (localStorageColumns.some(columnLocalstorage => {
        return (columnLocalstorage.dataField === columnAdapter.dataField ||
          columnLocalstorage.text === columnAdapter.text) && columnLocalstorage.hidden === false;
      })) {
        columnAdapter.hidden = false
        return columnAdapter
      } else {
        columnAdapter.hidden = true
        return columnAdapter
      }
    });

    updateLocalStorege(
      localStorageColumnsName,
      newColumns.sort((a, b) => (a.order > b.order ? 1 : -1))
    );
  }
}

export async function orderValueColums(nameColumn: TableDescriptions, localStorageColumnsName: string, columns: TableDescriptions[],
  totalPageSize: number, handleTableFucntion: (() => void), nextPagination: string) {
  /** busca no localstorage */
  let localStorageColumsOrder: any[] = getLocalStorage(localStorageColumnsName) ?? [];

  columns.forEach((value) => {
    if (value.id === nameColumn.id) {
      if (localStorageColumsOrder.length === 0) {
        value.ordination!.ordinationValue = 'asc';
        localStorageColumsOrder[0] = value.ordination;
        updateLocalStorege(localStorageColumnsName, localStorageColumsOrder);
      } else {
        if (value.ordination?.ordinationValue === 'asc') {
          value.ordination!.ordinationValue = 'desc';
          localStorageColumsOrder[0] = value.ordination;
          updateLocalStorege(localStorageColumnsName, localStorageColumsOrder);
        } else if (value.ordination?.ordinationValue === 'desc') {
          value.ordination!.ordinationValue = '';
          localStorageColumsOrder[0] = value.ordination;
          updateLocalStorege(localStorageColumnsName, localStorageColumsOrder);
        } else if (value.ordination?.ordinationValue === '') {
          value.ordination!.ordinationValue = 'asc';
          localStorageColumsOrder[0] = value.ordination;
          updateLocalStorege(localStorageColumnsName, localStorageColumsOrder);
        }
      }
    } else {
      if (value.ordination !== undefined && value.ordination !== null) {
        value.ordination!.ordinationValue = '';
      }
    }
  });
  /** chama o endpoint */
  totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
  await handleTableFucntion()
}