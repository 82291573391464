/* eslint-disable no-restricted-globals */
import { AuthUseCase } from "service/api/rest/auth-usecase";
import ResetPasswordPage from "pages/layout/auth/reset-password-page";
import React, { useEffect, useState } from "react";
import { getEnv } from "helper/window-helper";


export const ResetPasswordFactory: React.FC = () => {

    let token = window.location.search.replace("?token=", "")

    /** ENDPOINT */
    const ENDPOINT_RESET_PASSWORD = getEnv("REACT_APP_END_POINT_USER_RESET_PASSWORD")!

    const [withError, updateWithError] = useState<boolean>(false)
    const [withSuccess, updateWithSuccess] = useState<boolean>(false)
    const [isShowPassword, updateIsShowPassword] = useState<boolean>(false)
    const [passwordOK, updatePasswordOK] = useState<boolean>(false)
    const [isLoading, updateIsLoading] = useState<boolean>(false)
    const [tokenPassword, updateTokenPassword] = useState<any>(token)

    function togglePassword() {
        updateIsShowPassword((isShown) => !isShown);
    };

    function isPasswordOk(params: { password: string, verificationPassword: string }) {
        var isOK = ((params.password != "") && (params.password != "") && (params.password === params.verificationPassword))
        updatePasswordOK(isOK)
    }

    function handleGoToLogin() {
        location.replace("/")
    }

    const resetPassword = async (params: { password: string, verificationPassword: string }) => {
        updateIsLoading(true)

        var resetPassword = {
            newPassword: params.password,
            token: tokenPassword
        };

        await new AuthUseCase(`${ENDPOINT_RESET_PASSWORD}`)
            .Post({ data: resetPassword })
            .then(response => {
                if (response.statusCode === 200) {
                    updateWithSuccess(true)
                    updateWithError(false)
                } else {
                    updateWithSuccess(false)
                    updateWithError(true)
                    updateIsLoading(false)
                }
            })
            .catch((error) => {
                updateWithError(true)
                updateIsLoading(false)
                updateIsLoading(false)
            })
        updateIsLoading(false)
    }

    useEffect(() => {
        updateWithSuccess(false)
        updateWithError(false)
    }, [passwordOK, updateIsShowPassword])

    return (
        <>
            <ResetPasswordPage
                togglePassword={togglePassword}
                handleGoToLogin={handleGoToLogin}
                isShowPassword={isShowPassword}
                resetPassword={resetPassword}
                isPasswordOk={isPasswordOk}
                passwordOK={passwordOK}
                error={withError}
                success={withSuccess}
                isLoading={isLoading} />
        </>
    )
}