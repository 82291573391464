import { useEffect, useState } from 'react';
import CrudPage from 'pages/layout/crud-page';
import { UseAuth } from 'hook/auth-hook';
import { RestUseCase } from 'service/api/rest/rest-usecase';
import { ParseDateToEn } from 'helper/format-date-helper';
import { EntityField } from '../../../@types/entity/field/crud/field-crud-entity';
import ErrorNotAuthorizedPage from 'pages/layout/error/error-not-authorized-page';
import { HeaderNavegationList } from 'helper/adapter/headerNavigation/header-navegation-adapter';
import { getEnv } from 'helper/window-helper';
import { SelectOptionInterface } from '../../../@types/entity/interface/select-option-interface';
import { UseAlert } from 'hook/alert-hook';

export const ItemNoteFactory: React.FC = () => {
  /** ALERTA **/
  const { showAlert } = UseAlert()

  /** ENDPOINT */
  const ENDPOINT_CRUD = getEnv('REACT_APP_END_POINT_ITEM_NOTE')!;

  /** TITULO DA PAGINA */
  const titlePage = 'Cadastrar Observação em Item';

  /** CAMPOS PARA MODAL E TABELA */
  const entityFields: EntityField[] = [
    {
      name: 'id',
      labelField: '',
      labelTable: 'ID',
      typeField: 'hidden',
      valueField: null,
      sizeField: 0,
      required: false,
      align: 'center',
      tableField: { column: 'ID', columnVisible: true },
    },
    {
      name: 'dataInicio',
      labelField: 'Data Inicial:',
      labelTable: 'Data Inicial',
      typeField: 'date',
      valueField: '',
      sizeField: 4,
      required: false,
      align: 'left',
      tableField: { column: 'Data Inicial', columnVisible: true },
    },
    {
      name: 'dataFim',
      labelField: 'Data Final:',
      labelTable: 'Data Final',
      typeField: 'date',
      valueField: '',
      sizeField: 4,
      required: false,
      align: 'left',
      tableField: { column: 'Data Final', columnVisible: true },
    },
    {
      name: 'tipo',
      labelField: 'Tipo:',
      labelTable: 'Tipo',
      valueField: null,
      typeField: 'radio',
      listValueField: [
        { label: 'Informação', value: '1' },
        { label: 'Alerta', value: '2' },
        { label: 'Crítico', value: '3' },
      ],
      sizeField: 4,
      required: true,
      align: 'right',
      tableField: { column: 'Tipo', columnVisible: true },
    },
    {
      name: 'mensagem',
      labelField: 'Mensagem:',
      labelTable: 'Mensagem',
      typeField: 'text-area',
      valueField: '',
      sizeField: 12,
      required: false,
      align: 'left',
      tableField: { column: 'Mensagem', columnVisible: true },
    },
    {
      name: 'codigosItens',
      labelField: 'Itens:',
      labelTable: 'Itens',
      typeField: 'text-area',
      valueField: [],
      sizeField: 12,
      required: false,
      align: 'left',
      tableField: { column: 'Itens', columnVisible: false },
    },
  ];
  const [entity, updateEntity] = useState(entityFields);

  /** TABELA */
  const [tableHeader, updateTableHeader] = useState<any[]>([]);
  const [tableBody, updateTableBody] = useState([]);

  /** VERIFICA PERMISSAO DE ACESSO */
  const { loggedUserData } = UseAuth();
  const [showScreen, updateShowScreen] = useState<boolean>(false);
  const [filterByCompany, updateFilterByCompany] = useState<boolean>(false);
  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter((header) => header.navTitle === 'Configurações')[0].navItem.filter(
      (item) => item.title === 'Cadastrar Observação em Item'
    )[0].role;

    if (loggedUserData?.role) {
      loggedUserData.role.forEach((value) => {
        if (roleScreen.includes(value)) {
          updateShowScreen(true);
          if (value === "ADMIN") {
            updateFilterByCompany(true)
          }
          updateIdCompany(loggedUserData.companyId)
        }
      });
    }
  }, [loggedUserData]);

  const [idCompany, updateIdCompany] = useState<number>();
  const [modelCompany, updateModelCompany] = useState<SelectOptionInterface>();
  function handleSelectProvider(filial: { value: number; label: string }) {
    updateModelCompany(filial);
    updateIdCompany(filial.value);
    if (filial.value) {
      getAllV2(filial.value);
    }
  }

  async function getAllV2(company: number) {
    const response = await new RestUseCase(`${ENDPOINT_CRUD}/${company}`).Get().finally();

    updateTableHeader(
      entityFields.map((value) => {
        return {
          name: value.name,
          labelTable: value.labelTable,
          align: value.align,
          columnVisible: value.tableField.columnVisible,
        };
      })
    );
    updateTableBody(response.body);
  }

  return (
    <>
      {showScreen ? (
        <CrudPage
          handleSelectProvider={handleSelectProvider}
          filterByCompany={filterByCompany}
          modelCompany={modelCompany}
          entityFields={entity}
          updateEntity={updateEntity}
          titlePage={titlePage}
          getAll={async function () {
            const response = await new RestUseCase(`${ENDPOINT_CRUD}/${idCompany}`).Get().finally();

            updateTableHeader(
              entityFields.map((value) => {
                return { name: value.name, labelTable: value.labelTable, align: value.align, columnVisible: value.tableField.columnVisible };
              })
            );
            updateTableBody(
              response.body.map((value: any) => {
                value['tipo'] = value['tipo'].toString();
                return value;
              })
            );
          }}
          getById={async function (id: number) {
            const response = await new RestUseCase(`${ENDPOINT_CRUD}/${idCompany}/${id}`).GetById().finally();

            updateEntity(
              entityFields.map((value) => {
                if (value.typeField === 'date') value.valueField = ParseDateToEn(response.body[value.name]);
                else if (value.typeField === 'radio') value.valueField = response.body[value.name].toString();
                else value.valueField = response.body[value.name];

                return value;
              })
            );

            const responseCodigosItem = await new RestUseCase(`${ENDPOINT_CRUD}/${idCompany}/${id}/codigos`).GetById().finally();

            updateEntity(
              entityFields.map((value) => {
                if (value.name === 'codigosItens') {
                  value.valueField = responseCodigosItem.body[value.name];
                  if (value.valueField !== undefined && value.valueField !== '') {
                    value.valueField = String(value.valueField).replaceAll(',', ';');
                  }
                }
                return value;
              })
            );
          }}
          post={async function (model: any) {
            delete model['id'];
            if (model['dataInicio'] !== '') {
              model['dataInicio'] = new Date(model['dataInicio']).toISOString();
            }
            if (model['dataFim'] !== '') {
              model['dataFim'] = new Date(model['dataFim']).toISOString();
            }
            if (model['codigosItens'] !== undefined && model['codigosItens'] !== '') {
              model['codigosItens'] = model['codigosItens']
                .toString()
                .split(';')
                .map((value: string) => {
                  return value.toString().trim();
                });
            } else {
              model['codigosItens'] = [];
            }

            await new RestUseCase(`${ENDPOINT_CRUD}/${idCompany}`).Post({ data: model }).then((response) => {
              if (response.statusCode >= 200 && response.statusCode < 300) {
                showAlert({ show: true, content: "Observação salva com sucesso", color: "success", time: 5000 })
              } else {
                showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
              }
            });
          }}
          put={async function (id: number, model: any) {
            if (model['dataInicio'] !== '') {
              model['dataInicio'] = new Date(model['dataInicio']).toISOString();
            }
            if (model['dataFim'] !== '') {
              model['dataFim'] = new Date(model['dataFim']).toISOString();
            }

            if (model['codigosItens'] !== undefined && model['codigosItens'] !== '') {
              model['codigosItens'] = model['codigosItens']
                .toString()
                .split(';')
                .map((value: string) => {
                  return value.toString().trim();
                });
            } else {
              model['codigosItens'] = [];
            }

            const response = await new RestUseCase(`${ENDPOINT_CRUD}/${idCompany}/${id}`).Put({ data: model }).finally();

            updateEntity(
              entityFields.map((value) => {
                value.valueField = response.body[value.name];
                return value;
              })
            );
          }}
          del={async function (id: number) {
            await new RestUseCase(`${ENDPOINT_CRUD}/${idCompany}/${id}`).Delete().finally();
          }}
          tableHeader={tableHeader}
          tableBody={tableBody}
        />
      ) : (
        <ErrorNotAuthorizedPage />
      )}
    </>
  );
};
