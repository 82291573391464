/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { SetStateAction, useMemo, useState } from 'react';
import { Badge, Button, Card, Col, Container, Form, Modal, Pagination, Row, Table } from 'react-bootstrap';
import { CrudInterface } from '../../../@types/entity/interface/crud-interface';
import { UseAlert } from 'hook/alert-hook';
import { UseAlertModal } from 'hook/alert-modal-hook';
import { Footer } from 'components/footer/footer-component';
import { Headers } from 'components/header/headers-component';
import { Loading } from 'components/loading/loading-component';
import { OriginalItemsEntity, OriginalItemsInputs } from '../../../@types/entity/original-items-entity';
import { SelectOptionInterface } from '../../../@types/entity/interface/select-option-interface';
import { SelectProvider } from 'components/select/provider-select-component';
import { TableHeaderFixedStyled } from 'pages/style/table/table-header-fixed-styled';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { generateRandomId } from 'helper/randon-helper';
import {
  getParameterUrlByName,
  parseLinkHeader,
} from 'helper/request-helper';
import { TitleGroupComponent } from '../../../components/title/title-group-component';

interface LinkHeader {
  first: string;
  last: string;
  next: string;
  prev: string;
}

type Props = {
  modelMethods: CrudInterface<OriginalItemsEntity>;
  providerOption: SelectOptionInterface[];
  uploadFile: (params?: any) => Promise<any>;
};

const tableDescriptions: {
  fieldName: string;
  label: string;
  align: 'center' | 'left' | 'right';
}[] = [
    {
      fieldName: 'codigoFabricante',
      label: 'Código Fabricante',
      align: 'left',
    },
    { fieldName: 'nome', label: 'Nome', align: 'left' },
  ];
const titlePage = 'Cadastro de Itens Originais';

const OriginalItemsPage: React.FC<Props> = ({ modelMethods, providerOption, uploadFile }: Props) => {


  /**
   *
   * COMPONENTES DA TABELA
   *
   */
  const [listModel, updateListModel] = useState<OriginalItemsEntity[]>([]);
  const [idProvider, updateIdProvider] = useState<number>();

  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  /**
   *
   * FILTROS DA TABELA
   *
   */
  var filterTemporaryValue: SetStateAction<string>;
  const [filterValue, updatesFilterValue] = useState('');
  const [columnValue, updatesColumnValue] = useState('');
  const [filterName, updatesFilterName] = useState('');
  const [filterManufacturerCode, updatesFilterManufacturerCode] = useState('');
  const [sortType, updatesSortType] = useState('');

  useMemo(() => {
    if (idProvider !== undefined) {
      handleListModel({
        pagination: `&page=${0}&size=${50}`,
        page: 0,
        idProvider: idProvider,
      });
    }
  }, [filterName, filterManufacturerCode, sortType]);

  /**
   *
   * ALERT / ALERT MODAL
   *
   */
  const { showAlert } = UseAlert();
  const { messageAlertModal, showAlertModal } = UseAlertModal();
  const cleanAlertModal = () =>
    showAlertModal({ show: false, content: '', color: '' });

  function AlertModal() {
    if (messageAlertModal?.show) {
      return (
        <div
          className={
            'alert alert-' +
            messageAlertModal.color +
            ' fade show mx-5 mt-2 alert-dismissible'
          }
          role='alert'
        >
          {messageAlertModal.content}
          {!messageAlertModal.time && (
            <button
              type='button'
              className='ms-1 btn-close btn-sm'
              data-bs-dismiss='alert'
              aria-label='Close'
              onClick={() => cleanAlertModal()}
            ></button>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  }

  /**
   *
   * FORM HOOK
   *
   */
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm<OriginalItemsInputs>();

  function clearField(): void {
    // LIMPA OS CAMPOS
    reset({
      idFornecedor: 0,
      codigoFabricante: '',
      dataCadastro: '',
      faixaLucratividade: '',
      modeloPrioritario: '',
      nome: '',
      subsidiaria: '',
      precoAc: 0,
      precoAl: 0,
      precoAm: 0,
      precoAmzf: 0,
      precoAp: 0,
      precoApzf: 0,
      precoBa: 0,
      precoCe: 0,
      precoDf: 0,
      precoEs: 0,
      precoGo: 0,
      precoMa: 0,
      precoMg: 0,
      precoMgds: 0,
      precoMs: 0,
      precoMt: 0,
      precoPa: 0,
      precoPb: 0,
      precoPe: 0,
      precoPi: 0,
      precoPr: 0,
      precoRj: 0,
      precoRn: 0,
      precoRo: 0,
      precoRr: 0,
      precoRrzf: 0,
      precoRs: 0,
      precoSc: 0,
      precoSe: 0,
      precoSp: 0,
      precoTo: 0,
    });
  }

  /**
   *
   * ERROR
   *
   */
  const [listError, updateListError] = useState<string[]>([]);

  /**
   *
   * CRUD MODEL / MODELS
   *
   */

  async function handleListModel(params?: {
    pagination?: string,
    page: number,
    idProvider?: number,
    manufacturerCode?: string,
    name?: string,
    sort?: string
  }): Promise<void> {

    let pagePagination;

    updateIdProvider(params?.idProvider);

    var modelRequest = {
      pagination: params === undefined ? 0 : params?.pagination,
      idProvider: params === undefined ? idProvider : params?.idProvider,
      manufacturerCode: params === undefined ? filterManufacturerCode : params?.manufacturerCode,
      name: params === undefined ? filterName : params?.name,
      sort: params === undefined ? sortType : params?.sort,
    };

    updateLoading(true);

    if (params) {
      pagePagination = params.page;
    } else {
      pagePagination = 0;
    }

    modelRequest.manufacturerCode = filterManufacturerCode !== undefined ? filterManufacturerCode : '';
    modelRequest.name = filterName !== undefined ? filterName : '';
    if (sortType !== undefined && sortType !== '') {
      if (columnValue === 'codigoFabricante') {
        modelRequest.sort = `codigo_fabricante:${sortType}`;
      } else {
        modelRequest.sort = `${columnValue}:${sortType}`;
      }
    }

    let response;
    response = await modelMethods.getAll(modelRequest);

    /** links da paginacao */
    if (response.headers['link']) {
      let links: LinkHeader = parseLinkHeader(
        response.headers['link'],
      ) as any;

      updateFirstPagination(links.first);
      updateLastPagination(links.last);
      updateNextPagination(links.next);
      updatePrevPagination(links.prev);

      setValuePaginations(
        pagePagination,
        parseInt(getParameterUrlByName('page', links.first)!),
        parseInt(getParameterUrlByName('page', links.last)!),
      );
    }

    // BUSCA LISTAGEM DE MODELS
    if (response.statusCode === 200) {
      updateListModel(response.body);
    }

    updateLoading(false);
    handleCloseModal();
  }

  async function handleModel(id: number): Promise<void> {
    // BUSCA MODEL
    await modelMethods
      .getById(id)
      .then((response) => {
        // SETAR OUTROS CAMPOS
        setValue('id', response.id!);
        setValue('idFornecedor', response.idFornecedor);
        setValue('idStatus', response.idStatus);
        setValue('codigoFabricante', response.codigoFabricante);
        setValue('dataCadastro', response.dataCadastro);
        setValue('faixaLucratividade', response.faixaLucratividade);
        setValue('modeloPrioritario', response.modeloPrioritario);
        setValue('nome', response.nome);
        setValue('subsidiaria', response.subsidiaria);
        setValue('precoAc', response.precoAc);
        setValue('precoAl', response.precoAl);
        setValue('precoAm', response.precoAm);
        setValue('precoAmzf', response.precoAmzf);
        setValue('precoAp', response.precoAp);
        setValue('precoApzf', response.precoApzf);
        setValue('precoBa', response.precoBa);
        setValue('precoCe', response.precoCe);
        setValue('precoDf', response.precoDf);
        setValue('precoEs', response.precoEs);
        setValue('precoGo', response.precoGo);
        setValue('precoMa', response.precoMa);
        setValue('precoMg', response.precoMg);
        setValue('precoMgds', response.precoMgds);
        setValue('precoMs', response.precoMs);
        setValue('precoMt', response.precoMt);
        setValue('precoPa', response.precoPa);
        setValue('precoPb', response.precoPb);
        setValue('precoPe', response.precoPe);
        setValue('precoPi', response.precoPi);
        setValue('precoPr', response.precoPr);
        setValue('precoRj', response.precoRj);
        setValue('precoRn', response.precoRn);
        setValue('precoRo', response.precoRo);
        setValue('precoRr', response.precoRr);
        setValue('precoRrzf', response.precoRrzf);
        setValue('precoRs', response.precoRs);
        setValue('precoSc', response.precoSc);
        setValue('precoSe', response.precoSe);
        setValue('precoSp', response.precoSp);
        setValue('precoTo', response.precoTo);
      })
      .catch((error) => updateListError([...listError, error]));
  }

  async function handleCreteOrEdit(
    modelFields: OriginalItemsInputs,
  ): Promise<void> {
    // EDITA OU CRIA
    const model: OriginalItemsInputs = {
      idStatus: modelFields.idStatus,
      idFornecedor: modelFields.idFornecedor,
      codigoFabricante: modelFields.codigoFabricante,
      dataCadastro: modelFields.dataCadastro
        ? new Date(modelFields.dataCadastro).toISOString()
        : new Date().toISOString(),
      faixaLucratividade: modelFields.faixaLucratividade,
      modeloPrioritario: modelFields.modeloPrioritario,
      nome: modelFields.nome,
      subsidiaria: modelFields.subsidiaria,
      precoAc: modelFields.precoAc,
      precoAl: modelFields.precoAl,
      precoAm: modelFields.precoAm,
      precoAmzf: modelFields.precoAmzf,
      precoAp: modelFields.precoAp,
      precoApzf: modelFields.precoApzf,
      precoBa: modelFields.precoBa,
      precoCe: modelFields.precoCe,
      precoDf: modelFields.precoDf,
      precoEs: modelFields.precoEs,
      precoGo: modelFields.precoGo,
      precoMa: modelFields.precoMa,
      precoMg: modelFields.precoMg,
      precoMgds: modelFields.precoMgds,
      precoMs: modelFields.precoMs,
      precoMt: modelFields.precoMt,
      precoPa: modelFields.precoPa,
      precoPb: modelFields.precoPb,
      precoPe: modelFields.precoPe,
      precoPi: modelFields.precoPi,
      precoPr: modelFields.precoPr,
      precoRj: modelFields.precoRj,
      precoRn: modelFields.precoRn,
      precoRo: modelFields.precoRo,
      precoRr: modelFields.precoRr,
      precoRrzf: modelFields.precoRrzf,
      precoRs: modelFields.precoRs,
      precoSc: modelFields.precoSc,
      precoSe: modelFields.precoSe,
      precoSp: modelFields.precoSp,
      precoTo: modelFields.precoTo,
    };

    if (inEditing === true)
      await modelMethods
        .edit(model, modelFields.id!)
        .then(() =>
          showAlert({
            show: true,
            content: 'Editado com sucesso',
            color: 'success',
            time: 5000,
          }),
        )
        .catch((error) => updateListError([...listError, error]));
    else
      await modelMethods
        .create(model)
        .then(() =>
          showAlert({
            show: true,
            content: 'Cadastrado com sucesso',
            color: 'success',
            time: 5000,
          }),
        )
        .catch((error) => updateListError([...listError, error]));

    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleListModel({
      pagination: `&page=${0}&size=${size}`,
      page: 0,
      idProvider: idProvider,
    });
  }

  async function handleDelete(): Promise<void> {
    // DELETA
    let id: number = getValues('id')!;

    await modelMethods
      .remove(id)
      .then((response) => {
        if (response === true)
          showAlert({
            show: true,
            content: 'Deletado com sucesso',
            color: 'success',
            time: 5000,
          });
        else
          showAlert({
            show: true,
            content:
              'Não Foi possível deletar, verifique suas credenciais',
            color: 'danger',
            time: 5000,
          });
      })
      .catch((error) => updateListError([...listError, error]));

    const size: string = getParameterUrlByName('size', prevPagination)!;
    await handleListModel({
      pagination: `&page=${0}&size=${size}`,
      page: 0,
      idProvider: idProvider,
    });
  }

  /**
   *
   * MODAL
   *
   */
  const [inEditing, updateInEditing] = useState<boolean>(false);
  const [openModal, updateOpenModal] = useState<boolean>(false);

  async function handleModalEdit(id: number): Promise<void> {
    // ABRE MODAL PARA EDICAO
    await handleModel(id);
    updateInEditing(true);
    updateOpenModal(true);
  }

  function handleModalCreate() {
    // ABRE MODAL PARA CRIACAO
    updateLoading(false);
    updateOpenModal(true);
    updateInEditing(false);
  }

  function handleCloseModal() {
    // FECHA MODAL
    clearField();
    updateInEditing(false);
    updateOpenModal(false);
  }

  /**
   *
   *
   *
   *
   *  paginacao */
  var totalPageSize: number = 50;
  const [firstPagination, updateFirstPagination] = useState<string>('');
  const [lastPagination, updateLastPagination] = useState<string>('');
  const [nextPagination, updateNextPagination] = useState<string>('');
  const [prevPagination, updatePrevPagination] = useState<string>('');
  const [paginationItemList, updatePaginationItemList] =
    useState<{
      item: number;
      active: boolean
    }[]>();
  const setValuePaginations = (
    paginationsActive: number,
    prev: number,
    next: number,
  ) => {
    let modelPaginationList: any[] = [];
    for (
      let index = paginationsActive;
      index < 6 + paginationsActive;
      index++
    ) {
      if (!(index > next))
        modelPaginationList.push({
          item: index,
          active: index === paginationsActive ? true : false,
        });
    }
    updatePaginationItemList(modelPaginationList);
  };
  const onClickItemPagination = async (pagination: any) => {
    totalPageSize = Number(getParameterUrlByName('size', nextPagination))!;
    handleListModel({
      pagination: `&page=${pagination.item}&size=${totalPageSize}`,
      page: parseInt(pagination.item),
      idProvider: idProvider,
    });
  };
  const onClickFirstPagination = async () => {
    const size: string = getParameterUrlByName('size', firstPagination)!;
    handleListModel({
      pagination: `&page=${0}&size=${size}`,
      page: 0,
      idProvider: idProvider,
    });
  };
  const onClickLastPagination = async () => {
    const page: string = getParameterUrlByName('page', lastPagination)!;
    const size: string = getParameterUrlByName('size', lastPagination)!;
    handleListModel({
      pagination: `&page=${page}&size=${size}`,
      page: parseInt(page),
      idProvider: idProvider,
    });
  };
  const onClickNextPagination = async () => {
    const page: string = getParameterUrlByName('page', nextPagination)!;
    const size: string = getParameterUrlByName('size', nextPagination)!;
    handleListModel({
      pagination: `&page=${page}&size=${size}`,
      page: parseInt(page),
      idProvider: idProvider,
    });
  };
  const onClickPrevPagination = async () => {
    const page: string = getParameterUrlByName('page', prevPagination)!;
    const size: string = getParameterUrlByName('size', prevPagination)!;
    handleListModel({
      pagination: `&page=${page}&size=${size}`,
      page: parseInt(page),
      idProvider: idProvider,
    });
  };

  /**
   *
   * PROVIDER
   *
   */

  const [modelProvider, updateModelProvider] =
    useState<SelectOptionInterface>();

  function handleSelectProvider(filial: {
    value: number;
    label: string
  }) {
    updateModelProvider(filial);
    updateIdProvider(filial.value);
    handleListModel({
      pagination: `&page=${0}&size=${totalPageSize}`,
      page: 0,
      idProvider: filial.value,
    });
  }

  function CreateSelectProvider() {
    return (
      <div className='m-3'>
        <Row className='d-flex align-items-center justify-content-start'>
          <Col md={1}>
            <Form.Label>Fornecedor:</Form.Label>
          </Col>
          <Col md={3}>
            <SelectProvider
              passOnTheValue={handleSelectProvider}
              defaultValue={modelProvider}
            />
          </Col>
        </Row>
      </div>
    );
  }

  /**
   *
   * UPLOAD FILE
   *
   */
  const [showUploadFile, updateShowUploadFile] = useState(false);
  let activeItemType = 'ITENS_PRECOS';

  const closeUploadModal = () => updateShowUploadFile(false);
  const showUploadModal = () => updateShowUploadFile(true);

  const typeFileOption: SelectOptionInterface[] = [
    { selected: false, value: 'ITENS_PRECOS', label: 'Itens preços' },
    { selected: false, value: 'ITENS', label: 'Itens' },
    { selected: false, value: 'PRECOS', label: 'Preços' },
  ];

  async function loadFile(file: File) {
    if (file !== null || file !== undefined) {
      contentFile = file;
    }
  }

  let contentFile: File | undefined = undefined;

  function ModalUploadFile() {
    return (
      <Modal show={showUploadFile} onHide={closeUploadModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Carregar Arquivos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={9} className='mt-3'>
              <Form.Label className='me-3'>Arquivo:</Form.Label>
              <Col>
                <input
                  type='file'
                  className='form-control'
                  onChange={(event) => {
                    event.preventDefault();
                    loadFile(event.target.files![0]);
                  }}
                />
              </Col>
            </Col>
            <Col md={3} className='mt-3'>
              <Form.Label className='me-3'>
                Tipo do arquivo:
              </Form.Label>
              <Col>
                <Select
                  defaultValue={typeFileOption[0]}
                  onChange={(val) => {
                  }}
                  options={typeFileOption}
                />
              </Col>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              uploadFile({
                typeFile: activeItemType,
                idProvider: idProvider,
                file: contentFile,
              });
            }}
          >
            Enviar arquivo
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}
      {!loading && <CreatePageContent />}

      <Modal
        show={openModal}
        onHide={handleCloseModal}
        size={'xl'}
        fullscreen={'md-down'}
      >
        <Container>
          <form
            onSubmit={handleSubmit(handleCreteOrEdit)}
            className='mt-0'
            noValidate
          >
            <Modal.Header closeButton>
              <Modal.Title
                className='ms-3 lead'
                style={{ fontSize: '30px' }}
              >
                {inEditing ? 'EDITAR' : 'NOVO'}
              </Modal.Title>
            </Modal.Header>
            <AlertModal />
            <Modal.Body>
              <Form.Control type='hidden' {...register('id')} />

              <Row className='mt-3'>
                {/* <Col md={2}>
                                    <Form.Label column>Codigo: <h6>{getValues("id")}</h6></Form.Label>
                                </Col> */}
                <Col md={3}>
                  <Form.Label>Código Fabricante:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('codigoFabricante')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Nome:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('nome')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Data:</Form.Label>
                  <Col>
                    <Form.Control
                      type='date'
                      {...register('dataCadastro')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Subsidiária:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('subsidiaria')}
                    />
                  </Col>
                </Col>
              </Row>
              <Row></Row>
              <Row className='mt-3'>
                <Col md={3}>
                  <Form.Label className='me-3'>
                    Usuário Referência:
                  </Form.Label>
                  <Col>
                    <Controller
                      name='idFornecedor'
                      control={control}
                      render={({
                        field: { onChange, value },
                      }) => {
                        return (
                          <Select
                            defaultValue={
                              providerOption[
                              value!
                              ]
                            }
                            onChange={(val) =>
                              onChange(val?.value)
                            }
                            options={providerOption}
                          />
                        );
                      }}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label className='me-3'>
                    Faixa de lucratividade::
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('faixaLucratividade')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Status:</Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('idStatus')}
                    />
                  </Col>
                </Col>
                <Col md={3}>
                  <Form.Label>Fornecedor:</Form.Label>
                  <Col>
                    <Controller
                      name='idFornecedor'
                      control={control}
                      render={({
                        field: { onChange, value },
                      }) => {
                        return (
                          <Select
                            defaultValue={
                              providerOption[
                              value!
                              ]
                            }
                            onChange={(val) =>
                              onChange(val?.value)
                            }
                            options={providerOption}
                          />
                        );
                      }}
                    />
                  </Col>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={3}>
                  <Form.Label className='me-3'>
                    Acre:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoAc')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Alagoas:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoAl')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Amazonas:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoAm')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Amazonas ZF:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoAmzf')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Amapá:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoAp')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Amapá ZF:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoApzf')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Bahia:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoBa')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Ceará:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoCe')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Distrito Federal:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoDf')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Goiáis:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoEs')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Maranhão:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoGo')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Mato Grosso do sul:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoMa')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Minas Gerais do Sul:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoMg')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Espirito Santo:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoMgds')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Minas Gerais:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoMs')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    MatoGrosso:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoMt')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Pará:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoPa')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Paraíba:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoPb')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Pernambuco:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoPe')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Piauí:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoPi')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Paraná:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoPr')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Rio de Janeiro:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoRj')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Rio Grande do Norte:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoRn')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Roraima:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoRo')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Rondônia:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoRr')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Rondônia ZF:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoRrzf')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Rio Grande do Sul:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoRs')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Santa Catarina:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoSc')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Sergipe:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoSe')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    São Paulo:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoSp')}
                    />
                  </Col>
                </Col>

                <Col md={3}>
                  <Form.Label className='me-3'>
                    Tocantins:
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type='text'
                      {...register('precoTo')}
                    />
                  </Col>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start'>
              <Button variant='primary' size='sm' type='submit'>
                Salvar
              </Button>
              <Button
                variant='outline-primary'
                size='sm'
                onClick={clearField}
              >
                Limpar
              </Button>
              {inEditing && (
                <Button
                  variant='danger'
                  size='sm'
                  onClick={handleDelete}
                >
                  Deletar
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Container>
      </Modal>
      <ModalUploadFile />
    </>
  );


  /**
   *
   * CREATE TABLE
   *
   */
  function CreateTable() {

    useMemo(() => {
      let filterValueLowerCase = (filterValue === undefined ? '' : filterValue.toLowerCase());
      if (columnValue === 'codigoFabricante') {
        updatesFilterManufacturerCode(filterValueLowerCase);
      } else {
        if (columnValue === 'nome') {
          updatesFilterName(filterValueLowerCase);
        }
      }
    }, [filterValue]);

    /** ORDENAÇÃO DA TABELA **/
    function sortColumn(value: any) {
      if (sortType === '') {
        updatesSortType('asc');
      } else if (sortType === 'asc') {
        updatesSortType('desc');
      } else if (sortType === 'desc') {
        updatesSortType('');
      }
    }

    /** ICONES DE ORDENAÇÃO **/
    const CreateIconOrder = (props: {
      colunmName: string
    }) => {
      if (
        columnValue !== undefined &&
        columnValue !== null &&
        props.colunmName == columnValue
      ) {
        if (sortType === 'asc')
          return (
            <FaSortUp className='me-3'
              color='#FFC007'
              size={11}
              key={generateRandomId(100) + generateRandomId(300)}
            />
          );
        else if (sortType === 'desc')
          return (
            <FaSortDown className='me-3'
              color='#FFC007'
              size={11}
              key={generateRandomId(100) + generateRandomId(300)}
            />
          );
        else
          return (
            <FaSort className='me-3'
              size={11}
              key={generateRandomId(100) + generateRandomId(300)}
            />
          );
      }
      return (
        <FaSort className='me-3'
          size={11}
          key={generateRandomId(100) + generateRandomId(300)}
        />
      );
    };

    function defineFilterPlaceholder(valueColumn: any) {
      if (valueColumn.fieldName === 'codigoFabricante' && filterManufacturerCode !== '' && filterManufacturerCode !== undefined) {
        return `(${filterManufacturerCode}) Digite + Enter`;
      }

      if (valueColumn.fieldName === 'nome' && filterName !== '' && filterName !== undefined) {
        return `(${filterName}) Digite + Enter`;
      }

      return 'Digite + Enter';
    }


    return (
      <TableHeaderFixedStyled>
        <Table>
          <thead>
            <tr>
              {tableDescriptions.map((value, index) => {
                return (
                  <th key={index}>
                    <Row>
                      <Col md='5'>
                        <div className='d-flex align-items-baseline justify-content-start'>
                          <div className='d-flex align-items-baseline justify-content-start'
                            onClick={() => {
                              updatesColumnValue(value.fieldName);
                              sortColumn(value);
                            }}>
                            <p style={{ whiteSpace: 'nowrap', textAlign: value.align }} className='mt-1 me-1 p-0'>
                              {value.label}
                            </p>
                            <CreateIconOrder colunmName={value === undefined ? '' : value.fieldName} />
                          </div>
                          {(value.fieldName === 'codigoFabricante' &&
                            filterManufacturerCode !== undefined && filterManufacturerCode !== '')
                            &&
                            <p className='ms-2'>
                              <Badge bg='secondary'
                                onClick={() => {
                                  updatesColumnValue(value.fieldName);
                                  updatesFilterValue('');
                                }}>
                                {`Limpar Pesquisa`}
                              </Badge>
                            </p>
                          }
                          {(value.fieldName === 'nome' &&
                            filterName !== undefined && filterName !== '')
                            &&
                            <p className='ms-2'>
                              <Badge bg='secondary'
                                onClick={() => {
                                  updatesColumnValue(value.fieldName);
                                  updatesFilterValue('');
                                }}>
                                {`Limpar Pesquisa`}
                              </Badge>
                            </p>
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control size='sm'
                          placeholder={defineFilterPlaceholder(value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              updatesColumnValue(value.fieldName);
                              updatesFilterValue(filterTemporaryValue);
                            }
                          }}
                          onChange={(e) => {
                            filterTemporaryValue = e.target.value;
                          }} />
                      </Col>
                    </Row>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listModel.map((value, index) => {
              return (
                <tr
                  key={index}
                  onClick={() =>
                    handleModalEdit(value.id!)
                  }
                >
                  <td align='left'>
                    {value.codigoFabricante}
                  </td>
                  <td align='left'>{value.nome}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableHeaderFixedStyled>
    );
  }

  /**
   *
   * CREATE INITIAL PAGE
   *
   */
  function CreatePageContent() {
    return (
      <>
        <div>
          <div className='mx-4'>
            <TitleGroupComponent titlePage={titlePage}>
              <Row>
                <Col className='d-flex align-items-center justify-content-end'>
                  <Button
                    variant='outline-primary'
                    onClick={showUploadModal}
                    size='sm'
                  >
                    Carregar
                  </Button>
                  <Button
                    className='ms-1'
                    variant='primary'
                    onClick={handleModalCreate}
                    size='sm'
                  >
                    Novo
                  </Button>
                </Col>
              </Row>
            </TitleGroupComponent>
          </div>
          <div className='mx-2'>
            <Card>
              <CreateSelectProvider />

              {(listModel.length > 0 || listModel !== undefined) && idProvider !== undefined && (
                <Card.Body>
                  <div>
                    <CreateTable />
                    <div className='mt-4'>
                      <Row className='d-flex align-items-center justify-content-center'>
                        <Col className='d-flex align-items-center justify-content-center'>
                          <Pagination size='sm'>
                            <Pagination.First
                              onClick={
                                onClickFirstPagination
                              }
                            >
                              Primeira
                            </Pagination.First>
                            <Pagination.Prev
                              onClick={
                                onClickPrevPagination
                              }
                            >
                              Anterior
                            </Pagination.Prev>
                            {paginationItemList?.map(
                              (value, key) => {
                                return (
                                  <Pagination.Item
                                    key={
                                      key
                                    }
                                    active={
                                      value.active
                                    }
                                    onClick={() =>
                                      onClickItemPagination(
                                        value,
                                      )
                                    }
                                  >
                                    {value.item +
                                      1}
                                  </Pagination.Item>
                                );
                              },
                            )}
                            <Pagination.Next
                              onClick={
                                onClickNextPagination
                              }
                            >
                              Próxima
                            </Pagination.Next>
                            <Pagination.Last
                              onClick={
                                onClickLastPagination
                              }
                            >
                              Última
                            </Pagination.Last>
                          </Pagination>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card.Body>
              )}

              {listModel.length === 0 && idProvider === undefined && (
                <div className='d-flex align-items-center justify-content-center'>
                  <p className='m-5'>
                    Não há nada a ser exibido, escolha um fornecedor ou tente novamente mais tarde.
                  </p>
                </div>
              )}
            </Card>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default OriginalItemsPage;
