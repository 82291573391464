import { DefaultEntity } from "../../../@types/entity/default-entity";
import { EntityField } from "../../../@types/entity/field/crud/field-crud-entity";
import { Footer } from "components/footer/footer-component";
import { Headers } from "components/header/headers-component"
import HeaderCrud from "components/header/header-crud-component";
import { Loading } from "components/loading/loading-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Collapse, Container, Form, Row, Table } from "react-bootstrap";
import CreateTableCrud from "components/table/table-crud-component";
import { ModalCrud } from "components/modal/modal-crud-component";
import CreateFieldModalCrud from "components/field/field-modal-component";
import { TableHeaderFixedStyled } from "pages/style/table/table-header-fixed-styled";
import { ParseDateToBr } from "helper/format-date-helper";
import Select from 'react-select'
import { TableComponents } from "../../../@types/entity/interface/table-interface";

interface CrudRestUserInterface<T> {
    create: (model?: T, profile?: string[]) => void
    edit: (model?: T, profile?: string[]) => void
    block: (value?: any) => void
    unblock: (value?: any) => void
    getById(id?: any, value?: any): void
    listAllByCompany: (value?: any) => void
    handleListLogUser: (dateInit: string, dateEnd: string) => Promise<any>
    resetPassword: (params: { userEmail: string, userLogin?: string }) => void
}

type Props = {
    titlePage: string
    entityFields: EntityField[]
    tableComponents: TableComponents
    userCompanyID: any
    companies: any[]
    profile: any[]
    modelMethods: CrudRestUserInterface<DefaultEntity>;
}

const checkboxValues = ["ADMIN", "GUEST", "COMPRAS_GESTOR", "COMPRAS_OPERACIONAL", "SCRAP_ADMINISTRADOR", "SCRAP_COMPRADOR", "SCRAP_AUDITOR", "SCRAP_CONSULTOR", "SCRAP_FINANCEIRO", "SCRAP_VENDAS"]

const UserPageV2: React.FC<Props> = ({ titlePage, entityFields, tableComponents, userCompanyID, companies, profile, modelMethods }: Props) => {

    /**  LOADING **/
    const [loading, updateLoading] = useState<boolean>(false)

    /** ENTIDADE PARA A TABELA E MODAL */
    const [entity, updateEntity] = useState(entityFields)

    /** VERIFICA SE ESTA EDITANDO */
    const [inEditing, updateInEditing] = useState<boolean>(true)

    /** ID DA EMPRESA SELECIONADA */
    const [companyID, updateCompanyID] = useState<number>(userCompanyID)
    useMemo(() => {
        updateCompanyID(userCompanyID)
    }, [userCompanyID])

    /** ESTADOS CHECkBOX PERFIS */
    const [checkedState, setCheckedState] = useState(
        new Array(checkboxValues.length).fill(false)
    );

    /** ATUALIZA ESTADOS PERFIS */
    useEffect(() => {
        profileChecked()
    }, [profile])

    /** MODAL */
    const [showModalCrud, updateShowModalCrud] = useState<boolean>(false)
    const openModalCrud = () => { updateShowModalCrud(true) }
    const closeModalCrud = () => { updateShowModalCrud(false); modelMethods.listAllByCompany(companyID); updateLoading(false) }

    /** MUDA OS VALORES DOS CAMPOS */
    const changeField = (modelFields: any) => { updateEntity(modelFields) }

    /** BUSCA ID DO USUARIO DO MODAL */
    const searchEntityId = () => { return entity.filter((value) => value.name === "id")[0].valueField as number }

    /** BUSCA STATUS DO USUARIO DO MODAL (B = Bloqueado, N = Normal, Não mapeado: E, S) */
    const searchEntityStatus = () => { return entity.filter((value) => value.name === "status")[0].valueField as string }

    /** CLICA NA TABELA E ABRE O MODAL */
    const onClickTable = async (id: number) => {
        updateInEditing(true)
        await modelMethods.getById(id)
        openModalCrud()
    }

    /** CLICA EM NOVO */
    const onClickNew = () => {
        profile = []
        profileChecked()
        updateInEditing(false)
        clearFields()
        openModalCrud()
    }

    /** LIMPA OS CAMPOS */
    const clearFields = () => {
        updateEntity(entityFields.map(value => {
            if (value.name !== "idEmpresa") {
                value.valueField = ""
            }
            return value
        }))
    }

    /** CRIA USUÁRIO */
    const onSubmitForm = async () => {
        updateLoading(true)
        let model: any = {}
        entity.forEach((value) => { model[value.name] = value.valueField })
        await modelMethods.create(model, profile)
        closeModalCrud()
    }

    /** EDITA USUÁRIO */
    const onEditUser = async () => {
        updateLoading(true)
        let model: any = {}
        entity.forEach((value) => { model[value.name] = value.valueField })
        await modelMethods.edit(model, profile)
        closeModalCrud()
    }

    /** BLOQUEIA USUÁRIO */
    const blockUser = async () => {
        updateLoading(true);
        await modelMethods.block(searchEntityId());
        closeModalCrud()
    }

    /** DESBLOQUEIA USUÁRIO */
    const unblockUser = async () => {
        updateLoading(true);
        await modelMethods.unblock(searchEntityId());
        closeModalCrud()
    }

    /** RESETA SENHA */
    const onPasswordReset = async () => {
        updateLoading(true)
        var email = entity.filter((value) => value.name === "email")[0].valueField as string
        var login = entity.filter((value) => value.name === "login")[0].valueField as string
        await modelMethods.resetPassword({ userEmail: email, userLogin: login })
        closeModalCrud()
    }

    /** SELECT EMPRESA */
    const handleOnChangeSelect = useCallback((company: any) => {
        modelMethods.listAllByCompany(company.value)
        updateCompanyID(company.value)
    }, [])

    const CompaniesList = () => {
        let optionSelected
        companies?.forEach(company => {
            if (company.value === companyID) {
                optionSelected = company
            }
        })

        return (
            <>
                <div style={{ marginBottom: "5px" }}>
                    <Form.Label className="m-1">Empresa:</Form.Label>
                    <Select options={companies} defaultValue={optionSelected} onChange={(company) => handleOnChangeSelect(company)} />
                </div>
            </>
        )
    }

    /** PERFIL */
    function profileChecked() {
        const updatedCheckedState = checkedState.map((checkedValue, index) => {
            if (profile.includes(checkboxValues[index])) {
                return checkedValue = true
            } else {
                return checkedValue = false
            }
        })
        setCheckedState(updatedCheckedState)
    }

    function addRemoveProfile(e: any, indexProfile: number) {
        if (!profile.includes(e.target.value)) {
            profile.push(e.target.value)
        } else {
            profile.splice(profile.indexOf(e.target.value), 1)
        }
        profileChecked()
    }

    /**
     * 
     * LOGS DE USUARIO NA MODAL
     *  
     */
    const ContainerTableCollapse = () => {

        const [openTableCollapse, updateOpenCollapse] = useState(false)

        const [dateInitial, updateDateInitial] = useState<string>()
        const [dateEnd, updateDateEnd] = useState<string>()

        function changeDateInitial(date: string) {
            updateDateInitial(date)
        }
        function changeDateEnd(date: string) {
            updateDateEnd(date)
        }

        const [listLogUser, updateListLogUser] = useState<any[]>([])

        const list = async (dateIni: string, dateEnd: string,) => {
            const list = await modelMethods.handleListLogUser(dateIni, dateEnd)

            if (list.statusCode === 200) {
                updateListLogUser(list.body)
            }
        }

        return (
            <>
                <Container>
                    <Button
                        onClick={() => {
                            updateOpenCollapse(!openTableCollapse)
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={openTableCollapse}
                        size="sm"
                        variant="outline-primary"
                    >
                        Ver Log de Acesso
                    </Button>
                </Container>
                <Container>
                    <Collapse in={openTableCollapse}>
                        <div id="example-collapse-text">

                            <Row className="my-3">
                                <Col md={3}>
                                    <Form.Label>Início:</Form.Label>
                                    <Col>
                                        <Form.Control type="date" onChange={(event) => changeDateInitial(event.target.value)} />
                                    </Col>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Fim:</Form.Label>
                                    <Col>
                                        <Form.Control type="date" onChange={(event) => changeDateEnd(event.target.value)} />
                                    </Col>
                                </Col>
                            </Row>
                            <div className='d-flex align-items-center justify-content-end mt-4'>
                                <Button variant="primary" size="sm" className='mb-4' onClick={async () => {
                                    if (dateInitial && dateEnd) {
                                        await list(dateInitial, dateEnd)
                                        updateOpenCollapse(true)
                                    }
                                }}>Pesquisar</Button>
                            </div>

                            <TableHeaderFixedStyled>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Usuário</th>
                                            <th>Data</th>
                                            <th>Action</th>
                                            <th>IP</th>
                                            <th>Session</th>
                                            <th>Objeto</th>
                                            <th>Método</th>
                                            <th>Response Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listLogUser.map((value, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{value.id}</td>
                                                    <td>{value.usuario}</td>
                                                    <td>{ParseDateToBr(value.data)}</td>
                                                    <td>{value.action}</td>
                                                    <td>{value.id}</td>
                                                    <td>{value.session}</td>
                                                    <td>{value.objeto}</td>
                                                    <td>{value.metodo}</td>
                                                    <td>{value.responsecode}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </TableHeaderFixedStyled>
                        </div>
                    </Collapse>
                </Container>
            </>
        )
    }

    /**
     *
     * RENDER
     *
     */
    return (
        loading ?
            <Loading />
            :
            <>
                <Headers />
                <HeaderCrud title={titlePage} >
                    <div>
                        <Button variant="primary" size="sm" onClick={() => { onClickNew() }}>
                            Novo
                        </Button>
                    </div>
                </HeaderCrud>
                <div className="mx-2">
                    <Card>
                        <Card.Body>
                            <CompaniesList />
                            <CreateTableCrud tableHeader={tableComponents.tableHeader} tableBody={tableComponents.tableBody} entityFields={entity} openModalCrud={onClickTable} />
                        </Card.Body>
                    </Card>
                </div>
                <Footer />

                <ModalCrud title={titlePage} open={openModalCrud} close={closeModalCrud} isOpen={showModalCrud} isEditing={inEditing} submit={onSubmitForm}>
                    <div>
                        <CreateFieldModalCrud entityFields={entityFields} changeField={changeField} inEditing={inEditing} />

                        {
                            <>
                                <Row className="mt-3 mb-3">
                                    <Form.Label>Perfis:</Form.Label>
                                    {
                                        checkboxValues.map((checkboxValue: string, index) => {
                                            return (
                                                <Col key={index} md={3}>
                                                    <Form.Check label={checkboxValue.replaceAll("_", " ")} type="checkbox" value={checkboxValue} checked={checkedState[index]} onChange={(e) => { addRemoveProfile(e, index) }} />
                                                </Col>
                                            )
                                        })

                                    }
                                </Row>
                                {inEditing ?
                                    <Row>
                                        <ContainerTableCollapse />
                                    </Row>
                                    :
                                    <>
                                    </>
                                }
                            </>
                        }

                    </div>
                    <div>
                        {!inEditing ? <Button variant="primary" size="sm" type="submit">Salvar</Button> : <></>}
                        {inEditing ? <Button variant="primary" size="sm" onClick={onEditUser}>Salvar</Button> : <></>}
                        {inEditing ? <Button className="ms-1" variant="outline-primary" size="sm" onClick={onPasswordReset}>Reenviar Senha</Button> : <></>}
                        {inEditing ? searchEntityStatus() === "B" ? <Button className="ms-1" variant="success" size="sm" onClick={unblockUser}>Desbloquear</Button> :
                            <Button className="ms-1" variant="danger" size="sm" onClick={blockUser}>Bloquear</Button> : <></>}
                    </div>
                </ModalCrud>
            </>
    )

}

export default UserPageV2