/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { sleep } from "helper/sleep-helper";
import CrudPage from "pages/layout/crud-page";
import { UseAuth } from "hook/auth-hook";
import { RestUseCase } from "service/api/rest/rest-usecase";
import { EntityField } from "../../../@types/entity/field/crud/field-crud-entity";
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface";
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page";
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter";
import { getEnv } from "helper/window-helper";

export const ProviderFactory: React.FC = () => {
    /** ENDPOINT */
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_PROVIDER")!;
    const ENDPOINT_COMPANIE = getEnv("REACT_APP_END_POINT_COMPANIES")!;
    const ENDPOINT_USER = getEnv("REACT_APP_END_POINT_USER")!;

    /** TITULO DA PAGINA */
    const titlePage = "Cadastrar Fornecedor";

    /** CHAMADA BACKEND */
    const handleSelectCompanie = async () => {
        await new RestUseCase(ENDPOINT_COMPANIE).Get().then((response) => {
            updateListCompanie(
                response.body.map((value: any) => {
                    return { value: value.id, label: value.nomeFantasia };
                })
            );
        });
    };
    const listAllUserByCompanies = async (companie: number) => {
        updateListUser([]);
        await new RestUseCase(
            ENDPOINT_USER + `?empresa=${companie}&ativo=${true}`
        )
            .Get()
            .then((response) => {
                updateEntity(
                    entity.map((value) => {
                        if (value.name === "empresaReferencia")
                            value.listValueField = listCompanie;
                        if (value.name === "usuarioReferencia")
                            value.listValueField = response.body.map(
                                (value: any) => {
                                    return {
                                        value: value.id,
                                        label: value.nome,
                                    };
                                }
                            );
                        return value;
                    })
                );
            });
        sleep(2000);
    };

    /** CAMPOS PARA MODAL E TABELA */
    const [listCompanie, updateListCompanie] = useState<
        SelectOptionInterface[]
    >([]);
    const [listUser, updateListUser] = useState<SelectOptionInterface[]>([]);
    const entityFields: EntityField[] = [
        {
            name: "id",
            labelField: "",
            labelTable: "ID",
            typeField: "hidden",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "center",
            tableField: { column: "ID", columnVisible: true },
        },

        {
            name: "dataCadastro",
            labelField: "Data Cadastro:",
            labelTable: "Data Cadastro",
            typeField: "date",
            valueField: null,
            sizeField: 3,
            required: false,
            align: "center",
            tableField: { column: "Data Cadastro", columnVisible: true },
            hideFieldOnCreate: true,
            hideFieldOnEdit: true,
        },
        {
            name: "nome",
            labelField: "Nome:",
            labelTable: "Nome",
            typeField: "text",
            valueField: null,
            sizeField: 6,
            required: false,
            align: "left",
            tableField: { column: "Nome", columnVisible: true },
        },
        {
            name: "nomeFantasia",
            labelField: "Nome Fantasia:",
            labelTable: "Nome Fantasia",
            typeField: "text",
            valueField: null,
            sizeField: 6,
            required: false,
            align: "left",
            tableField: { column: "Nome Fantasia", columnVisible: true },
        },
        {
            name: "email",
            labelField: "Email:",
            labelTable: "Email",
            typeField: "text",
            valueField: null,
            sizeField: 0,
            required: false,
            align: "left",
            tableField: { column: "Email", columnVisible: true },
        },
        {
            name: "empresaReferencia",
            labelField: "Empresa Referência:",
            labelTable: "Empresa Referência",
            typeField: "select",
            valueField: null,
            listValueField: listCompanie,
            sizeField: 0,
            required: false,
            align: "right",
            tableField: { column: "Empresa Referência", columnVisible: true },
        },
        {
            name: "usuarioReferencia",
            labelField: "Usuário Referência:",
            labelTable: "Usuário Referência",
            typeField: "select",
            valueField: null,
            listValueField: listUser,
            sizeField: 0,
            required: false,
            align: "right",
            tableField: { column: "Usuário Referência", columnVisible: true },
        },
    ];
    const [entity, updateEntity] = useState(entityFields);
    const [companyModal, updateCompanyModal] = useState<number>();

    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([]);
    const [tableBody, updateTableBody] = useState([]);

    /** INICIO */
    useEffect(() => {
        handleSelectCompanie();
    }, []);
    useEffect(() => {
        updateCompanyModal(
            entity.filter((value) => value.name === "empresaReferencia")[0]
                .valueField
        );
    }, [entity]);
    useEffect(() => {
        if (companyModal! > 0) listAllUserByCompanies(companyModal!);
    }, [companyModal]);

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Configurações"
        )[0].navItem.filter((item) => item.title === "Cadastrar Fornecedor")[0]
            .role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
    }, [loggedUserData]);

    return (
        <>
            {showScreen ? (
                <CrudPage
                    entityFields={entity}
                    updateEntity={updateEntity}
                    titlePage={titlePage}
                    getAll={async function () {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/?all=true`
                        )
                            .Get()
                            .finally();

                        const responseCompany = await new RestUseCase(
                            ENDPOINT_COMPANIE
                        )
                            .Get()
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                if (value.name === "empresaReferencia") {
                                    value.listValueField =
                                        responseCompany.body.map(
                                            (value: any) => {
                                                return {
                                                    value: value.id,
                                                    label: value.nome,
                                                };
                                            }
                                        );
                                }
                                value.valueField = response.body[value.name];
                                return value;
                            })
                        );

                        updateTableHeader(
                            entityFields.map((value) => {
                                return {
                                    name: value.name,
                                    labelTable: value.labelTable,
                                    align: value.align,
                                    columnVisible:
                                        value.tableField.columnVisible,
                                };
                            })
                        );
                        updateTableBody(response.body);
                    }}
                    getById={async function (id: number) {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${id}`
                        )
                            .GetById()
                            .finally();

                        if (response.body["empresaReferencia"]) {
                            const responseUser = await new RestUseCase(
                                ENDPOINT_USER +
                                `?empresa=${response.body["empresaReferencia"]
                                }&ativo=${true}`
                            )
                                .Get()
                                .finally();

                            updateEntity(
                                entityFields.map((value) => {
                                    if (value.name === "usuarioReferencia") {
                                        value.listValueField =
                                            responseUser.body.map(
                                                (value: any) => {
                                                    return {
                                                        value: value.id,
                                                        label: value.nome,
                                                    };
                                                }
                                            );
                                    }
                                    value.valueField =
                                        response.body[value.name];
                                    return value;
                                })
                            );
                        } else {
                            updateEntity(
                                entityFields.map((value) => {
                                    value.valueField =
                                        response.body[value.name];
                                    return value;
                                })
                            );
                        }
                    }}
                    post={async function (model: any) {
                        const id = model["id"];
                        delete model["id"];

                        if (model["dataCadastro"] !== "") {
                            model["dataCadastro"] = new Date(
                                model["dataCadastro"]
                            ).toISOString();
                        } else {
                            model["dataCadastro"] = new Date().toISOString();
                        }
                        await new RestUseCase(`${ENDPOINT_CRUD}/${id}`)
                            .Post({ data: model })
                            .finally();
                    }}
                    put={async function (id: number, model: any) {
                        const response = await new RestUseCase(
                            `${ENDPOINT_CRUD}/${id}`
                        )
                            .Put({ data: model })
                            .finally();

                        updateEntity(
                            entityFields.map((value) => {
                                value.valueField = response.body[value.name];
                                return value;
                            })
                        );
                    }}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                />
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
