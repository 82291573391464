/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { CrudInterface } from '../../../@types/entity/interface/crud-interface';
import { SelectOptionInterface } from '../../../@types/entity/interface/select-option-interface';
import {
  ConfigurationEntity,
  ConfigurationInputs,
} from '../../../@types/entity/configuration-entity';
import { UseAlert } from 'hook/alert-hook';
import { SpinnerSC } from 'pages/style/spinner/spinner-styled';
import { Footer } from 'components/footer/footer-component';
import { Headers } from 'components/header/headers-component';
import { SelectCompanie } from 'components/select/companie-select-component';
import { TitleGroupComponent } from '../../../components/title/title-group-component';

type Props = {
  marksOptions: SelectOptionInterface[];
  loadFileVersionOptions: SelectOptionInterface[];
  modelMethods: CrudInterface<ConfigurationEntity>;
  fieldForOverlappingSparePartsEqualToZeroOptions: SelectOptionInterface[];
};

const titlePage = 'Cadastrar Configuração';

const ConfigurationPage: React.FC<Props> = ({
  modelMethods,
  marksOptions,
  loadFileVersionOptions,
  fieldForOverlappingSparePartsEqualToZeroOptions,
}: Props) => {
  /**
   *
   * ALERT / ALERT MODAL
   *
   */
  const { showAlert } = UseAlert();

  /**
   *
   * FORM HOOK
   *
   */
  const { register, handleSubmit, setValue, control } =
    useForm<ConfigurationInputs>();

  /**
   *
   * ERROR
   *
   */
  const [listError, updateListError] = useState<string[]>([]);

  /**
   *
   * CRUD MODEL / MODELS
   *
   */
  const [model, updateModel] = useState<any>();

  async function handleModel(idCompany: number): Promise<void> {
    updateLoading(true);
    // BUSCA LISTAGEM DE MODELS
    await modelMethods
      .getById(idCompany)
      .then((response) => {
        updateModel(response);
        setValue('senha', response.senha);
        setValue('marca', response.marca);
        setValue('usuario', response.usuario);
        setValue('pathUpload', response.pathUpload);
        setValue('pathDownload', response.pathDownload);
        setValue('quantidadeDemanda', response.quantidadeDemanda);
        setValue('versaoArquivoCarga', response.versaoArquivoCarga);
        setValue('campoPrecoReposicao', response.campoPrecoReposicao);
      })
      .catch((error) => updateListError([...listError, error]));

    updateLoading(false);
  }

  async function handleEdit(modelFields: ConfigurationInputs): Promise<void> {
    // EDITA
    await modelMethods
      .edit(modelFields, idCompany)
      .then(() =>
        showAlert({
          show: true,
          content: 'Editado com sucesso',
          color: 'success',
          time: 5000,
        }),
      )
      .catch((error) => updateListError([...listError, error]));
    if (idCompany) {
      await handleModel(idCompany);
    }
  }

  /**
   *
   * LOADING
   *
   */
  const [loading, updateLoading] = useState<boolean>(false);

  function Loading() {
    return (
      <SpinnerSC>
        <div
          className='spinner-border text-secondary'
          role='status'
        ></div>
      </SpinnerSC>
    );
  }

  /**
   *
   * EMPRESA
   *
   */
  const [idCompany, updateIdCompany] = useState<number>();
  const [modelCompany, updateModelCompany] =
    useState<SelectOptionInterface>();

  function handleSelectProvider(filial: {
    value: number;
    label: string
  }) {
    updateModelCompany(filial);
    updateIdCompany(filial.value);
    if (idCompany) {
      handleModel(idCompany);
    }
  }

  function CreateSelectCompany() {
    return (
      <div className=''>
        <Row className='d-flex align-items-center justify-content-start'>
          <Col md={1}>
            <Form.Label>Empresa:</Form.Label>
          </Col>
          <Col md={3}>
            <SelectCompanie
              passOnTheValue={handleSelectProvider}
              defaultValue={modelCompany}
            />
          </Col>
        </Row>
      </div>
    );
  }

  /**
   *
   * INIT
   *
   */
  useEffect(() => {
    if (idCompany) {
      handleModel(idCompany);
    }
  }, [idCompany]);

  /**
   *
   * RENDER
   *
   */
  return (
    <>
      <Headers />

      {loading && <Loading />}

      <div className='mx-3'>
        <form
          onSubmit={handleSubmit(handleEdit)}
          className='mt-0'
          noValidate
        >
          <TitleGroupComponent titlePage={titlePage}>
            <Row>
              <Col className='d-flex align-items-center justify-content-end'>
                <Button variant='primary' type='submit' size='sm'>
                  Salvar
                </Button>
              </Col>
            </Row>
          </TitleGroupComponent>

          <Card>
            <Card.Body>
              <Container>
                <CreateSelectCompany />

                {model && (
                  <>
                    <Row>
                      <Col md={3} className='mt-3'>
                        <Form.Label className='me-3'>
                          Quantidade de Demanda:
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type='text'
                            {...register(
                              'quantidadeDemanda',
                            )}
                          />
                        </Col>
                      </Col>
                      <Col md={3} className='mt-3'>
                        <Form.Label className='me-3'>
                          Versão do Arquivo de Carga:
                        </Form.Label>
                        <Col>
                          <Controller
                            name='versaoArquivoCarga'
                            control={control}
                            render={({
                              field: {
                                onChange,
                                value,
                              },
                            }) => {
                              let changeSelected =
                                loadFileVersionOptions.map(
                                  (el) => {
                                    el.selected =
                                      el.value ===
                                        value
                                        ? true
                                        : false;
                                    return el;
                                  },
                                );

                              return (
                                <Select
                                  defaultValue={
                                    changeSelected
                                  }
                                  onChange={(
                                    val,
                                  ) =>
                                    onChange(
                                      val?.value,
                                    )
                                  }
                                  options={
                                    loadFileVersionOptions
                                  }
                                />
                              );
                            }}
                          />
                        </Col>
                      </Col>
                      <Col md={3} className='mt-3'>
                        <Form.Label className='me-3'>
                          Campo p/sobrepor prç.
                          reposição = 0:
                        </Form.Label>
                        <Col>
                          <Controller
                            name='campoPrecoReposicao'
                            control={control}
                            render={({
                              field: {
                                onChange,
                                value,
                              },
                            }) => {
                              let changeSelected =
                                fieldForOverlappingSparePartsEqualToZeroOptions.map(
                                  (el) => {
                                    el.selected =
                                      el.value ===
                                        value
                                        ? true
                                        : false;
                                    return el;
                                  },
                                );

                              return (
                                <Select
                                  defaultValue={
                                    changeSelected
                                  }
                                  onChange={(
                                    val,
                                  ) =>
                                    onChange(
                                      val?.value,
                                    )
                                  }
                                  options={
                                    fieldForOverlappingSparePartsEqualToZeroOptions
                                  }
                                />
                              );
                            }}
                          />
                        </Col>
                      </Col>
                      <Col md={3} className='mt-3'>
                        <Form.Label className='me-3'>
                          Marca:
                        </Form.Label>
                        <Col>
                          <Controller
                            name='marca'
                            control={control}
                            render={({
                              field: {
                                onChange,
                                value,
                              },
                            }) => {
                              let changeSelected =
                                marksOptions.map(
                                  (el) => {
                                    el.selected =
                                      el.value ===
                                        value
                                        ? true
                                        : false;
                                    return el;
                                  },
                                );

                              return (
                                <Select
                                  defaultValue={
                                    changeSelected
                                  }
                                  onChange={(
                                    val,
                                  ) =>
                                    onChange(
                                      val?.value,
                                    )
                                  }
                                  options={
                                    marksOptions
                                  }
                                />
                              );
                            }}
                          />
                        </Col>
                      </Col>
                    </Row>
                    <Row className='my-3'>
                      <Col md={6} className='mt-3'>
                        <Form.Label className='me-3'>
                          Path do arquivo para upload:
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type='text'
                            {...register(
                              'pathUpload',
                            )}
                          />
                        </Col>
                      </Col>
                      <Col md={6} className='mt-3'>
                        <Form.Label className='me-3'>
                          Path do arquivo para
                          download:
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type='text'
                            {...register(
                              'pathDownload',
                            )}
                          />
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2} className='mt-3'>
                        <Form.Label className='me-3'>
                          User do db2:
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type='text'
                            {...register('usuario')}
                          />
                        </Col>
                      </Col>
                      <Col md={2} className='mt-3'>
                        <Form.Label className='me-3'>
                          Password do db2:
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type='password'
                            {...register('senha')}
                          />
                        </Col>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ConfigurationPage;
