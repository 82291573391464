import Select from 'react-select'
import { useEffect, useState } from "react"
import { SelectOptionInterface } from '../../@types/entity/interface/select-option-interface'
import { RestUseCase } from 'service/api/rest/rest-usecase'
import { getEnv } from 'helper/window-helper'

type Props = {
    passOnTheValue: any;
    defaultValue?: SelectOptionInterface;
};

export const SelectCompanie: React.FC<Props> = ({
    passOnTheValue,
    defaultValue,
}: Props) => {
    const ENDPOINT = getEnv("REACT_APP_END_POINT_COMPANIES")!

    const [options, updateOptions] = useState<SelectOptionInterface[]>([])

    async function handleOptions() {
        await new RestUseCase(ENDPOINT).Get().then((response) => {
            if (
                response.statusCode !== 500 &&
                response.statusCode !== undefined
            ) {
                updateOptions(
                    response.body.map((value: any) => {
                        return {
                            value: value.id,
                            label: value.nome,
                        };
                    })
                );
            }
        });
    }

    function changeValueSelectes(params: any) {
        passOnTheValue(params);
    }

    /**
     *
     * INIT
     *
     */
    useEffect(() => {
        handleOptions();
    }, []);

    return (
        <>
            <Select
                defaultValue={defaultValue}
                options={options}
                onChange={(val) => changeValueSelectes(val)}
            />
        </>
    );
};
