import { TopHeader } from "./top-header-component";
import { HeaderNavegation } from "./header-navegation-component";
import { Button, Card, Carousel, Modal, Pagination } from "react-bootstrap";
import { useEffect, useState } from "react";
import { RestUseCase } from "service/api/rest/rest-usecase";
import {
    getLocalStorage,
    setLocalStorege,
} from "data/cache/localstorage-cache";
import { getEnv } from "helper/window-helper";

const LOCALSTORAGE_VIEW_COMMUNIQUE =
    getEnv("REACT_APP_LOCALSTORAGE_VIEW_COMMUNIQUE")!;
export function Headers() {
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_COMMUNIQUE")!;
    const [listCommunique, updateListCommunique] = useState<any[]>([]);
    async function listAllCommunique(): Promise<any> {
        await new RestUseCase(ENDPOINT_CRUD).Get().then((response) => {
            if (
                response.statusCode !== 500 &&
                response.statusCode !== undefined
            ) {
                updateListCommunique(response.body);
            }
        });
    }

    useEffect(() => {
        var localStorage = getLocalStorage(LOCALSTORAGE_VIEW_COMMUNIQUE);

        if (localStorage && localStorage.view === true) {
            listAllCommunique();
        }
    }, []);

    return (
        <>
            <TopHeader />
            <HeaderNavegation />
            <AdditionalContentExample listCommunique={listCommunique} />
        </>
    );
}

type Props = {
    listCommunique: any[];
};

const AdditionalContentExample: React.FC<Props> = ({
    listCommunique,
}: Props) => {
    const [show, setShow] = useState(true);
    const [index, setIndex] = useState(0);

    return (
        <>
            {listCommunique.length > 0 && (
                <Modal
                    show={show}
                    onHide={() => {
                        setShow(false);
                        setLocalStorege(LOCALSTORAGE_VIEW_COMMUNIQUE, {
                            view: false,
                        });
                    }}
                    size={"lg"}
                    fullscreen={"md-down"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Comunicado
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card className="m-2">
                            <Card.Header>
                                {listCommunique[index].titulo}
                            </Card.Header>
                            <div
                                style={{ width: "80%" }}
                                dangerouslySetInnerHTML={{
                                    __html: listCommunique[index].texto,
                                }}
                            />
                        </Card>
                    </Modal.Body>
                    <Modal.Footer>
                        <Pagination>
                            <Pagination.Prev
                                onClick={() => {
                                    setIndex(index === 0 ? 0 : index - 1);
                                }}
                                disabled={index === 0 ? true : false}
                            />
                            <Pagination.Next
                                onClick={() => {
                                    setIndex(
                                        index === listCommunique.length - 1
                                            ? listCommunique.length - 1
                                            : index + 1
                                    );
                                }}
                                disabled={
                                    index === listCommunique.length - 1
                                        ? true
                                        : false
                                }
                            />
                        </Pagination>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default AdditionalContentExample;
