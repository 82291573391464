import { DragInDropHideOrderColumsTableComponent } from "components/dragDrop/drag-in-drop-hide-order-colums-table-component";
import { Modal, Row, Col, Form, Card, Button } from "react-bootstrap";

interface PropsModalOrderHideColumns {
  showModalColumns: boolean;
  closeColumn: (() => void) | undefined;
  applyAction: (() => void) | undefined;
  totalPageSize: number
  columns: any[]
}

export const ModalOrderHideColumns: React.FC<PropsModalOrderHideColumns> = ({ showModalColumns, closeColumn, applyAction, totalPageSize, columns }) => {
  return (
    <Modal show={showModalColumns} onHide={closeColumn} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Reordenar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="mb-2">
            <Form.Group as={Row}>
              <Form.Label column>Quantidade de Item na Tabela:</Form.Label>
              <Col sm="12" md={4}>
                <Form.Control
                  type="number"
                  defaultValue={50}
                  onBlur={(e) => {
                    e.preventDefault();
                    totalPageSize = parseInt(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <p className="mb-0 text-center">
              <small>Clique no item deixando-o em azul para que apareça na tabela, </small>
            </p>
            <p className="mt-0 text-center">
              <small>clique e segure no item para arrastá-lo e reordenar as colunas da tabela trocando os itens de lugar </small>
            </p>
            <DragInDropHideOrderColumsTableComponent columns={columns} />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="primary"
          onClick={applyAction}>
          Aplicar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}