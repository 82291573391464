/* eslint-disable no-restricted-globals */
import { Container } from "react-bootstrap"
import { Headers } from "components/header/headers-component"

const ErrorNotAuthorizedPage: React.FC = () => {

    return (
        <>
            <Headers />
            <Container>
                <h6 className="mt-3">Infelizmente suas credenciais, não tem acesso a esta tela, verifique com seu sua equipe</h6>
            </Container>
        </>
    )
}

export default ErrorNotAuthorizedPage