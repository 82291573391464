import { SpinnerSC } from "pages/style/spinner/spinner-styled";

export function Loading() {

    return (
        <SpinnerSC>
            <div className="spinner-border text-secondary" role="status"></div>
        </SpinnerSC>
    )

}
