import { OrdinationInterface } from "../../../@types/interfaces/ordination-interface";

export const OrdinationTransferValues: OrdinationInterface[] = [
  { croStrColuna: "CRE_FLT_PRCVENDA", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Prç Vend", },
  { croStrColuna: "CRE_FLT_PRCVENDA", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Prç Vend", },
  { croStrColuna: "CRE_FLT_CSTMEDIO", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Cst Médio" },
  { croStrColuna: "CRE_FLT_CSTMEDIO", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Cst Médio" },
  { croStrColuna: "CRE_INT_QTDEMBALAGEM", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Ebg" },
  { croStrColuna: "CRE_INT_QTDEMBALAGEM", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Ebg" },
  { croStrColuna: "CRE_INT_QTDPENDENTE" , croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Pen" },
  { croStrColuna: "CRE_INT_QTDPENDENTE" , croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Pen" },
  { croStrColuna: "CRE_INT_SUG1", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Exc" },
  { croStrColuna: "CRE_INT_SUG1", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Exc" },
  { croStrColuna: "CRE_CHA_NOVCURVA", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "NCvf" },
  { croStrColuna: "CRE_CHA_NOVCURVA", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "NCvf" },
  { croStrColuna: "CRE_CHA_STATUS", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Status" },
  { croStrColuna: "CRE_CHA_STATUS", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Status" },
  { croStrColuna: "CRE_INT_PROJECAO", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Proj" },
  { croStrColuna: "CRE_INT_PROJECAO", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Proj"},
  { croStrColuna: "CRE_INT_FUTURO", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Futuro" },
  { croStrColuna: "CRE_INT_FUTURO", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Futuro" },
  { croStrColuna: "CRE_FLT_PMES", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Pmes" },
  { croStrColuna: "CRE_FLT_PMES", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Pmes" },
  { croStrColuna: "CRE_INT_PONTUACAO", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Ppt" },
  { croStrColuna: "CRE_INT_PONTUACAO", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Ppt" },
  { croStrColuna: "CRE_CHA_CURVA", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Cvf" },
  { croStrColuna: "CRE_CHA_CURVA", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Cvf" },
  { croStrColuna: "CRE_FLT_MEDIA", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Média" },
  { croStrColuna: "CRE_FLT_MEDIA", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Média" },
  { croStrColuna: "CRE_INT_QUANTIDADE", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Qtd" },
  { croStrColuna: "CRE_INT_QUANTIDADE", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Qtd" },
  { croStrColuna: "CRE_INT_QTDESTOQUE", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Est" },
  { croStrColuna: "CRE_INT_QTDESTOQUE", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Est" },
  { croStrColuna: "CRE_FLT_PRCREPOSICAO", croStrTipordem: "ASC", croChaTipo: "T", croIntOrdem: 0, descricao: "Prç Rep" },
  { croStrColuna: "CRE_FLT_PRCREPOSICAO", croStrTipordem: "DESC", croChaTipo: "T", croIntOrdem: 0, descricao: "Prç Rep" },
]