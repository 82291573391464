import { AlertMessageType } from "data/context/alert-context";

export function showSuccessAlert(showAlert: (data: AlertMessageType) => void, msg: string) {
  showAlert({
    show: true,
    content: msg,
    color: 'success',
    time: 3000,
  });
}

export function showErrorAlert(showAlert: (data: AlertMessageType) => void, msg: string) {
  showAlert({
    show: true,
    content: msg,
    color: 'danger',
    time: 3000,
  });
}

export function showWaitAlert(showAlert: (data: AlertMessageType) => void, msg: string) {
  showAlert({
    show: true,
    content: msg,
    color: 'warning',
    time: 2000,
  });
}