import { useState } from "react"
import { Button } from "react-bootstrap"

export const DragInDropHideOrderColumsTableComponent = (props) => {

    const [dragDatafield, updateDragDatafield] = useState()
    const [boxes, updateBoxes] = useState(props.columns)

    const handleDrag = (ev) => {
        updateDragDatafield(ev.currentTarget.id)
    }

    const handleDrop = (ev) => {
        const dragBox = boxes.find((box) => box.dataField === dragDatafield)
        const dropBox = boxes.find((box) => box.dataField === ev.currentTarget.id)

        const dragBoxOrder = dragBox.order
        const dropBoxOrder = dropBox.order

        const newBoxState = boxes.map((box) => {
            if (box.dataField === dragDatafield) {
                box.order = dropBoxOrder
            }
            else if (box.id === dropBox.id) {
                box.order = dropBoxOrder + 1
            }
            else if (box.order > dragBoxOrder && box.order < dropBoxOrder) {
                 box.order = box.order - 1
            }
            else if (box.order > dropBoxOrder && box.order < dragBoxOrder) {
                box.order = box.order + 1
            }
                
            return box
        })

        updateBoxes(newBoxState)
    }

    const handleHide = (params) => {
        updateBoxes(boxes.map((value) => {
            if (value.id === params.id) {
                value.hidden = value.hidden ? false : true
            }
            return value
        }))
    }

    return (
        <>
            {boxes.sort((a, b) => a.order - b.order).map((box, index) => {
                if (!box.fixed) {
                    return (
                        <Button
                            key={index}
                            draggable={true}
                            id={box.dataField}
                            onDragOver={(ev) => ev.preventDefault()}
                            onDragStart={handleDrag}
                            onDrop={handleDrop}
                            variant={box.hidden ? "secondary" : "primary"}
                            onClick={() => handleHide(box)}
                            className="m-1"
                            size={"sm"}
                            active
                        >
                            {box.text}
                        </Button>
                    )
                }

                return (<></>)
            })}
        </>
    )
}