import { RestUseCase } from "service/api/rest/rest-usecase"
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface"
import { OrdinationGroupInterface } from "../../../@types/interfaces/ordination-groups-interface"
import { OrdinationInterface } from "../../../@types/interfaces/ordination-interface"
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter"
import { getEnv } from "helper/window-helper"
import { UseAlert } from "hook/alert-hook"
import { UseAuth } from "hook/auth-hook"
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page"
import OrdinationPage from "pages/layout/settings/ordination-page"
import { useState, useEffect } from "react"

const emptyOrdinationGroup = (): OrdinationGroupInterface => ({
  transferencia: [],
  recebimento: [],
  fisica: [],
  virtual: []
});

export const OrdinationFactory: React.FC = () => {

  const ENDPOINT_ORDINATION = getEnv('REACT_APP_END_POINT_ORDINATION')!;

  /** USUARIO LOGADO */
  const { loggedUserData } = UseAuth()

  /** LOADING **/
  const [loading, updateLoading] = useState<boolean>(false)

  /** ALERTA **/
  const { showAlert } = UseAlert()

  const [showScreen, updateShowScreen] = useState<boolean>(false)
  const [company, updateCompany] = useState<SelectOptionInterface>();
  const [ordinationGroup, updateOrdinationGroup] = useState<OrdinationGroupInterface>(emptyOrdinationGroup);

  async function listOrdinationByCompany(companyId: number): Promise<any> {
    updateLoading(true)
    await new RestUseCase(`${ENDPOINT_ORDINATION}/${companyId}`).Get()
      .then((response) => {
        if (response.statusCode === undefined || response.statusCode < 200 || response.statusCode >= 300) {
          showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
        } else {
          updateOrdinationGroup(response.body)
        }
        updateLoading(false)
      });
  }

  async function saveOrdinationByCompany(companyId: number, ordinationGroup: OrdinationGroupInterface): Promise<any> {
    updateLoading(true)
    let ordenacaoDTOList: OrdinationInterface[] = addIntOrdemToList(ordinationGroup.transferencia)
    ordenacaoDTOList = ordenacaoDTOList.concat(addIntOrdemToList(ordinationGroup.recebimento))
    ordenacaoDTOList = ordenacaoDTOList.concat(addIntOrdemToList(ordinationGroup.virtual))
    ordenacaoDTOList = ordenacaoDTOList.concat(addIntOrdemToList(ordinationGroup.fisica))
    await new RestUseCase(`${ENDPOINT_ORDINATION}/${companyId}`).Post({ data: ordenacaoDTOList })
      .then((response) => {
        if (response.statusCode === undefined || response.statusCode < 200 || response.statusCode >= 300) {
          showAlert({ show: true, content: "Ops!!! Algo deu errado tente novamente mais tarde...", color: "danger", time: 5000 })
        } else {
          showAlert({ show: true, content: "Ordenação salva com sucesso", color: "success", time: 5000 })
        }
        updateLoading(false)
      });
  }

  function addIntOrdemToList(ordinationList: OrdinationInterface[]): OrdinationInterface[] {
    ordinationList.forEach((element, index) => {
      element.croIntOrdem = index + 1
    });
    return ordinationList
  }

  function handleSelectProvider(company: { value: number; label: string }) {
    updateCompany(company);
    listOrdinationByCompany(company.value)
  }

  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Configurações")[0].navItem.filter(item => item.title === "Cadastro de Ordenação")[0].role
    if (loggedUserData?.role) {
      loggedUserData.role.forEach(value => {
        if (roleScreen.includes(value))
          updateShowScreen(true)
      })
    }
  }, [loggedUserData])

  return (
    <>
      {showScreen ?
        <OrdinationPage
          loading={loading}
          company={company}
          ordinationGroup={ordinationGroup}
          handleSelectProvider={handleSelectProvider}
          saveOrdinationByCompany={saveOrdinationByCompany}
        />
        :
        <ErrorNotAuthorizedPage />
      }
    </>
  )
}