import { useEffect, useState } from "react"
import { UseAuth } from 'hook/auth-hook'
import { RestCrud } from "service/api/rest/rest-crud-usecase"
import { ConfigurationEntity } from "../../../@types/entity/configuration-entity"
import ConfigurationPage from "pages/layout/settings/configuration-page"
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface"
import ErrorNotAuthorizedPage from 'pages/layout/error/error-not-authorized-page'
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter"
import { getEnv } from "helper/window-helper"

export const ConfigurationFactory: React.FC = () => {

    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_CONFIGURATION")

    const marksOptions: SelectOptionInterface[] = [
        { label: "Outras", value: "OUTRAS", selected: false },
        { label: "FIAT", value: "FIAT", selected: false },
        { label: "FORD", value: "FORD", selected: false },
        { label: "GM", value: "GM", selected: false },
        { label: "HONDA", value: "HONDA", selected: false },
        { label: "NISSAN", value: "NISSAN", selected: false },
        { label: "PEUGEOT", value: "PEUGEOT", selected: false },
        { label: "RENAULT", value: "RENAULT", selected: false },
        { label: "SUZUKI", value: "SUZUKI", selected: false },
        { label: "TOYOTA", value: "TOYOTA", selected: false },
        { label: "VW", value: "VW", selected: false },
        { label: "AUDI", value: "AUDI", selected: false },
        { label: "HONDA", value: "HONDA", selected: false },
        { label: "DAFRA", value: "DAFRA", selected: false }
    ]

    const loadFileVersionOptions: SelectOptionInterface[] = [
        { label: "V1", value: "V1", selected: false },
        { label: "V2", value: "V2", selected: false },
        { label: "V9", value: "V9", selected: false },
    ]

    const fieldForOverlappingSparePartsEqualToZeroOptions: SelectOptionInterface[] = [
        { label: "Preço de Reposição", value: "PRECO_REPOSICAO", selected: false },
        { label: "Preço de Venda", value: "PRECO_VENDA", selected: false }
    ]

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Configurações")[0].navItem.filter(item => item.title === "Cadastrar Configuração")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }

    }, [loggedUserData])

    return (
        <>
            {showScreen ?
                <ConfigurationPage
                    modelMethods={{
                        create: async function (model: ConfigurationEntity): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Post(model)
                            return response.body
                        },
                        edit: async function (model: ConfigurationEntity, id: number): Promise<boolean> {
                            const response = await new RestCrud(`${ENDPOINT_CRUD!}/${id}`).Put(model)
                            return response.body
                        },
                        remove: async function (id: number): Promise<boolean> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Delete(id)
                            return response.body
                        },
                        getAll: async function (): Promise<ConfigurationEntity[]> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Get()
                            return response.body
                        },
                        get: async function (): Promise<ConfigurationEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).Get()
                            return response.body
                        },
                        getById: async function (id: number): Promise<ConfigurationEntity> {
                            const response = await new RestCrud(ENDPOINT_CRUD!).GetById(id)
                            return response.body
                        }
                    }}

                    marksOptions={marksOptions}

                    loadFileVersionOptions={loadFileVersionOptions}

                    fieldForOverlappingSparePartsEqualToZeroOptions={fieldForOverlappingSparePartsEqualToZeroOptions}
                />
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )
}
