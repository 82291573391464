/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { UseAuth } from 'hook/auth-hook';
import { RestUseCase } from 'service/api/rest/rest-usecase';
import { handleRequest } from 'service/api/request-common';
import { getLocalStorage } from 'data/cache/localstorage-cache';
import SyntheticPage from 'pages/layout/analysis/synthetic-page';
import ErrorNotAuthorizedPage from 'pages/layout/error/error-not-authorized-page';
import { SelectOptionInterface } from '../../../@types/entity/interface/select-option-interface';
import { HeaderNavegationList } from 'helper/adapter/headerNavigation/header-navegation-adapter';
import { SyntheticFilterPlanningAdapter } from 'helper/adapter/planning/synthetic-filter-planning-adapter';
import { ParseDateToBr, ParseDateToBrWithHours } from 'helper/format-date-helper';
import { getEnv } from 'helper/window-helper';
import { TableDescriptions } from '../../../@types/interfaces/table-interface';
import { checkColumns } from 'helper/table-columns-helper';
import { ColumsTableSyntheticAdapter } from 'helper/adapter/columsTable/colums-table-synthetic-adapter';

export const SyntheticFactory: React.FC = () => {
  /**
   *
   *
   *
   *
   *  endpoint */
  const ENDPOINT_TABLE = getEnv('REACT_APP_END_POINT_SYNTHETIC');
  const ENDPOINT_ORDER = getEnv('REACT_APP_END_POINT_SYNTHETIC_GENERATE_ORDER');
  const ENDPOINT_PLANNING = getEnv('REACT_APP_END_POINT_SYNTHETIC') + '/filtros';
  const END_POINT_ITEM = getEnv('REACT_APP_END_POINT_ITEM');
  const END_POINT_MOVEMENT = getEnv('REACT_APP_END_POINT_MOVEMENT');
  const ENDPOINT_FILIAL = getEnv('REACT_APP_END_POINT_FILIAL')!;
  const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv('REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS')!;
  const END_POINT_ITEM_TRACKS = getEnv('REACT_APP_END_POINT_ITEM_TRACKS')!;
  const END_POINT_ITEM_BASE = getEnv('REACT_APP_END_POINT_ITEM_BASE')!;
  const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_SYNTHETIC')!;
  const END_POINT_CORRELATE = getEnv('REACT_APP_END_POINT_CORRELATE_ICON')!;
  const LOCALSTORAGE_COLUMS_ORDER = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_SYNTHETIC')! + '_order';
  const ENDPOINT_MANUFACTURER = getEnv('REACT_APP_END_POINT_MANUFACTURER');
  const LOCALSTORAGE_COLUMS = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_SYNTHETIC')!;
  const ENDPOINT_CAMPANHAS_ATIVAS = getEnv('REACT_APP_END_POINT_CAMPANHAS_ATIVAS')!;

  /**
   *
   *
   *
   *
   *  verificar as permissões de acesso */
  const { loggedUserData } = UseAuth();
  const [showScreen, updateShowScreen] = useState<boolean>(false);
  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter((header) => header.navTitle === 'Análise')[0].navItem.filter((item) => item.title === 'Sintético')[0].role;

    if (loggedUserData?.role) {
      loggedUserData.role.forEach((value) => {
        if (roleScreen.includes(value)) updateShowScreen(true);
      });
    }
  }, [loggedUserData]);

  /**
   *
   *
   *
   *
   *  selects */
  const [filial, updateFilial] = useState<SelectOptionInterface[]>();
  const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>();
  const [base, updateBase] = useState<SelectOptionInterface[]>();
  const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>();
  const [manufacturer, updateManufacturer] = useState<SelectOptionInterface[]>();
  const [campanhasAtivas, updateCampanhasAtivas] = useState<SelectOptionInterface[]>();
  /**
   *
   *
   *
   *
   *  chamada ao backend */
  const paginationDefault = '?page=0&size=50';
  const END_POINT_LAST_CHARGE = getEnv('REACT_APP_END_POINT_LAST_CHARGE')!;
  const [lastCharge, updateLastCharge] = useState<string>();
  const handleLastCharge = async () => {
    await new RestUseCase(END_POINT_LAST_CHARGE).Get().then((response) => {
      updateLastCharge(ParseDateToBrWithHours(response.body));
    });
  };
  const handleFilial = async () => {
    await new RestUseCase(`${ENDPOINT_FILIAL}?modulos=COMPRAS`).Get().then((response) => {
      updateFilial(
        response.body.map((value: any) => {
          return { value: value.id, label: value.nome };
        })
      );
    });
  };
  const handleDiscountGroup = async () => {
    await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS).Get().then((response) => {
      updateDiscountGroup(
        response.body.map((value: any) => {
          return { value: value, label: value };
        })
      );
    });
  };
  const handleRangeProfitability = async () => {
    await new RestUseCase(END_POINT_ITEM_TRACKS).Get().then((response) => {
      updateRangeProfitability(
        response.body.map((value: any) => {
          return { value: value, label: value };
        })
      );
    });
  };
  const handleBase = async () => {
    await new RestUseCase(END_POINT_ITEM_BASE).Get().then((response) => {
      updateBase(
        response.body.bases.map((value: any) => {
          return {
            value: value.codigo,
            label: `${value.codigo} - ${value.desc}`,
          };
        })
      );
    });
  };
  const handleManufacturer = async () => {
    await new RestUseCase(ENDPOINT_MANUFACTURER).Get().then((response) => {
      updateManufacturer(
        response.body.map((value: any) => {
          return {
            value: value.codigo,
            label: `${value.codigo} - ${value.nome}`,
          };
        })
      );
    });
  };
  const handleCampanhasAtivas = async () => {
    await new RestUseCase(ENDPOINT_CAMPANHAS_ATIVAS).Get().then((response) => {
      updateCampanhasAtivas(
        response.body.map((value: any) => {
          return { value: value.id, label: value.descricao };
        })
      );
    });
  };
  const handleGraphicHistory = async (id: number) => {
    const response = await new RestUseCase(`${ENDPOINT_TABLE}/historico/${id}`).Get();
    return handleRequest(response);
  };
  const handleObservationItem = async (params: { codigo: number; filial: number }) => {
    const response = await new RestUseCase(`${END_POINT_ITEM}/${params.codigo}/observacoes?filiais=${params.filial}`).Get();
    return handleRequest(response);
  };
  const handleMovement = async (params: { codigoItem: number; idFilial: number; typeMoviment: 'compra' | 'venda' }) => {
    const response = await new RestUseCase(`${END_POINT_MOVEMENT}/${params.typeMoviment}?codigoItem=${params.codigoItem}&idFilial=${params.idFilial}`).Get();
    return handleRequest(response);
  };
  const correlate = async (params: { codigoItem: number }) => {
    const response = await new RestUseCase(`${END_POINT_CORRELATE}/?codigo=${params.codigoItem}`).Get();
    return handleRequest(response);
  };
  const resume = async (params: any) => {
    const resume = await new RestUseCase(ENDPOINT_TABLE! + '/resumo').Post({
      data: params,
    });

    updatePanelInfo([
      [
        {
          label: 'Itens Distintos Sugeridos',
          number: resume.body.itensSugeridos,
        },
        {
          label: 'Valor Itens Sugeridos',
          number: resume.body.valorItensSugeridos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        },
      ],
      [
        {
          label: 'Itens Distintos Salvos',
          number: resume.body.itensSalvos,
        },
        {
          label: 'Valor Itens Salvos',
          number: resume.body.valorItensSalvos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        },
      ],
    ]);
  };
  const ENDPOINT_TIPO_REMANEJAMENTO = getEnv('REACT_APP_END_POINT_EQUALIZATION')!;
  const [typeRemanejamento, updateTypeRemanejamento] = useState<{
    dataPesquisa: string;
    tipo: string;
  }>({ dataPesquisa: '', tipo: '' });

  async function handleTypeRemanejamento(): Promise<any> {
    await new RestUseCase(ENDPOINT_TIPO_REMANEJAMENTO).Get().then((response) => {
      if (response.statusCode !== 500 && response.statusCode !== undefined) {
        updateTypeRemanejamento({
          dataPesquisa: ParseDateToBr(response.body.dataPesquisa),
          tipo: response.body.tipo,
        });
      }
    });
  }

  /**
   *
   *
   *
   *
   *  painel */
  const [panelInfo, updatePanelInfo] = useState<any[]>([]);

  /**
   *
   *
   *
   *
   *  gerenciador de estado */
  useEffect(() => {
    checkColumns(LOCALSTORAGE_COLUMS, ColumsTableSyntheticAdapter);
    handleFilial();
    handleDiscountGroup();
    handleRangeProfitability();
    handleBase();
    handleManufacturer();
    handleLastCharge();
    handleTypeRemanejamento();
    handleCampanhasAtivas();
  }, []);
  useEffect(() => {
    SyntheticFilterPlanningAdapter.map((value) => {
      if (value.label === 'Filial') value.field[1].optionsInput = filial;
      if (value.label === 'Grupo de desconto') value.field[1].optionsInput = discountGroup;
      if (value.label === 'Base') value.field[1].optionsInput = base;
      if (value.label === 'Lucratividade do fornecedor') value.field[1].optionsInput = rangeProfitability;
      if (value.label === 'Código do Fabricante') value.field[1].optionsInput = manufacturer;
      if (value.label === 'Campanha ATIVA') value.field[1].optionsInput = campanhasAtivas;
    });

    SyntheticFilterPlanningAdapter.sort(function (a, b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });
  }, [filial,
    discountGroup,
    rangeProfitability,
    manufacturer,
    campanhasAtivas,
    base
  ]);

  return (
    <>
      {showScreen ? (
        <SyntheticPage
          /** dados / confi para o planejamento */
          optionsFiltersDefault={SyntheticFilterPlanningAdapter}
          /** processa e lista */
          processAndList={async function (params: { pagination: string; model?: any }): Promise<any> {
            let querie = params?.pagination === undefined ? paginationDefault : params?.pagination;

            if (params.model) params.model.forEach((item: any) => (querie += `&fil=${item.value}`));
            var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING);

            const response = await new RestUseCase(ENDPOINT_TABLE! + querie).Put({
              headers: { 'Action-Name': "Sintetico/Processar" },
              data: localStoragePlanning ? localStoragePlanning : undefined,
            });

            await resume(localStoragePlanning ? localStoragePlanning : undefined);

            return handleRequest(response);
          }}
          /** lista */
          list={async function (params: { pagination: string; model?: any }): Promise<any> {
            /** cria a query */
            let localStorageColumsOrder: any[] = getLocalStorage(LOCALSTORAGE_COLUMS_ORDER) ?? [];

            let queryParamsOrder = '';

            localStorageColumsOrder.forEach((fe) => {
              queryParamsOrder += `&sort=${fe.ordinationName.toLowerCase()}:${fe.ordinationValue}`;
            });

            var dataPlanning = params.model ? params.model : getLocalStorage(LOCALSTORAGE_PLANNING);

            const response = await new RestUseCase(
              ENDPOINT_TABLE! + (params?.pagination === undefined ? paginationDefault : params?.pagination) + queryParamsOrder
            ).Post({
              data: dataPlanning ? dataPlanning : undefined,
            });

            await resume(dataPlanning ? dataPlanning : undefined);

            return handleRequest(response);
          }}
          /** gera o pedido */
          generateOrder={async function (params: { idFilial: number, orderName: string }): Promise<any> {
            let url = params.idFilial === undefined || params.idFilial === null ? ENDPOINT_ORDER! :
              `${ENDPOINT_ORDER!}?idFilial=${params.idFilial}&orderName=${params.orderName}`;

            const response = await new RestUseCase(url).Post({});
            return handleRequest(response);
          }}
          /** salva a pagina ou linha */
          save={async function (params: { modelItem: [{ filial: number; id: number; quantidade: number }] }): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_TABLE!).Patch({ data: params });
            return handleRequest(response);
          }}
          /** salva tudo */
          saveAll={async function (params: { modelItem: [{ filial: number; id: number; quantidade: number }] }): Promise<any> {
            var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING);
            const response = await new RestUseCase(ENDPOINT_TABLE! + `/all`).Patch({
              data: localStoragePlanning ? localStoragePlanning : undefined,
            });
            return handleRequest(response);
          }}
          /** remove a pagina ou linha */
          remove={async function (params: { modelItem: [{ filial: number; id: number }] }): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_TABLE!).Delete({ data: params });
            return handleRequest(response);
          }}
          /** planejamento */
          listByIdPlanning={async function (): Promise<any> { }}
          /** planejamento lista*/
          listAllPlanning={async function (): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_PLANNING!).Get();
            return handleRequest(response);
          }}
          /** planejamento cria*/
          createPlanning={async function (params?: any): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_PLANNING!).Post({ data: params });
            return handleRequest(response);
          }}
          /** planejamento edita*/
          editPlanning={async function (): Promise<any> { }}
          /** planejamento remove*/
          removePlanning={async function (params?: number): Promise<any> {
            const response = await new RestUseCase(ENDPOINT_PLANNING! + '/' + params).Delete();
            return handleRequest(response);
          }}
          /** exporta arquivo */
          exportFileCSV={async function (): Promise<any> {
            var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING);
            const response = await new RestUseCase(ENDPOINT_TABLE!).Post({
              headers: { accept: 'text/csv' },
              data: localStoragePlanning ? localStoragePlanning : undefined,
            });
            return handleRequest(response);
          }}
          exportFileExcel={async function (): Promise<any> {
            var localStorageColunas: TableDescriptions[] = getLocalStorage(LOCALSTORAGE_COLUMS);

            let querie = '';

            if (localStorageColunas !== null) {
              let colunsParams = localStorageColunas
                .sort((a, b) => a.order - b.order)
                .filter((item) => item.hidden === false)
                .map((item) => item.text);

              colunsParams.forEach((item: any) => (querie += `&filtroColunas=${item}`));
            }

            var localStoragePlanning = getLocalStorage(LOCALSTORAGE_PLANNING);

            const response = await new RestUseCase(`${ENDPOINT_TABLE!}/gerar-excel${querie === '' ? querie : '?' + querie}`).Post({
              responseType: 'blob',
              data: localStoragePlanning ? localStoragePlanning : undefined,
            });
            return handleRequest(response);
          }}
          /** alerta grafico*/
          graphicHistory={handleGraphicHistory}
          /** alerta observacao*/
          observationItem={handleObservationItem}
          /** alerta compra e venda*/
          movement={handleMovement}
          /** alerta item correlato*/
          correlate={correlate}
          /** role do usuário logado */
          loggedUserDataRole={loggedUserData?.role!}
          /** painel com os totalizadores */
          panelList={panelInfo}
          lastCharge={lastCharge!}
          typeRemanejamento={typeRemanejamento}
        />
      ) : (
        <ErrorNotAuthorizedPage />
      )}
    </>
  );
};
