/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Select from 'react-select'
import { useEffect, useState } from "react"
import { Controller, useForm } from 'react-hook-form'
import { UseAuth } from "hook/auth-hook"
import { RestUseCase } from "service/api/rest/rest-usecase"
import { Button, Col, Form, Row } from "react-bootstrap"
import ReportPage from "pages/layout/scrap/report-page"
import { ConfigTable } from "../../../@types/interfaces/table-interface"
import { EntityFieldReport } from "../../../@types/entity/field/report/field-report"
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface"
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page"
import { HeaderNavegationList } from 'helper/adapter/headerNavigation/header-navegation-adapter'
import { UseAlert } from 'hook/alert-hook'
import { getEnv } from 'helper/window-helper'

interface FilterInputs {
    analiseRelatorioAgrupamento: string
    fornecedor: number
    status: string[]
    setores: number[]
    regionais: number[]
    filiais: string[]
    codigoItem: string
    modeloPrioritario: string
    inicioAgendamento: string
    fimAgendamento: string
}

export const ReportScrapFactory: React.FC = () => {

    /**
    * 
    * 
    * 
    * 
    * 
    * 
    * 
    * 
    */
    /** CAMPOS DA TELA */
    const entityField: EntityFieldReport[] = [
        { name: "agrupamento", labelTable: "Agrupamento", typeField: "text", align: "left" },
        { name: "disponivel", labelTable: "Disponível", typeField: "monetary", align: "left" },
        { name: "gasto", labelTable: "Gasto", typeField: "monetary", align: "left" }
    ]
    const [entityFields] = useState<EntityFieldReport[]>(entityField)

    /**
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     */
    /** TABELA */
    const [tableHeader, updateTableHeader] = useState<any[]>([])
    const [tableBody, updateTableBody] = useState<any[]>([])
    const [graphicData, updateGraphicData] = useState<any[]>()
    const clearTableBody = () => { updateTableBody([]) }

    /**
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     */
    /** CONFIGURACAO */
    const configTable: ConfigTable = {
        checkboxes: false,
        icons: false,
        buttonHeader: false,
        selectsTable: true,
        navegation: false,
        buttonFooter: false,
        pagination: false,
        planning: false,
        openModal: true
    }
    const ENDPOINT_REPORT = getEnv("REACT_APP_END_POINT_SCRAP")! + `/relatorio`
    const ENDPOINT_PROVIDER = getEnv("REACT_APP_END_POINT_PROVIDER")! + ``
    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")! + ``
    const ENDPOINT_SECTOR = getEnv("REACT_APP_END_POINT_SECTOR")! + ``
    const ENDPOINT_REGIONAL = getEnv("REACT_APP_END_POINT_REGIONAL")! + ``
    const ENDPOINT_COMPANIES = getEnv("REACT_APP_END_POINT_COMPANIES")! + ``



    const titlePage = "Relatório Scrap"

    /**
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     */
    /** CHAMADAS BACKEND */
    const { showAlert } = UseAlert()
    const [withError, updateWithError] = useState<boolean>(false)
    const [waiting, updateWaiting] = useState<boolean>(false)
    const get = async (modelFields: FilterInputs) => {
        clearTableBody()
        updateWaiting(true)

        let data = Object.fromEntries(Object.entries(modelFields).filter(([_, v]) => {
            if (v != null || v !== '')
                return v
        }))

        if (sector?.length === 0) {
            data.setores = []
        }

        await new RestUseCase(`${ENDPOINT_REPORT}`)
            .Post({
                data: data
            })
            .then(response => {
                if (response.statusCode === 200) {
                    updateTableHeader(entityFields.map((value) => { return { name: value.name, labelTable: value.labelTable, align: value.align } }))
                    const sumDisponivel = response.body.reduce((accumulator: any, object: { disponivel: any }) => {
                        return accumulator + object.disponivel;
                    }, 0);

                    const sumGasto = response.body.reduce((accumulator: any, object: { gasto: any }) => {
                        return accumulator + object.gasto;
                    }, 0);

                    updateGraphicData([{
                        name: 'disponivel x gasto',
                        Disponivel: Number(sumDisponivel),
                        Gasto: Number(sumGasto)
                    }])

                    showAlert({ show: true, content: "Relatório gerado com sucesso", color: "success", time: 2000 })
                    updateTableBody(response.body)
                } else {
                    showAlert({ show: true, content: "Nenhum item retornado", color: "warning", time: 2000 })
                    updateWithError(true)
                }
            })
            .catch(() => updateWithError(true))
        updateWaiting(false)
    }
    const handleSelectProvider = async () => {
        await new RestUseCase(ENDPOINT_PROVIDER)
            .Get()
            .then(response => {
                updateProviderSelect(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
            })
            .catch(() => updateWithError(true))
    }
    const handleSelectFilial = async (idCompanie?: number) => {
        let querie = `?`
        if (idCompanie)
            querie += `empresa=${idCompanie}&`
        if (provider)
            querie += `fornecedor=${provider.value}&`

        await new RestUseCase(ENDPOINT_FILIAL + querie)
            .Get()
            .then(response => {
                updateFilialSelect(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
            })
            .catch(() => updateWithError(true))
    }
    const handleSelectGrouping = async () => {
        updateGroupingSelect([
            { value: "REGIONAL", label: "REGIONAL" },
            { value: "SETOR", label: "SETOR" },
            { value: "FILIAL", label: "FILIAL" },
            { value: "SEMESTRAL", label: "SEMESTRAL" },
            { value: "ITEM", label: "ITEM" }
        ])
    }
    const handleSelectSectors = async (regionais?: SelectOptionInterface[]) => {
        if (provider !== null && provider !== undefined) {
            await new RestUseCase(ENDPOINT_SECTOR + `?idFornecedor=${provider.value}`)
                .Get()
                .then(response => {
                    if (regionais?.length !== 0) {
                        updateSectorsSelect(response.body.filter((value: any) => regionais?.includes(value.idRegional)).map((value: any) => {
                            return {
                                value: value.id, label: value.nome
                            }
                        })
                        )
                    } else {
                        updateSectorsSelect(response.body.map((value: any) => {
                            return {
                                value: value.id, label: value.nome
                            }
                        }))
                    }
                })
                .catch(() => updateWithError(true))
        }
    }
    const handleSelectRegional = async () => {
        if (provider !== undefined) {
            await new RestUseCase(ENDPOINT_REGIONAL + `?idFornecedor=${provider.value}`)
                .Get()
                .then(response => {
                    updateRegionalSelect(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
                })
                .catch(() => updateWithError(true))
        }
    }
    const handleSelectCompanie = async () => {
        await new RestUseCase(ENDPOINT_COMPANIES)
            .Get()
            .then(response => {
                updateCompanieSelect(response.body.map((value: any) => { return { value: value.id, label: value.nome } }))
            })
            .catch(() => updateWithError(true))
    }

    /**
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     */
    /** HEADER */
    const { register, handleSubmit, control } = useForm<FilterInputs>()
    const [providerSelect, updateProviderSelect] = useState<SelectOptionInterface[]>()
    const [provider, updateProvider] = useState<SelectOptionInterface>()
    const [companieSelect, updateCompanieSelect] = useState<SelectOptionInterface[]>()
    const [companie, updateCompanie] = useState<number>()
    const [filialSelect, updateFilialSelect] = useState<SelectOptionInterface[]>()
    const [groupingSelect, updateGroupingSelect] = useState<SelectOptionInterface[]>()
    const [sectorsSelect, updateSectorsSelect] = useState<SelectOptionInterface[]>()
    const [sector, updateSector] = useState<number[]>()
    const [regionalSelect, updateRegionalSelect] = useState<SelectOptionInterface[]>()
    const [regional, updateRegional] = useState<SelectOptionInterface[]>([])

    /**
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     */
    /** INICIO */
    useEffect(() => {
        updateSectorsSelect([])
        updateSector(undefined)
        updateRegionalSelect([])
        updateRegional([])
        updateFilialSelect([])
        updateCompanieSelect([])

        handleSelectSectors()
        handleSelectRegional()
        handleSelectFilial()
        handleSelectCompanie()
    }, [provider])

    useEffect(() => {
        updateSectorsSelect([])
        updateSector([])
        if (regional.length !== 0)
            handleSelectSectors(regional)
    }, [regional])

    useEffect(() => {
        updateFilialSelect([])
        if (companie)
            handleSelectFilial(companie)
    }, [companie])

    useEffect(() => {
        handleSelectProvider()
        handleSelectGrouping()
    }, [])

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth()
    const [showScreen, updateShowScreen] = useState<boolean>(false)
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Scrap")[0].navItem.filter(item => item.title === "Relatório Scrap")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    updateShowScreen(true)
            })
        }

    }, [loggedUserData])

    /**
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     * 
     */
    /** RENDERIZACAO */
    return (
        <>
            {showScreen ?

                <ReportPage
                    titlePage={titlePage}
                    entityFields={entityFields}
                    tableHeader={tableHeader}
                    tableBody={tableBody}
                    configTable={configTable}
                    withError={withError}
                    waiting={waiting}
                    graphic={graphicData && graphicData.length > 0 ? graphicData : undefined}
                >
                    <>
                        {/* Planejamento */}
                    </>
                    <>
                        <form onSubmit={handleSubmit(get)} className="mt-0" noValidate>

                            <Row>
                                <Col md={3}>
                                    <Form.Label>Agrupamentos:</Form.Label>
                                    <Controller
                                        name="analiseRelatorioAgrupamento"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (<Select onChange={val => onChange(val?.value)} options={groupingSelect} placeholder="Selecione o Agrupamento" />)
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Fornecedor:</Form.Label>
                                    <Controller
                                        name="fornecedor"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (<Select
                                                isClearable={true}
                                                value={provider}
                                                onChange={val => {
                                                    onChange(val?.value)
                                                    updateProvider(val ? val : undefined)
                                                }}
                                                options={providerSelect}
                                                placeholder="Selecione o Fornecedor"
                                            />)
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Regionais:</Form.Label>
                                    <Controller
                                        name="regionais"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    isMulti
                                                    key={provider?.value}
                                                    options={regionalSelect}
                                                    onChange={val => {
                                                        let data = val.map((elements: any) => elements.value)
                                                        updateRegional(data)
                                                        onChange(data)
                                                    }}
                                                    placeholder="Selecione a regional"
                                                />
                                            )
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Setores:</Form.Label>
                                    <Controller
                                        name="setores"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (<Select
                                                key={regional[0] ? regional[0].value : -1}
                                                isMulti
                                                options={sectorsSelect}
                                                onChange={val => {
                                                    let data = val.map((elements: any) => elements.value)
                                                    updateSector(data)
                                                    onChange(data.length > 0 ? data : [])
                                                }}
                                                placeholder="Selecione o Setor" />)
                                        }}
                                    />
                                </Col>

                            </Row>
                            <Row className='mt-3'>
                                <Col md={3}>
                                    <Form.Label>Empresa:</Form.Label>
                                    <Controller
                                        name="filiais"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (<Select
                                                onChange={val => {
                                                    onChange(val?.value)
                                                    updateCompanie(val?.value)
                                                }}
                                                options={companieSelect}
                                                placeholder="Selecione a Empresa"
                                            />)
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Filiais:</Form.Label>
                                    <Controller
                                        name="filiais"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (<Select onChange={val => onChange([val?.value])} options={filialSelect} placeholder="Selecione a Filial" />)
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Inicio do Agendamento:</Form.Label>
                                    <Col>
                                        <Form.Control type="date" {...register("inicioAgendamento")} />
                                    </Col>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Fim do Agendamento:</Form.Label>
                                    <Col>
                                        <Form.Control type="date" {...register("fimAgendamento")} />
                                    </Col>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={3}>
                                    <Form.Label>Código do Item:</Form.Label>
                                    <Col>
                                        <Form.Control type="text" {...register("codigoItem")} />
                                    </Col>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Modelo Prioritário:</Form.Label>
                                    <Col>
                                        <Form.Control type="text" {...register("modeloPrioritario")} />
                                    </Col>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Form.Label>Status:</Form.Label>
                                <Col md={4}>
                                    <Form.Check {...register("status")} label="Aguardando Auditor" type="checkbox" value="Aguardando Auditor" />
                                    <Form.Check {...register("status")} label="Aguardando CPV" type="checkbox" value="Aguardando CPV" />
                                    <Form.Check {...register("status")} label="Aguardando CPV (Divergências)" type="checkbox" value="Aguardando CPV (Divergências)" />
                                    <Form.Check {...register("status")} label="Aguardando escolha de Auditor" type="checkbox" value="Aguardando escolha de Auditor" />
                                </Col>
                                <Col md={4}>
                                    <Form.Check {...register("status")} label="Processo em análise do Financeiro (Fábrica)" type="checkbox" value="Processo em análise do Financeiro (Fábrica)" />
                                    <Form.Check {...register("status")} label="Aguardando Concessionária inserir NF (XML)" type="checkbox" value="Aguardando Concessionária inserir NF (XML)" />
                                    <Form.Check {...register("status")} label="Aguardando Nota Fiscal CPV" type="checkbox" value="Aguardando Nota Fiscal CPV" />
                                    <Form.Check {...register("status")} label="Programado para pagamento à Concessionária" type="checkbox" value="Programado para pagamento à Concessionária" />
                                </Col>
                                <Col md={4}>
                                    <Form.Check {...register("status")} label="Processo Entregue (Em análise pela Fábrica)" type="checkbox" value="Processo Entregue (Em análise pela Fábrica)" />
                                    <Form.Check {...register("status")} label="Aguardando Vendas (Divergências)" type="checkbox" value="Aguardando Vendas (Divergências)" />
                                    <Form.Check {...register("status")} label="Cancelado" type="checkbox" value="Cancelado" />
                                    <Form.Check {...register("status")} label="Pago (Creditado em Conta movimento do Dealer)" type="checkbox" value="Pago (Creditado em Conta movimento do Dealer)" />
                                </Col>
                            </Row>
                            <div className="d-flex align-items-center justify-content-end mt-3">
                                <Button variant="primary" size="sm" type="submit">Aplicar</Button>
                            </div>
                        </form>
                    </>
                    <>
                        {/* Navegacao */}
                    </>
                    <>
                        {/* Footer */}
                    </>
                    <>
                        {/* modal item */}
                    </>
                </ReportPage>
                :
                <ErrorNotAuthorizedPage />
            }
        </>
    )

}
