import { useMemo, useState } from "react"
import Loading from "react-loading"
import { Headers } from "components/header/headers-component"
import HeaderCrud from "components/header/header-crud-component";
import { Button, Card, Col, Form, ListGroup } from "react-bootstrap";
import { SelectCompanie } from "components/select/companie-select-component";
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface";
import { Footer } from "components/footer/footer-component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { UseAlert } from "hook/alert-hook";

type Props = {
  titlePage: string,
  modelCompany?: SelectOptionInterface,
  saveControlledCompanies: (controladas: SelectOptionInterface[]) => Promise<any>;
  handleSelectProvider?: (filial: { value: number; label: string }) => any
  companiesList: SelectOptionInterface[]
  controlledCompanies: number[]
  loading: boolean
}

const CONTROLADAS = "controladas"
const EMPRESAS = "empresas"

let filteredCtrlList: any[] = []
let filteredChangebleList: any[] = []

const CompanyGroupPage: React.FC<Props> = ({ titlePage, modelCompany, handleSelectProvider, loading, companiesList, controlledCompanies, saveControlledCompanies }: Props) => {

  /** ALERTA **/
  const { showAlert } = UseAlert()

  const [controlledList, updateControlledList] = useState<SelectOptionInterface[]>([]);
  const [changebleCompaniesList, updateChangebleCompaniesList] = useState<SelectOptionInterface[]>([]);

  const [filteredControlledList, updateFilteredControlledList] = useState<SelectOptionInterface[]>([])
  const [filteredCompaniesList, updateFilteredCompaniesList] = useState<SelectOptionInterface[]>([])
  const [filterValue, updateFilterValue] = useState("")

  /** FILTRO DA TABELA **/
  useMemo(() => {
    let filterValueLowerCase = filterValue.toLowerCase()
    if (filterValue === "") {
      updateFilteredControlledList(controlledList)
      updateFilteredCompaniesList(changebleCompaniesList)
    } else {
      filteredChangebleList = filterChangebleList(filterValueLowerCase)
      updateFilteredCompaniesList(filteredChangebleList)

      filteredCtrlList = filterControlledList(filterValueLowerCase)
      updateFilteredControlledList(filteredCtrlList)
    }
  }, [filterValue])

  useMemo(() => {
    if ((companiesList !== null && companiesList !== undefined) &&
      (controlledCompanies !== null && controlledCompanies !== undefined)) {
      let newControlledList: SelectOptionInterface[] = []
      controlledCompanies.forEach(idControlledCompany => {
        let newItem = companiesList.find((company) => company.value === idControlledCompany)
        if (newItem !== null && newItem !== undefined) {
          newControlledList = [...newControlledList, newItem]
        }
      })
      updateControlledList(newControlledList)
      updateFilteredControlledList(newControlledList)

      let newChangebleList: SelectOptionInterface[] = companiesList
      controlledCompanies.forEach(idControlledCompany => {
        newChangebleList = newChangebleList.filter((company) => company.value !== idControlledCompany).sort((a, b) => a.label.localeCompare(b.label))
      })
      updateChangebleCompaniesList(newChangebleList)
      updateFilteredCompaniesList(newChangebleList)
    }
  }, [companiesList, controlledCompanies])

  useMemo(() => {
    updateFilteredCompaniesList(filterChangebleList(filterValue.toLowerCase()))
    updateFilteredControlledList(filterControlledList(filterValue.toLowerCase()))
  }, [changebleCompaniesList, controlledList])

  const onDragEnd = (result: { source: any; destination: any; draggableId: any; }) => {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    // ADDING TO CONTROLADAS
    if (source.droppableId === EMPRESAS && destination.droppableId === CONTROLADAS) {
      let itemToAdd = changebleCompaniesList.find(company => company.label === draggableId)
      let itemFound = controlledList.find(company => company.value === itemToAdd)
      if (itemFound === undefined) {
        updateControlledList((list) => [...list, itemToAdd!].sort((a, b) => a.label.localeCompare(b.label)))
        updateChangebleCompaniesList((list) => list.filter((company) => company.value !== itemToAdd?.value)
          .sort((a, b) => a.label.localeCompare(b.label)))
      }
    }
    // ADDING TO EMPRESAS
    if (source.droppableId === CONTROLADAS && destination.droppableId === EMPRESAS) {
      let itemToAdd = controlledList.find(company => company.label === draggableId)
      let newControlledList = controlledList.filter(company => company.value !== itemToAdd?.value).sort((a, b) => a.label.localeCompare(b.label))
      updateControlledList(newControlledList)
      let itemFound = changebleCompaniesList.find(company => company.value === itemToAdd?.value)
      if (itemFound === undefined) {
        updateChangebleCompaniesList((list) => [...list, itemToAdd!].sort((a, b) => a.label.localeCompare(b.label)))
      }
    }
    // dropped in the same list
    if (source.droppableId === destination.droppableId) {
      if (!result.destination) {
        return;
      }
    }
  }

  function filterChangebleList(filterValueLowerCase: string): SelectOptionInterface[] {
    return changebleCompaniesList.filter(value =>
      String(value.label).toLowerCase().includes(filterValueLowerCase)
    )
  }

  function filterControlledList(filterValueLowerCase: string): SelectOptionInterface[] {
    return controlledList.filter(value =>
      String(value.label).toLowerCase().includes(filterValueLowerCase)
    )
  }

  return (
    loading ?
      <Loading />
      :
      <>
        <Headers />
        <HeaderCrud title={titlePage} >
        </HeaderCrud>
        <div className="mx-2">
          <Card>
            <Card.Body>
              <Col md={3}>
                <Form.Label className="m-1">Empresa:</Form.Label>
                <SelectCompanie
                  passOnTheValue={handleSelectProvider}
                  defaultValue={modelCompany}
                />
              </Col>
              <>
                {
                  modelCompany ?
                    <>
                      <div className="mt-1">
                        Total de Controladas: <b>{controlledCompanies !== null && controlledCompanies !== undefined ? controlledCompanies.length : ""}</b>
                      </div>
                      <div>
                        <Card style={{ background: "#F0F0F0", marginTop: "10px" }}>
                          <Card.Body className="d-flex align-items-center justify-content-center">
                            <Col className="d-flex align-items-center justify-content-center mx-5" >
                              <Form.Control placeholder={"Digite para filtrar as listas..."} size="sm" value={filterValue} onChange={(e) => { updateFilterValue(e.target.value) }} />
                            </Col>
                          </Card.Body>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-2">Empresas:</div>
                                <ListGroup style={{ background: "white", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={EMPRESAS}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {filteredCompaniesList.map((item, index) => (
                                          <Draggable
                                            key={item.value}
                                            draggableId={item.label}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item key={item.value} >{item.label}</ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {changebleCompaniesList.length}</div>
                              </Col>
                              <Col md={1}>
                              </Col>
                              <Col md={5}>
                                <div className="d-flex align-items-center justify-content-center m-2">Empresas Controladas:</div>
                                <ListGroup style={{ background: "white", height: "200px", width: "100%" }}>
                                  <Droppable droppableId={CONTROLADAS}>
                                    {(provided, snapshot) => (
                                      <div
                                        style={{ overflowY: "auto", overflowX: "hidden", height: "100%", width: "100%" }}
                                        ref={provided.innerRef}>
                                        {filteredControlledList.map((item, index) => (
                                          <Draggable
                                            key={item.value}
                                            draggableId={item.label}
                                            index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                style={{ width: "100%" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <ListGroup.Item key={item.value} >{item.label}</ListGroup.Item>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </ListGroup>
                                <div className="mt-1" >Total: {controlledList.length}</div>
                              </Col>
                            </Card.Body>
                            <div className="d-flex m-2">
                              <Col md={5} className="d-flex align-items-center justify-content-start m-2">
                                <Button variant="secondary" size="sm" onClick={() => {
                                  showAlert({ show: true, content: "Arraste os itens para as colunas desejadas depois clique em salvar.", color: "info", time: 5000 })
                                }}>
                                  <AiOutlineQuestionCircle style={{
                                    fontSize: 25,
                                  }} />
                                </Button>
                              </Col>
                              <Col className="d-flex align-items-center justify-content-end m-2">
                                <Button variant="primary" size="sm" onClick={() => { saveControlledCompanies(controlledList) }}>
                                  Salvar
                                </Button>
                              </Col>
                            </div>
                          </DragDropContext>
                        </Card>
                      </div>
                    </>
                    :
                    <>
                      <Card style={{ background: "#F0F0F0", marginTop: "10px" }}>
                        <div className="d-flex align-items-center justify-content-center m-2">
                          Nenhuma Empresa selecionada
                        </div>
                      </Card>
                    </>
                }
              </>
            </Card.Body>
          </Card>
        </div>
        <Footer />
      </>
  )
}

export default CompanyGroupPage