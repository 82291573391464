import { ReturnResponse } from "../../@types/interfaces/request-interface";

export function handleRequest(returnResponse: ReturnResponse): ReturnResponse {
    let reponse: ReturnResponse = { statusCode: 500, headers: {}, body: {} };

    if (returnResponse.body === undefined || returnResponse.body === 500) {
        reponse.statusCode = 500;
        reponse.headers = {};
        reponse.body = {};

        return reponse;
    }

    reponse.body = returnResponse.body;
    reponse.headers = returnResponse.headers;
    reponse.statusCode = returnResponse.statusCode;

    return reponse;
}
