/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import jwt_decode from "jwt-decode";
import { createContext, ReactNode, useEffect, useState } from "react";
import { getLocalStorage } from "data/cache/localstorage-cache";
import { UserLoggedInEntity } from "../../@types/entity/auth/user-logged-in-entity"
import { getEnv } from "helper/window-helper"

export const AuthContext = createContext({} as AuthContextType);

export type ProviderPropsType = { children: ReactNode };
export type AuthContextType = {
    isLoggedIn: boolean;
    updateIsLoggedIn: (status: boolean) => void;
    loggedUserData?: UserLoggedInEntity;
};

export function AuthProvider(props: ProviderPropsType) {
    /** IDENTIFICACAO PARA O LOCALSTORAGE */
    const TOKEN_USER_LOGIN = getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN")!
    const TOKEN_USER_CHANGE = getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_CHANGE")!

    /** TOKEN VINDOS DO LOCALSTORAGE */
    const tokenLocalStorage: { token: string } =
        getLocalStorage(TOKEN_USER_LOGIN);
    const tokenLocalStorageChange: { token: string } =
        getLocalStorage(TOKEN_USER_CHANGE);

    /** USUARIO ESTA LOGADO */
    const [isLoggedIn, updateIsLoggedIn] = useState<boolean>(
        tokenLocalStorage ? true : false
    );

    /** DADOS DO USUARIO LOGADO PARA TODA A APLICACAO */
    const [loggedUserData, updateLoggedUserData] =
        useState<UserLoggedInEntity>();

    /** INICIO */
    useEffect(() => {
        var decoded;

        if (tokenLocalStorage && isLoggedIn) {
            if (!tokenLocalStorageChange)
                decoded = jwt_decode(tokenLocalStorage.token) as any;
            else decoded = jwt_decode(tokenLocalStorageChange.token) as any;

            updateIsLoggedIn(true);

            updateLoggedUserData({
                role: decoded.scope!,
                companyId: decoded.emp,
                companyName: "",
                id: decoded.sub,
                name: "",
            });

            if (Date.now() >= decoded.exp * 1000) {
                localStorage.clear();
                location.reload();
            }
        } else updateIsLoggedIn(false);
    }, []);

    return (
        <AuthContext.Provider
            value={{ isLoggedIn, updateIsLoggedIn, loggedUserData }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}
